import { Typography } from 'antd';
import {
  Card,
  CardDescription,
  Image,
  ShowcaseContainer,
  ShowcaseGrid,
  Title,
} from './styles';

import ObjectDetectionGIF from '@/assets/launchpad_object_detection.gif';
import QualityInspectionGIF from '@/assets/launchpad_quality_inspection.gif';
import SleepDetectionGIF from '@/assets/launchpad_sleep_detection.gif';
import WildfireDetectionGIF from '@/assets/launchpad_wildfire_detection.gif';
import { useDfMediaQuery } from '@/utils/dfMediaQuery';

const UseCases = [
  {
    title: 'Quality Inspection',
    description:
      'Detect cracks, veneers, and defects in manufacturing processes to maintain product integrity.',
    image: QualityInspectionGIF,
  },
  {
    title: 'Sleep Detection',
    description:
      'Monitor drivers in fleet vehicles to prevent drowsiness and ensure safety on the road.',
    image: SleepDetectionGIF,
  },
  {
    title: 'Wildfire Detection',
    description:
      'Aid state agencies in spotting early signs of wildfires, helping protect communities.',
    image: WildfireDetectionGIF,
  },
  {
    title: 'Object Detection & Counting',
    description:
      'Identify objects and count people in real-time to optimize operations and insights.',
    image: ObjectDetectionGIF,
  },
];

const Showcases = () => {
  const { isTabletOrMobile } = useDfMediaQuery();

  return (
    <ShowcaseContainer>
      <Title>Some of our showcases</Title>
      <ShowcaseGrid
        justify="center"
        gap={16}
        direction={isTabletOrMobile ? 'column' : 'row'}
        align={isTabletOrMobile ? 'center' : 'inherit'}>
        {UseCases.map(({ title, description, image }, index) => {
          return (
            <Card key={index}>
              <Image src={image} />
              <Typography.Title level={5}>{title}</Typography.Title>
              <CardDescription> {description} </CardDescription>
            </Card>
          );
        })}
      </ShowcaseGrid>
    </ShowcaseContainer>
  );
};

export default Showcases;
