import { TileLayout } from '@/components/TileLayoutSelector/constants';
import { TileLayoutType } from '@/components/TileLayoutSelector/types';
import classNames from 'classnames';
import _ from 'lodash';
import { Children, useMemo, useState } from 'react';
import { SizeMe, SizeMeProps } from 'react-sizeme';
import styles from '../style.less';
import { calculateOptimalGridLayout } from './utils';

interface Props {
  cues: React.ReactNode;
  layout?: TileLayoutType;
}

const CuesLayout = ({ cues, layout }: Props) => {
  const [containerSize, setContainerSize] = useState<SizeMeProps['size']>({
    width: 0,
    height: 0,
  });
  const numCues = Children.count(cues);

  const { cols: optimalColCount, rows: optimalRowCount } = useMemo(() => {
    if (
      layout === TileLayout.FIT &&
      containerSize.width &&
      containerSize.width > 0 &&
      containerSize.height &&
      containerSize.height > 0
    ) {
      return calculateOptimalGridLayout(
        containerSize.width,
        containerSize.height,
        numCues,
      );
    }
    return { cols: 1, rows: numCues };
  }, [numCues, containerSize, layout]);

  return (
    <SizeMe refreshMode="throttle" refreshRate={500} monitorHeight>
      {({ size }) => {
        if (!_.isEqual(size, containerSize)) {
          setContainerSize(size);
        }
        return (
          <div style={{ width: '100%', height: '100%' }}>
            <div
              className={classNames(styles['timeline-content-cues'], {
                [styles['timeline-content-cues--contain']]: !layout,
                [styles['timeline-content-cues--fit']]:
                  layout === TileLayout.FIT,
                [styles['timeline-content-cues--custom']]:
                  layout && layout !== TileLayout.FIT,
              })}
              style={
                {
                  '--num-columns':
                    layout && layout !== TileLayout.FIT
                      ? layout
                      : optimalColCount,
                  '--num-rows': optimalRowCount,
                } as React.CSSProperties
              }>
              {cues}
            </div>
          </div>
        );
      }}
    </SizeMe>
  );
};

export { CuesLayout };
