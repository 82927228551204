import { Collapse, Typography } from 'antd';
import { ExampleUsageCards } from './components/ExampleUsageCards';
import { FaqContainer } from './styles';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const FAQ = () => {
  return (
    <FaqContainer>
      <Title level={1} style={{ textAlign: 'center', marginBottom: '2rem' }}>
        FAQs
      </Title>

      <Collapse
        accordion
        bordered={false}
        expandIconPosition="end"
        defaultActiveKey={['0']}>
        <Panel header="What Vision Models do you use?" key="1">
          <Text>
            We constantly update the models we use with the most
            state-of-the-art models available in the market. You can try out the
            model with an image from the video to get a sense of the results you
            can expect.
          </Text>
        </Panel>

        <Panel
          header="My camera's RTSP URLs are behind a firewall. What can we do?"
          key="2">
          <Text>
            We fully support on-prem deployments, with cameras behind firewalls.{' '}
            <a href="mailto:contact@Dragonfruit.ai">Get in touch with us</a> for
            details.
          </Text>
        </Panel>

        <Panel header="Do I need to build my own frontend/UI?" key="3">
          <Text>
            No, you can build on our UI platform, which supports full SSO and
            RBAC.{' '}
            <a href="mailto:contact@Dragonfruit.ai">Get in touch with us</a> for
            details.
          </Text>
        </Panel>

        <Panel header="What if we have our own models?" key="4">
          <Text>
            Yes, we can host your models as well.{' '}
            <a href="mailto:contact@Dragonfruit.ai">Get in touch with us</a> for
            details.
          </Text>
        </Panel>
        <Panel header="Can I see some examples?" key="5">
          <ExampleUsageCards />
        </Panel>
      </Collapse>
    </FaqContainer>
  );
};

export default FAQ;
