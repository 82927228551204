/**
 * @generated SignedSource<<f31dd68d92de5635e3f4c3b50a92da3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MonitorMutations_UserSceneConnectionAdd_Mutation$variables = {
  anchorTimeStamp?: string | null | undefined;
  app_id: number;
  channel_ids: ReadonlyArray<string>;
  connections: ReadonlyArray<string>;
  customer_id: number;
  isFavorite?: boolean | null | undefined;
  layout?: string | null | undefined;
  name: string;
  resolution?: string | null | undefined;
};
export type MonitorMutations_UserSceneConnectionAdd_Mutation$data = {
  readonly createNewUserScene: {
    readonly sceneEdge: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"SceneFragment">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type MonitorMutations_UserSceneConnectionAdd_Mutation = {
  response: MonitorMutations_UserSceneConnectionAdd_Mutation$data;
  variables: MonitorMutations_UserSceneConnectionAdd_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anchorTimeStamp"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "app_id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "channel_ids"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customer_id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isFavorite"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "layout"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resolution"
},
v9 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "anchorTimeStamp",
        "variableName": "anchorTimeStamp"
      },
      {
        "kind": "Variable",
        "name": "appId",
        "variableName": "app_id"
      },
      {
        "kind": "Variable",
        "name": "channelIDs",
        "variableName": "channel_ids"
      },
      {
        "kind": "Variable",
        "name": "customerId",
        "variableName": "customer_id"
      },
      {
        "kind": "Variable",
        "name": "isFavorite",
        "variableName": "isFavorite"
      },
      {
        "kind": "Variable",
        "name": "layout",
        "variableName": "layout"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "resolution",
        "variableName": "resolution"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MonitorMutations_UserSceneConnectionAdd_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "CreateNewUserScenePayload",
        "kind": "LinkedField",
        "name": "createNewUserScene",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MonitorSceneEdge",
            "kind": "LinkedField",
            "name": "sceneEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MonitorScene",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SceneFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v8/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "MonitorMutations_UserSceneConnectionAdd_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "CreateNewUserScenePayload",
        "kind": "LinkedField",
        "name": "createNewUserScene",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MonitorSceneEdge",
            "kind": "LinkedField",
            "name": "sceneEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MonitorScene",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFavorite",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timestamp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "anchorTimeStamp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSharedWithTeam",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "layout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "resolution",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Channel",
                    "kind": "LinkedField",
                    "name": "channels",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ChannelID",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "Name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "MonitorStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "LatestMedia",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "Project",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Site",
                            "kind": "LinkedField",
                            "name": "Site",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "SiteID",
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "sceneEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb62bee8c71db4ede0445ec1dcd93450",
    "id": null,
    "metadata": {},
    "name": "MonitorMutations_UserSceneConnectionAdd_Mutation",
    "operationKind": "mutation",
    "text": "mutation MonitorMutations_UserSceneConnectionAdd_Mutation(\n  $app_id: Int!\n  $customer_id: Int!\n  $channel_ids: [String!]!\n  $name: String!\n  $isFavorite: Boolean\n  $anchorTimeStamp: String\n  $layout: String\n  $resolution: String\n) {\n  createNewUserScene(input: {appId: $app_id, customerId: $customer_id, channelIDs: $channel_ids, name: $name, isFavorite: $isFavorite, anchorTimeStamp: $anchorTimeStamp, layout: $layout, resolution: $resolution}) {\n    sceneEdge {\n      node {\n        ...SceneFragment\n        id\n      }\n    }\n  }\n}\n\nfragment ChannelTileAndName_Channel on Channel {\n  Name\n  ChannelID\n  MonitorStatus\n  LatestMedia\n  Project {\n    Site {\n      SiteID\n      id\n    }\n    id\n  }\n}\n\nfragment SceneFragment on MonitorScene {\n  id\n  name\n  isFavorite\n  timestamp\n  anchorTimeStamp\n  createdBy\n  isSharedWithTeam\n  layout\n  resolution\n  channels {\n    ChannelID\n    Name\n    ...ChannelTileAndName_Channel\n    ...TileThumbnailChannelsMedia\n    id\n  }\n}\n\nfragment TileThumbnailChannelsMedia on Channel {\n  LatestMedia\n}\n"
  }
};
})();

(node as any).hash = "5020c7632e4b3fd2a0502af2fc3bbe67";

export default node;
