import { relayGraphQlFetchFunction } from '@/services/graphql';
import { RelayEnvironmentProvider } from 'react-relay';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { Outlet } from 'umi';
import { dfTheme } from './dfTheme';

const createEnvironment = () => {
  const network = Network.create(relayGraphQlFetchFunction);
  const store = new Store(new RecordSource());
  return new Environment({ store, network });
};

const environment = createEnvironment();

const ProvidersLayout = () => {
  return (
    <RelayEnvironmentProvider environment={environment}>
      <StyleSheetManager enableVendorPrefixes>
        <ThemeProvider theme={dfTheme}>
          <Outlet />
        </ThemeProvider>
      </StyleSheetManager>
    </RelayEnvironmentProvider>
  );
};

export default ProvidersLayout;
