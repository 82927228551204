import { sendSlackNotification } from '@/monitoring';

export const getPlatformSignupPayload = (formData: any) => {
  const { ingestion, account } = formData;
  return {
    customerName: account.company,
    firstName: account.firstName,
    lastName: account.lastName,
    email: account.email,
    password: account.password,
    channel_details: {
      rtsp_urls: ingestion.rtspUrls,
      config: {
        inference_type: ingestion.matchType,
        //Convert from q/min to q/sec
        inference_fps: Number(
          (Number(ingestion.callsPerMinute) / 60).toFixed(2),
        ),
        webhook_endpoint: ingestion.webhookEndpoint,
      },
    },
  };
};

export const trackIngestionComplete = (form: any) => {
  const { ingestion = {} } = form;
  const payload = {
    rtsp: {
      source: ingestion.sourceType === 'rtsp' ? 'input' : 'csv',
      count: ingestion.rtspUrls?.length,
    },
    endpoint: {
      type: ingestion.endpointType,
      matchType: ingestion.matchType,
      qpm: ingestion.callsPerMinute,
      webhook: ingestion.webhookEndpoint,
    },
  };
  sendSlackNotification(
    '[Launchpad] Step 1 Complete: ' + JSON.stringify(payload),
  );
};

export const trackSignupComplete = (form: any) => {
  const { account = {} } = form;
  const payload = {
    customerName: account.company,
    firstName: account.firstName,
    lastName: account.lastName,
    email: account.email,
  };
  sendSlackNotification(
    '[Launchpad] Step 2 Complete: ' + JSON.stringify(payload),
  );
};

export const trackBillingComplete = (form: any) => {
  const { billing = {} } = form;
  const payload = {
    amount: billing.amount,
    autoReload: billing.autoReload,
    autoReloadAmount: billing.autoReloadAmount,
  };
  sendSlackNotification(
    '[Launchpad] Step 3 Complete: ' + JSON.stringify(payload),
  );
};
