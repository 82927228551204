import { Card, Collapse } from 'antd';
import styled, { css } from 'styled-components';

export const Accordion = styled(Collapse)`
  .ant-collapse-item,
  .ant-collapse-item:last-child {
    text-align: left;
    border-bottom: 1px solid white;
    border-radius: 0px;
  }
  .ant-collapse-item:first-child {
    border-top: 1px solid white;
  }
  .ant-collapse-header,
  .ant-collapse-content {
    color: white !important;
    text-align: left;
  }
  .ant-collapse-header {
    align-items: center !important;
    font-size: 18px;
  }
  .ant-collapse-content {
    margin-bottom: 16px;
  }
`;

export const StyledCard = styled(Card)`
  ${({ theme }) => css`
    border-radius: ${theme.token?.borderRadiusLG}px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  `}
`;

export const TwoColumnLayout = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media(min-width: ${theme.token?.screenMD}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`,
);

export const Banner = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 12px;
    background-color: ${theme.token?.colorPrimary};
    border-radius: ${theme.token?.borderRadiusLG}px;
    color: white;
    padding: 24px;
    .ant-typography {
      margin: 0px !important;
      color: white;
    }

    @media (min-width: ${theme.token?.screenMD}px) {
      min-width: 300px;
    }
  `}
`;
