import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';
import { DEFAULT_INCIDENTS_PAGE_SIZE } from '../constants';

declare const DF_SERVICES_API_ENDPOINT: string;

interface GetIncidentListParams {
  status?: string[];
  page_number?: number;
  page_size?: number;
  app_id?: number;
}

export async function getIncidentList({
  status = [],
  page_number = 1,
  page_size = DEFAULT_INCIDENTS_PAGE_SIZE,
  app_id = 105,
}: GetIncidentListParams): Promise<any> {
  const token = await getIdToken();
  return request.get(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps/${app_id}/incidents`,
    {
      params: {
        status: status,
        page_no: page_number,
        page_size,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getIncidentDetails(id: any, app_id = 105) {
  const token = await getIdToken();
  return request.get(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps/${app_id}/incidents/${id}/detail`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getSearchResultsForIncident(
  searchTerm: string,
  app_id = 105,
) {
  const token = await getIdToken();
  return request.get(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps/${app_id}/product_search`,
    {
      params: { search_keyword: searchTerm },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function postIncidentReview(payload: any, app_id = 105) {
  const token = await getIdToken();
  return request.post(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps/${app_id}/submit`,
    {
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
