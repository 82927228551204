import { DfFlex } from '@/components/DfFlex';
import { Typography } from 'antd';
import styled, { css } from 'styled-components';

export const ShowcaseContainer = styled.div`
  width: 100%;
`;

export const Title = styled(Typography.Text)`
  font-size: 18px;
  font-weight: bold;
`;

export const ShowcaseGrid = styled(DfFlex)`
  margin-top: 16px;
`;

export const Card = styled.div`
  ${({ theme }) => css`
    background: linear-gradient(#2b104f 0 0) padding-box,
      linear-gradient(180deg, #faad14 0%, #f2007d 52%, #0044f5 100%) border-box;
    padding: 12px;
    border-radius: ${theme.token?.borderRadiusLG}px;
    max-width: 400px;
    border: 1px solid transparent;
  `}
`;

export const Image = styled.img`
  ${({ theme }) => css`
    background-color: white;
    border-radius: ${theme.token?.borderRadiusLG}px;
    width: 100%;
    margin-bottom: 12px;
    height: 8rem;
    border: 1px solid #fce75f;
  `}
`;

export const CardDescription = styled.p`
  color: #9ca3af;
`;
