import type { ComponentType } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

/**
 * Hook to get media query breakpoints. This is needed for use-cases where we need to apply breakpoint based logic in JS
 * @returns {isMobile: boolean}
 */
const useDfMediaQuery = () => {
  const dfTheme = useTheme();

  // Can add more breakpoints here on need basis

  const isMobile = useMediaQuery({
    query: `(max-width: ${dfTheme.token?.screenSM}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${
      (dfTheme.token?.screenSM ?? 0) + 1
    }px) and (max-width: ${dfTheme.token?.screenMD}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${(dfTheme.token?.screenMD ?? 0) + 1}px)`,
  });

  const isTabletOrMobile = isMobile || isTablet;

  return { isMobile, isTablet, isDesktop, isTabletOrMobile };
};

/**
 * HOC to pass media query breakpoints as props to the wrapped component. This is needed for use-cases where we need to apply breakpoint based logic in JS
 */
const withDfMediaQuery = (Component: ComponentType) => {
  return (props: any) => {
    const mediaQuery = useDfMediaQuery();

    return <Component {...props} {...mediaQuery} />;
  };
};

const Desktop: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const mediaQuery = useDfMediaQuery();

  if (!mediaQuery.isMobile) {
    return <>{children}</>;
  }

  return null;
};

const Mobile: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const mediaQuery = useDfMediaQuery();

  if (mediaQuery.isMobile) {
    return <>{children}</>;
  }

  return null;
};

export { useDfMediaQuery, withDfMediaQuery, Desktop, Mobile };
