import Background from '@/assets/launchpad_background.png';
import { Typography } from 'antd';
import styled from 'styled-components';
const { Link } = Typography;

export const LaunchPadContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  height: 100%;
  width: 100%;
  overflow: scroll;
  justify-items: center;
  background: url(${Background}) no-repeat center center;
  background-size: cover;
`;

export const LaunchPadNavControls = styled.div`
  display: flex;
  gap: 12px;
`;

export const LaunchPadNavLink = styled(Link)`
  color: white !important;
  cursor: pointer;
`;
