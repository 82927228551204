import { getCurrentCustomerID } from '@/utils/utils';
import { useSelector } from '@umijs/max';
import { graphql, useMutation } from 'react-relay';
import type { SceneFragment$data } from './components/Scene/__generated__/SceneFragment.graphql';
import useMyScenesConnectionInfo from './MyScenes/useMyScenesConnectionInfo';
import useTeamScenesConnectionInfo from './TeamScenes/useTeamScenesConnectionInfo';
import type { MonitorMutations_SceneUpdate_Mutation } from './__generated__/MonitorMutations_SceneUpdate_Mutation.graphql';
import type { MonitorMutations_ShareSceneWithTeam_Mutation } from './__generated__/MonitorMutations_ShareSceneWithTeam_Mutation.graphql';
import type { MonitorMutations_UnshareSceneWithTeam_Mutation } from './__generated__/MonitorMutations_UnshareSceneWithTeam_Mutation.graphql';
import type { MonitorMutations_UserSceneConnectionAdd_Mutation } from './__generated__/MonitorMutations_UserSceneConnectionAdd_Mutation.graphql';
import type { MonitorMutations_UserSceneRemove_Mutation } from './__generated__/MonitorMutations_UserSceneRemove_Mutation.graphql';

export const MonitorUserSceneConnectionAddMutation = graphql`
  mutation MonitorMutations_UserSceneConnectionAdd_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $channel_ids: [String!]!
    $name: String!
    $isFavorite: Boolean
    $anchorTimeStamp: String
    $layout: String
    $resolution: String
    $connections: [ID!]!
  ) {
    createNewUserScene(
      input: {
        appId: $app_id
        customerId: $customer_id
        channelIDs: $channel_ids
        name: $name
        isFavorite: $isFavorite
        anchorTimeStamp: $anchorTimeStamp
        layout: $layout
        resolution: $resolution
      }
    ) {
      sceneEdge @prependEdge(connections: $connections) {
        node {
          ...SceneFragment
        }
      }
    }
  }
`;

export const MonitorUserSceneConnectionRemoveMutation = graphql`
  mutation MonitorMutations_UserSceneRemove_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $id: String!
    $connections: [ID!]!
  ) {
    deleteScene(input: { appId: $app_id, customerId: $customer_id, id: $id }) {
      deletedSceneEdge {
        id @deleteEdge(connections: $connections)
      }
    }
  }
`;

export const MonitorShareSceneWithTeamMutation = graphql`
  mutation MonitorMutations_ShareSceneWithTeam_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $id: String!
    $teamScenesConnection: [ID!]!
  ) {
    shareSceneWithTeam(
      input: { appId: $app_id, customerId: $customer_id, id: $id }
    ) {
      sceneEdge @prependEdge(connections: $teamScenesConnection) {
        node {
          isSharedWithTeam
        }
      }
    }
  }
`;

export const MonitorUnshareSceneWithTeamMutation = graphql`
  mutation MonitorMutations_UnshareSceneWithTeam_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $id: String!
    $teamScenesConnection: [ID!]!
  ) {
    unShareSceneWithTeam(
      input: { appId: $app_id, customerId: $customer_id, id: $id }
    ) {
      sceneEdge {
        node {
          id @deleteEdge(connections: $teamScenesConnection)
          isSharedWithTeam
        }
      }
    }
  }
`;

export const MonitorSceneUpdateMutation = graphql`
  mutation MonitorMutations_SceneUpdate_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $channel_ids: [String!]!
    $id: String!
    $name: String
    $isFavorite: Boolean
    $anchorTimeStamp: String
    $layout: String
    $resolution: String
  ) {
    updateExistingScene(
      input: {
        appId: $app_id
        customerId: $customer_id
        channelIDs: $channel_ids
        id: $id
        name: $name
        isFavorite: $isFavorite
        anchorTimeStamp: $anchorTimeStamp
        layout: $layout
        resolution: $resolution
      }
    ) {
      sceneEdge {
        node {
          ...SceneFragment
        }
      }
    }
  }
`;

export const useSceneAddActions = ({
  appId,
  customerId,
}: {
  appId: number;
  customerId: number;
}) => {
  const { invalidateMyScenes } = useMyScenesConnectionInfo(appId, customerId);

  const [commitConnectionAddUserSceneMutation, addUserSceneMutationPending] =
    useMutation<MonitorMutations_UserSceneConnectionAdd_Mutation>(
      MonitorUserSceneConnectionAddMutation,
    );
  const addUserScene = ({
    channelIds,
    name,
    anchorTimeStamp,
    onSceneAdded,
    layout,
    resolution,
  }: {
    channelIds: string[];
    name: string;
    anchorTimeStamp: string;
    onSceneAdded?: (
      response: MonitorMutations_UserSceneConnectionAdd_Mutation['response'],
    ) => void;
    layout?: string;
    resolution?: string;
  }) => {
    commitConnectionAddUserSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        channel_ids: channelIds,
        anchorTimeStamp,
        connections: [],
        name,
        layout,
        resolution,
      },
      onCompleted: onSceneAdded,
      updater: invalidateMyScenes,
    });
  };

  return {
    addUserScene,
    addUserSceneMutationPending,
  };
};

export const useSceneRemoveActions = ({
  scene,
  appId,
  customerId,
}: {
  scene: SceneFragment$data;
  appId: number;
  customerId: number;
}) => {
  const { invalidateMyScenes } = useMyScenesConnectionInfo(appId, customerId);
  const { invalidateTeamScenes } = useTeamScenesConnectionInfo(
    appId,
    customerId,
  );

  const currentUser = useSelector(
    // @ts-expect-error
    (state) => state.user.currentUser,
  );
  const userID = currentUser.UserID;

  const [commitRemoveUserSceneMutation, isRemovingScene] =
    useMutation<MonitorMutations_UserSceneRemove_Mutation>(
      MonitorUserSceneConnectionRemoveMutation,
    );

  const removeUserScene = ({
    connectionId,
    onUserSceneRemoveError,
    onUserSceneRemoveSuccess,
  }: {
    connectionId: string | null;
    onUserSceneRemoveSuccess?: () => void;
    onUserSceneRemoveError?: () => void;
  }) => {
    commitRemoveUserSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        connections: connectionId ? [connectionId] : [],
      },
      onCompleted: onUserSceneRemoveSuccess,
      onError: onUserSceneRemoveError,
      updater: (store) => {
        if (scene.isSharedWithTeam || !scene.createdBy) {
          invalidateTeamScenes(store, undefined);
        }

        if (scene.createdBy === userID) {
          invalidateMyScenes(store, undefined);
        }
      },
    });
  };
  return {
    removeUserScene,
    isRemovingScene,
  };
};

export const useSceneShareActions = ({
  scene,
  appId,
  customerId,
}: {
  scene: SceneFragment$data;
  appId: number;
  customerId: number;
}) => {
  const { invalidateTeamScenes } = useTeamScenesConnectionInfo(
    appId,
    customerId,
  );

  const [commitShareSceneMutation] =
    useMutation<MonitorMutations_ShareSceneWithTeam_Mutation>(
      MonitorShareSceneWithTeamMutation,
    );

  const [commitUnshareSceneMutation] =
    useMutation<MonitorMutations_UnshareSceneWithTeam_Mutation>(
      MonitorUnshareSceneWithTeamMutation,
    );

  const shareScene = ({
    onSceneShareSuccess,
    onSceneShareError,
  }: {
    onSceneShareSuccess?: () => void;
    onSceneShareError?: () => void;
  } = {}) => {
    commitShareSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        teamScenesConnection: [],
      },
      optimisticResponse: {
        shareSceneWithTeam: {
          sceneEdge: {
            node: {
              id: scene.id,
              isSharedWithTeam: true,
            },
          },
        },
      },
      onCompleted: onSceneShareSuccess,
      onError: onSceneShareError,
      updater: invalidateTeamScenes,
    });
  };

  const unshareScene = ({
    onSceneUnShareSuccess,
    onSceneUnShareError,
    connectionId,
  }: {
    onSceneUnShareSuccess?: () => void;
    onSceneUnShareError?: () => void;
    connectionId: string | null;
  }) => {
    commitUnshareSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        teamScenesConnection: connectionId ? [connectionId] : [],
      },
      optimisticResponse: {
        unShareSceneWithTeam: {
          sceneEdge: {
            node: {
              isSharedWithTeam: false,
              id: scene.id,
            },
          },
        },
      },
      onCompleted: onSceneUnShareSuccess,
      onError: onSceneUnShareError,
      updater: invalidateTeamScenes,
    });
  };

  return { shareScene, unshareScene };
};

export const useSceneUpdateActions = ({
  scene,
  appId,
}: {
  scene: SceneFragment$data;
  appId: number;
}) => {
  const [commitSceneUpdateMutation, sceneUpdateMutationPending] =
    useMutation<MonitorMutations_SceneUpdate_Mutation>(
      MonitorSceneUpdateMutation,
    );

  const updateSceneAttribute = ({
    name,
    layout,
    resolution,
    onSceneUpdated,
  }: {
    name?: string;
    layout?: string;
    resolution?: string;
    onSceneUpdated?: (
      response: MonitorMutations_SceneUpdate_Mutation['response'],
    ) => void;
  }) => {
    const updatedValues = {
      name,
      layout,
      resolution,
    };
    commitSceneUpdateMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        channel_ids: scene.channels.map((ch) => String(ch.ChannelID)),
        anchorTimeStamp: scene.anchorTimeStamp,
        isFavorite: scene.isFavorite,
        ...updatedValues,
      },
      onCompleted: onSceneUpdated,
    });
  };

  const updateSceneNameAndChannels = ({
    name,
    layout,
    resolution,
    channelIDs,
    anchorTimeStamp,
    onSceneUpdated,
  }: {
    name: string;
    layout?: string;
    resolution?: string;
    channelIDs: string[];
    anchorTimeStamp: string;
    onSceneUpdated?: (
      response: MonitorMutations_SceneUpdate_Mutation['response'],
    ) => void;
  }) => {
    commitSceneUpdateMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        channel_ids: channelIDs,
        name,
        anchorTimeStamp: anchorTimeStamp,
        isFavorite: scene.isFavorite,
        layout,
        resolution,
      },
      onCompleted: onSceneUpdated,
    });
  };

  return {
    updateSceneAttribute,
    updateSceneNameAndChannels,
    sceneUpdateMutationPending,
  };
};
