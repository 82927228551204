import React, { createContext, useState } from 'react';

interface LoginLayoutContextState {
  navBarControls: React.ReactNode;
  setNavBarControls: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

export const LoginLayoutContext = createContext<
  LoginLayoutContextState | undefined
>(undefined);

interface LoginLayoutContextProviderProps {
  children: JSX.Element;
}

export const LoginLayoutContextProvider = ({
  children,
}: LoginLayoutContextProviderProps) => {
  const [navBarControls, setNavBarControls] = useState<React.ReactNode>();

  return (
    <LoginLayoutContext.Provider
      value={{
        navBarControls,
        setNavBarControls,
      }}>
      {children}
    </LoginLayoutContext.Provider>
  );
};

export const useLoginLayoutContext = () => {
  const context = React.useContext(LoginLayoutContext);
  if (context === undefined) {
    throw new Error('LoginLayoutContext is used incorrectly!');
  }
  return context;
};
