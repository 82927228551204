import LoadingSpinner from '@/components/LoadingSpinner';
import { Modal } from 'antd';
import { Suspense, useState } from 'react';
import CreateUpdateSiteForm from '..';
import type { CreateUpdateSite_labels$key } from '../__generated__/CreateUpdateSite_labels.graphql';
import styles from './style.less';

type UpdateSiteModalProps = {
  siteId: string;
  siteConnectionId: string;
  labelConnectionRef: CreateUpdateSite_labels$key;
  children: React.ReactNode;
};

const UpdateSiteModal = ({
  siteId,
  siteConnectionId,
  labelConnectionRef,
  children,
}: UpdateSiteModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Suspense fallback={<LoadingSpinner fontSize={12} />}>
      <Modal
        title={'Update Site'}
        className={styles['modal']}
        width={400}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        destroyOnClose>
        <CreateUpdateSiteForm
          siteId={siteId}
          siteConnectionId={siteConnectionId}
          labelConnectionRef={labelConnectionRef}
          onClose={() => setIsOpen(false)}
        />
      </Modal>
      <span
        onClick={() => {
          setIsOpen(true);
        }}>
        {children}
      </span>
    </Suspense>
  );
};

export default UpdateSiteModal;
