import { Contexts } from '@/components/ContextualCombobox/constants';
import { updateGlobalOptionList } from '@/components/ContextualCombobox/utils';
import _ from 'lodash';
import {
  graphql,
  useFragment,
  useMutation,
  useRelayEnvironment,
} from 'react-relay';
import type {
  UpdateSiteStatusMutation_site$data,
  UpdateSiteStatusMutation_site$key,
} from './__generated__/UpdateSiteStatusMutation_site.graphql';

const mutation = graphql`
  mutation UpdateSiteStatusMutation($input: UpdateSiteStatusInput!) {
    updateSiteStatus(input: $input) {
      site {
        id
        SiteID
        Status
        StatusNotes
        Issues
        IssueNotes
        HasStatusNotes
        HasIssueNotes
        StatusOptionID
        IssuesOptionID
      }
    }
  }
`;

const siteFragment = graphql`
  fragment UpdateSiteStatusMutation_site on Site {
    id
    SiteID
    Status
    StatusNotes
    Issues
    IssueNotes
    HasStatusNotes
    HasIssueNotes
    StatusOptionID
    IssuesOptionID
  }
`;

type Input = {
  siteId: number;
  issueNotes?: string;
  issuesOptionText?: string;
  issuesOptionId?: number;
  statusNotes?: string;
  statusOptionText?: string;
  statusOptionId?: number;
};

const getOptimisticResponse = (
  site: UpdateSiteStatusMutation_site$data,
  input: Input,
) => {
  let optimisticSite = {
    ...site,
    Status:
      input.statusOptionText !== undefined
        ? input.statusOptionText
        : site.Status,
    StatusOptionID:
      input.statusOptionId !== undefined
        ? input.statusOptionId
        : site.StatusOptionID,
    StatusNotes: input.statusNotes || site.StatusNotes,
    IssueNotes: input.issueNotes || site.IssueNotes,
    Issues:
      input.issuesOptionText !== undefined
        ? input.issuesOptionText
        : site.Issues,
    IssuesOptionID:
      input.issuesOptionId !== undefined
        ? input.issuesOptionId
        : site.IssuesOptionID,
  } as any;
  //Generating a temp id for optimistic response - using
  //negative integers so they do not conflict with actual ids
  if (input.issuesOptionText && !input.issuesOptionId) {
    optimisticSite.IssuesOptionID = -1 * _.random(1, 100);
  }
  if (input.statusOptionText && !input.statusOptionId) {
    optimisticSite.StatusOptionID = -1 * _.random(1, 100);
  }
  return optimisticSite;
};

export function useUpdateSiteStatusMutation(
  siteRef: UpdateSiteStatusMutation_site$key,
) {
  const [commit, isInFlight] = useMutation(mutation);
  let site = useFragment(siteFragment, siteRef);
  const environment = useRelayEnvironment();

  const updateSiteStatus = (input: Input, onCompleted?: () => void) => {
    let optimisticSite = getOptimisticResponse(site, input);
    commit({
      variables: {
        input,
      },
      onCompleted: (response: any) => {
        if (response) {
          //Update option list
          let { Status, StatusOptionID, Issues, IssuesOptionID } =
            response?.updateSiteStatus?.site || {};
          if (Status && StatusOptionID) {
            updateGlobalOptionList(
              environment,
              Contexts.fm_site_status,
              StatusOptionID,
              Status,
            );
          }
          if (Issues && IssuesOptionID) {
            updateGlobalOptionList(
              environment,
              Contexts.fm_site_issues,
              IssuesOptionID,
              Issues,
            );
          }
        }
        if (onCompleted) {
          onCompleted();
        }
      },
      optimisticResponse: {
        updateSiteStatus: {
          site: optimisticSite,
        },
      },
    });
  };

  return {
    isInFlight,
    updateSiteStatus,
  };
}
