import { Flex, Typography } from 'antd';
import styled from 'styled-components';

export const HeroText = styled(Flex)`
  max-width: 1080px;
  margin: auto;
  padding: 32px;
  text-align: center;
`;

export const Line1 = styled(Typography.Text)`
  text-transform: uppercase;
  font-size: 24px;
`;

export const Line2 = styled(Typography.Text)`
  font-size: 24px;
`;

export const YellowText = styled.span`
  color: #fce75f;
`;

//margin:auto seems to be required in safari, although it should ideally work w/o that
export const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  text-align: center;
  margin: auto;
`;
