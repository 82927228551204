export interface GridLayout {
  rows: number;
  cols: number;
  cellWidth: number;
  cellHeight: number;
}

export const aspectRatio = 16 / 9;

/*
This function calculates the number of rows & cols to have in our grid
when we're using a "Fit" layout to display the tiles
We're optimizing for tile size here - so that each cell is as large as possible
while maintaining a 16 X 9 ish aspect ratio
*/
export function calculateOptimalGridLayout(
  containerWidth: number,
  containerHeight: number,
  numCells: number,
): GridLayout {
  let maxTileSize = 0;
  let bestCols = 1;
  let bestRows = numCells;

  //When the number of cells are 4 always use a 2 X 2 grid for better aesthetics
  //The calculation below could return a 3 X 2 sometimes
  if (numCells === 4) {
    return {
      rows: 2,
      cols: 2,
      cellWidth: containerWidth / 2,
      cellHeight: containerHeight / 2,
    };
  }

  //Iterate and test all combinations
  for (let cols = 1; cols <= numCells; cols++) {
    const boxWidth = containerWidth / cols;
    const boxHeight = boxWidth / aspectRatio;
    const rows = Math.ceil(numCells / cols);

    // Check if the layout fits within the container height
    if (rows * boxHeight <= containerHeight) {
      const tileSize = boxWidth * boxHeight;
      if (tileSize > maxTileSize) {
        maxTileSize = tileSize;
        bestCols = cols;
        bestRows = rows;
      }
    }
  }

  const bestCellWidth = containerWidth / bestCols;
  const bestCellHeight = bestCellWidth / aspectRatio;

  return {
    rows: bestRows,
    cols: bestCols,
    cellWidth: bestCellWidth,
    cellHeight: bestCellHeight,
  };
}
