import LoadingSpinner from '@/components/LoadingSpinner';
import { Modal, Tabs, type TabsProps } from 'antd';
import { Suspense, useMemo, useState } from 'react';
import CreateUpdateSiteForm from '..';
import type { CreateUpdateSite_labels$key } from '../__generated__/CreateUpdateSite_labels.graphql';
import ImportSites from './ImportData';
import styles from './style.less';

type CreateSiteModalProps = {
  siteConnectionId: string;
  labelConnectionRef: CreateUpdateSite_labels$key;
  refreshData: () => void;
  children: React.ReactNode;
};

const CreateSiteModal = ({
  siteConnectionId,
  labelConnectionRef,
  refreshData,
  children,
}: CreateSiteModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const items: TabsProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: 'Add Site',
        children: (
          <CreateUpdateSiteForm
            siteConnectionId={siteConnectionId}
            labelConnectionRef={labelConnectionRef}
            onClose={() => setIsOpen(false)}
          />
        ),
      },
      {
        key: '2',
        label: 'Add Multiple Sites',
        children: <ImportSites refreshData={refreshData} />,
      },
    ],
    [siteConnectionId, labelConnectionRef, refreshData],
  );

  return (
    <Suspense fallback={<LoadingSpinner fontSize={12} />}>
      <Modal
        className={styles['modal']}
        width={400}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}>
        <Tabs defaultActiveKey="1" items={items} centered />
      </Modal>
      <span
        onClick={() => {
          setIsOpen(true);
        }}>
        {children}
      </span>
    </Suspense>
  );
};

export default CreateSiteModal;
