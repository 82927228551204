import { Values } from '@/types/utils';
import { getCurrentCustomerID } from '@/utils/utils';
import { Suspense, useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import Combobox from '../Combobox';
import { ComboboxProps, ValueType } from '../Combobox/types';
import LoadingSpinner from '../LoadingSpinner';
import { Contexts } from './constants';
import { ContextualComboboxQuery } from './__generated__/ContextualComboboxQuery.graphql'; // import the generated type

interface ContextualComboboxProps extends ComboboxProps {
  context: Values<typeof Contexts>;
  value?: ValueType;
  selectedLabel?: string;
}

const query = graphql`
  query ContextualComboboxQuery($customerId: Int!) {
    contextOptions(customerId: $customerId) {
      context @required(action: LOG)
      options @required(action: LOG) {
        OptionID @required(action: LOG)
        OptionText @required(action: LOG)
      }
    }
  }
`;

const ContextualCombobox = ({
  context,
  value,
  selectedLabel,
  ...props
}: ContextualComboboxProps) => {
  const customerId = getCurrentCustomerID();
  const data = useLazyLoadQuery<ContextualComboboxQuery>(query, { customerId });

  const options = useMemo(() => {
    const contextOptions = data.contextOptions?.find(
      (contextSet) => contextSet?.context === context,
    );

    const optionsList = contextOptions
      ? contextOptions.options
          .filter(
            (option): option is NonNullable<typeof option> => option != null,
          )
          .map((option) => ({
            label: option.OptionText,
            value: option.OptionID,
          }))
      : ([] as { label: string; value: number | string }[]);

    // If value is not part of the options, add it
    if (value && !optionsList.some((opt) => opt.value == value)) {
      optionsList.push({
        label: selectedLabel || String(value),
        value: value,
      });
    }
    return optionsList;
  }, [data, context, value, selectedLabel]);

  return <Combobox options={options} value={value} {...props} />;
};

const ContextualComboboxWithSuspense = (props: ContextualComboboxProps) => {
  return (
    <Suspense fallback={<LoadingSpinner fontSize={12} />}>
      <ContextualCombobox {...props} />
    </Suspense>
  );
};

export default ContextualComboboxWithSuspense;
