import {
  addApp,
  deleteApp,
  getAllApps,
  getApp,
  updateApp,
} from '@/services/apps';
import { ACCESS_CONTROL_EVENTS } from '@/utils/apps';
import { getCurrentCustomerID } from '@/utils/utils';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import type { Model } from 'dva';

export type AppsModalState = {
  all: any[];
  byID: Object;
};

const AppModal: Model & { state: AppsModalState } = {
  namespace: 'apps',
  state: {
    all: [],
    byID: {},
  },
  effects: {
    *fetchAllApps(action, { call, put }) {
      const {} = action;
      const response = yield call(getAllApps);
      if (!response.success) {
        return null;
      }
      const apps = response.data;
      yield put({
        type: 'saveAllApps',
        payload: apps,
      });
      const customerID = getCurrentCustomerID();
      yield put({
        type: 'user/setupPersistentSub',
        payload: {
          type: 'psubscribe',
          id: uuidv4(),
          data: {
            channels: apps.map(
              (app) => `/customer/${customerID}/app/${app.AppID}/*`,
            ),
          },
        },
      });

      return response;
    },
    *fetchApp(action, { call, put }) {
      const { appID, payload } = action;
      const response = yield call(() => getApp(appID, payload));
      if (response.success) {
        yield put({
          type: 'saveApp',
          payload: response.data,
          appID,
        });
      }
      return response;
    },
    *doAppOpNoloader(action, { call, put }) {
      const { appID, payload, customReducer } = action;
      const response = yield call(() => getApp(appID, payload));
      if (response.success && customReducer) {
        yield put({
          type: 'saveAppOp',
          payload: response.data,
          customReducer,
          appID,
        });
      }
      return response;
    },
    *doAppOp(action, { call, put }) {
      const { appID, payload, customReducer, followUpEffect = [] } = action;
      const response = yield call(() => getApp(appID, payload));
      if (
        response.success &&
        Array.isArray(followUpEffect) &&
        followUpEffect.length > 0
      ) {
        for (const effect of followUpEffect) {
          const {
            eff_type = null,
            eff_vars = {},
            eff_cb = (_res) => {},
          } = effect;
          if (eff_type) {
            // @ts-expect-error
            const _res = yield put.resolve({
              type: eff_type,
              ...eff_vars,
            });
            console.log('eff_cb', _res);
            eff_cb(_res);
          }
        }
      }
      if (response.success && customReducer) {
        yield put({
          type: 'saveAppOp',
          payload: response.data,
          customReducer,
          appID,
        });
      }
      return response;
    },
    *addAccessControlActivityFromWSmessage(action, { put }) {
      const { appID, activity } = action;
      if (appID && activity) {
        yield put({
          type: 'saveAccessControlActivityFromWSmessage',
          appID,
          activity,
        });
        return {
          success: true,
          data: {
            AppID: appID,
          },
        };
      }

      return { success: false };
    },
    *addApp({ payload }, { call, put }): Generator<any> {
      const response: any = yield call(addApp, payload);
      if (response.success) {
        yield put({
          type: 'saveAllApps',
          payload: response.data,
        });
      }
      return response;
    },
    *deleteApp({ payload }, { call, put }): Generator<any> {
      const response: any = yield call(deleteApp, payload);
      if (response.success) {
        yield put({
          type: 'saveAllApps',
          payload: response.data,
        });
      }
      return response;
    },
    *updateApp({ payload }, { call, put }): Generator<any> {
      const response: any = yield call(updateApp, payload);
      if (response.success) {
        yield put({
          type: 'saveAllApps',
          payload: response.data,
        });
      }
      return response;
    },
  },
  reducers: {
    saveAllApps(state, action) {
      const apps = action.payload;
      const newById = apps.reduce((acc, app) => {
        acc[app.AppID] = { ...state.byID[app.AppID], ...app };
        return acc;
      }, {});
      return { ...state, all: apps, byID: newById };
    },
    saveApp(state, action) {
      const { byID } = state;
      byID[action.appID] = { ...byID[action.appID], ...action.payload };
      return { ...state, byID };
    },
    saveAppOp(state, action) {
      const { byID } = _.cloneDeep(state);
      // customReducer is used for specific app ops
      if (action.customReducer) {
        const appState = byID[action.appID];
        const newAppState = action.customReducer(appState, action.payload);
        byID[action.appID] = newAppState;
        return { ...state, byID };
      }
      return state;
    },
    saveAccessControlActivityFromWSmessage(state, action) {
      const { byID } = state;
      const appId = _.get(action, 'appID', null);
      const activity = _.get(action, 'activity', null);
      if (appId && activity && appId in byID) {
        const activities = _.get(byID[appId], 'Data.activity', null);
        const new_activities = activity.filter(
          (act) =>
            ACCESS_CONTROL_EVENTS.indexOf(_.get(act, 'event', null)) != -1,
        );
        if (Array.isArray(activities)) {
          activities.unshift(...new_activities);
          byID[appId]['Data']['activity'] = activities;
        } else {
          byID[appId]['Data'] = {
            activity: new_activities,
          };
        }
        return { ...state, byID };
      }
      return state;
    },
  },
};

export default AppModal;
