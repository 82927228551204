import { Image } from 'antd';
import styled from 'styled-components';

export const VideoThumbnail = styled.div(
  ({ theme }) => `
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 150px;
  background: ${theme.token?.colorBorderSecondary};
  border-radius: ${theme.token?.borderRadius}px;

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 10px;
    color: white;
    font-size: 48px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
`,
);

export const SampleImage = styled(Image)`
  height: auto;
  border-radius: 5px;
  max-width: 250px;
`;
