import { ReactComponent as BackwardIcon } from '@/assets/backward.svg';
import { ReactComponent as ClipIcon } from '@/assets/clip.svg';
import { ReactComponent as ForwardIcon } from '@/assets/forward.svg';
import { ReactComponent as FullScreenArrow } from '@/assets/fullscreen-arrow.svg';
import { ReactComponent as PlayIcon } from '@/assets/play.svg';
import { ReactComponent as SkipBackwardIcon } from '@/assets/skip-backward.svg';
import { ReactComponent as SkipForwardIcon } from '@/assets/skip-forward.svg';
import { ReactComponent as VolumeMute } from '@/assets/volume-mute.svg';
import { ReactComponent as Volume } from '@/assets/volume.svg';
import {
  LeftOutlined,
  LoadingOutlined,
  MinusOutlined,
  PauseOutlined,
  PlusOutlined,
  RightOutlined,
  ShrinkOutlined,
} from '@ant-design/icons';
import { DatePicker, Tooltip } from 'antd';
import classnames from 'classnames';
import React from 'react';
import { HotKeys } from 'react-hotkeys';

import {
  displayTZ,
  getFlexibleDateFormat,
  PLAY_BACK_RATES,
} from '@/utils/utils';

import ShortcutsInfo from '@/components/shortcuts-info';
import SharingCtrl from './sharing-ctrl';

import styles from './style.less';

const VolumeCtrl = ({ toggleMute, isMuted }: any) => {
  return (
    <>
      <div onClick={toggleMute} className={styles['control-container']}>
        {!isMuted && <Volume style={{ height: '14px' }} />}
        {isMuted && <VolumeMute style={{ height: '14px' }} />}
      </div>
    </>
  );
};

// if [Live] is off, it can be turned 'on' by clicking
// if [Live] is on, it cannot be turned 'off'. this is because the action is
// ambiguous. do you want to pause? but then on unpause you get out of live. do
// you want to go non-Live? but then where's your cursor supposed to be?
const LiveCtrl = ({ toggleLive, isLive }: any) => {
  return (
    <>
      <div
        onClick={() => !isLive && toggleLive()}
        className={styles['control-container']}>
        <span
          className={classnames(
            styles['live-ctrl'],
            isLive ? styles['active'] : '',
          )}>
          <span className={styles['live-ctrl-bullet']}>&bull;&nbsp;</span>
          <span>LIVE</span>
        </span>
      </div>
    </>
  );
};

const FullScreenCtrl = ({ toggleFullScreen, isFullscreen }: any) => {
  return (
    <>
      <div onClick={toggleFullScreen} className={styles['control-container']}>
        {isFullscreen ? (
          <ShrinkOutlined style={{ height: '10px' }} />
        ) : (
          <FullScreenArrow style={{ height: '10px' }} />
        )}
      </div>
    </>
  );
};

const SpeedDisplayCtrl = ({ playBackRate }) => {
  let [lowValStr, lowVal] = PLAY_BACK_RATES[0];
  let [highValStr, highVal] = _.last(PLAY_BACK_RATES);

  let lowSelected = playBackRate[0] === lowValStr ? styles['selected'] : '';
  let highSelected = playBackRate[0] === highValStr ? styles['selected'] : '';

  let [midSelected, midValueStr] = [false, 0, ''];

  let anchorPct;
  if (lowSelected) {
    anchorPct = '0';
  } else if (highSelected) {
    anchorPct = '100%';
  } else {
    let [low, high] = [Math.log(lowVal), Math.log(highVal)];
    let scale = (high - low) / 100;
    let pos = (Math.log(playBackRate[1]) - low) / scale;

    midSelected = true;
    anchorPct = `${pos}%`;
    midValueStr = playBackRate[0];
  }

  return (
    <div className={styles['speed-display-ctn']}>
      <div className={styles['triangle-ctn']}>
        <div
          className={styles['triangle']}
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
        <div
          className={styles['vert']}
          style={{ height: anchorPct, left: anchorPct }}></div>
      </div>
      <div className={styles['labels']}>
        <div className={`${styles['low']} ${lowSelected}`}>{lowValStr}</div>
        <div className={`${styles['high']} ${highSelected}`}>{highValStr}</div>
        {midSelected && (
          <div
            className={`${styles['mid']} ${styles['selected']}`}
            style={{ left: `calc(${anchorPct} - 15px)`, width: '30px' }}>
            {midValueStr}
          </div>
        )}
      </div>
    </div>
  );
};

const PlayCtrl = ({
  playBackRate,
  decreasePlayBackRate,
  increasePlayBackRate,
  isLive,
  isPlaying,
  skipBackward,
  skipForward,
  togglePlayBack,
  enablePlayBackRateChange,
}: any) => {
  let isFaster = playBackRate[1] > 1;
  let isSlower = playBackRate[1] < 1;

  let ret = React.useMemo(
    () => (
      <div className={styles['play-ctn']}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}>
          {skipBackward && (
            <Tooltip
              placement="bottom"
              mouseEnterDelay={0.5}
              title="Skip Backward">
              <div
                onClick={skipBackward}
                className={styles['control-container']}>
                <SkipBackwardIcon style={{ height: '24px' }} />
              </div>
            </Tooltip>
          )}
          <Tooltip placement="bottom" mouseEnterDelay={0.5} title="Play slower">
            <div
              onClick={enablePlayBackRateChange && decreasePlayBackRate}
              className={styles['control-container']}>
              <div
                className={isSlower ? styles['rate-selected'] : undefined}
                style={{ height: '24px' }}>
                <BackwardIcon
                  style={{ opacity: !enablePlayBackRateChange && 0.15 }}
                />
              </div>
            </div>
          </Tooltip>
        </div>
        <div
          onClick={togglePlayBack}
          className={styles['control-container']}
          id="timeline-play-btn">
          {!isPlaying && <PlayIcon style={{ height: '24px' }} />}
          {isPlaying && <PauseOutlined style={{ fontSize: '24px' }} />}
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}>
          <Tooltip placement="bottom" mouseEnterDelay={0.5} title="Play faster">
            <div
              onClick={enablePlayBackRateChange && increasePlayBackRate}
              className={styles['control-container']}>
              <div
                className={isFaster ? styles['rate-selected'] : undefined}
                style={{ height: '24px' }}>
                <ForwardIcon
                  style={{ opacity: !enablePlayBackRateChange && 0.15 }}
                />
              </div>
            </div>
          </Tooltip>
          {skipForward && (
            <Tooltip
              placement="bottom"
              mouseEnterDelay={0.5}
              title="Skip Forward">
              <div
                onClick={!isLive && skipForward}
                className={styles['control-container']}>
                <SkipForwardIcon
                  style={{ height: '24px', opacity: isLive && 0.15 }}
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    ),
    [
      playBackRate,
      decreasePlayBackRate,
      increasePlayBackRate,
      isLive,
      isPlaying,
      skipBackward,
      skipForward,
      togglePlayBack,
      enablePlayBackRateChange,
    ],
  );

  return ret;
};

const TimelineNavCtrl = ({
  timezone,
  loadingHistory,
  axisScales,
  currentScale,
  cycleScale,
  scrollAxis,
  setAnchorDate,
  resetAnchorDate,
  isFullscreen,
}: any) => {
  let cantZoomIn = axisScales.indexOf(currentScale) === 0;
  let cantZoomOut = axisScales.indexOf(currentScale) === axisScales.length - 1;

  // controls get re-rendered every few msecs as the playhead moves and there
  // is no reason to keep recreating this object if the timezone/setAnchorDate
  // haven't changed.
  const ret = React.useMemo(
    () => (
      <div className={styles['controls-nav']}>
        <div className={styles['controls-ctn']}>
          <Tooltip
            placement="bottom"
            mouseEnterDelay={0.5}
            title="Zoom Out Timeline">
            <div
              onClick={() => cycleScale('forward')}
              className={classnames(
                styles['control-container'],
                cantZoomOut ? styles['control-disabled'] : '',
              )}>
              <MinusOutlined
                style={{ fontSize: '14px', opacity: cantZoomOut && 0.15 }}
              />
            </div>
          </Tooltip>
          <Tooltip
            placement="bottom"
            mouseEnterDelay={0.5}
            title="Zoom In Timeline">
            <div
              onClick={() => cycleScale('backward')}
              className={classnames(
                styles['control-container'],
                cantZoomIn ? styles['control-disabled'] : '',
              )}>
              <PlusOutlined
                style={{ fontSize: '14px', opacity: cantZoomIn && 0.15 }}
              />
            </div>
          </Tooltip>
        </div>
        <div className={styles['controls-ctn']}>
          <Tooltip
            placement="bottom"
            mouseEnterDelay={0.5}
            title="Scroll Back In Time">
            <div
              onClick={() => scrollAxis('backward', true)}
              className={styles['control-container']}>
              <LeftOutlined style={{ fontSize: '14px' }} />
            </div>
          </Tooltip>
          {loadingHistory ? (
            <div className={styles['control-container']}>
              <LoadingOutlined style={{ fontSize: 13 }} spin />
            </div>
          ) : (
            <Tooltip placement="bottom" mouseEnterDelay={0.5} title="Reset">
              <div
                onClick={() => resetAnchorDate()}
                className={styles['control-container']}>
                <div
                  style={{
                    width: '13px',
                    textAlign: 'center',
                    fontSize: '13px',
                    lineHeight: '13px',
                  }}>
                  &bull;
                </div>
              </div>
            </Tooltip>
          )}
          <Tooltip
            placement="bottom"
            mouseEnterDelay={0.5}
            title="Scroll Forward In Time">
            <div
              onClick={() => scrollAxis('forward', true)}
              className={styles['control-container']}>
              <RightOutlined style={{ fontSize: '14px' }} />
            </div>
          </Tooltip>
        </div>
        {/* <Tooltip placement="bottom" mouseEnterDelay={1} title="Edit Timeframe"> */}
        <div className={styles['controls-ctn']}>
          <div className={styles['control-container']}>
            <DatePicker
              className="no-input-datepicker"
              showTime
              getPopupContainer={
                isFullscreen
                  ? (popup) => {
                      const datePickerElement = popup.parentElement;
                      const timelineCtn = datePickerElement?.closest(
                        '#timeline-ctn',
                      ) as HTMLElement;
                      return timelineCtn || popup;
                    }
                  : undefined
              }
              placement="topRight"
              showNow={false}
              showToday={false}
              allowClear={false}
              onChange={(d) => {
                if (d) {
                  // a datetime has been selected. if a timezone is
                  // attached to this player, we have to interpret
                  // this request as that the user wants to browse to
                  // the specific selected time in the timezone of the
                  // player, NOT the user's current timezone OR UTC
                  if (timezone) {
                    // this says - keep the display time, but shift the unixstamp
                    // baseline so now it's the display time in this timezone
                    // e.g. let's say the player is showing a channel in New York,
                    // and the user is in SF. If the selection is "10:14:15", the
                    // d object starts off as "10:14:15 PST". however, what the user
                    // really wants is "10:14:15 EST". so we need to reset the
                    // timezone while keeping the "10:14:15" value - we don't want
                    // to interpret the user input as "04:14:15 UTC" or whatever
                    d.tz(timezone, true);
                  }
                  setAnchorDate(d.valueOf() / 1000, true);
                }
              }}
            />
          </div>
        </div>
        {/* </Tooltip> */}
      </div>
    ),
    [
      timezone,
      loadingHistory,
      axisScales,
      currentScale,
      cycleScale,
      scrollAxis,
      setAnchorDate,
      resetAnchorDate,
      isFullscreen,
    ],
  );
  return ret;
};

type TimelineControlsProps = {
  showLive?: boolean;
  isPlaying?: boolean;
  isMuted?: boolean;
  isFullscreen?: boolean;
  togglePlayBack?: (...args: any[]) => any;
  toggleMute?: (...args: any[]) => any;
  toggleFullScreen?: (...args: any[]) => any;
  axisAnchorDate?: number;
  setAnchorDate?: (...args: any[]) => any;
  resetAnchorDate?: (...args: any[]) => any;
  scrollAxis?: (...args: any[]) => any;
  cycleScale?: (...args: any[]) => any;
} & typeof defaultProps;

const wrapFn = (fn, p1, p2) => {
  return (e) => {
    e.preventDefault();
    fn(p1, p2);
  };
};

let shortcutsInfoRef;
let controlsRef;

const TimelineControls = ({
  setControlsRef,
  currentPlayTime,
  playBackRate,
  showShare,
  showLive,
  shareContext,
  targetShareRange,
  showShareRangeControl,
  axisScales,
  currentScale,
  isMuted,
  isLive,
  isPlaying,
  isFullscreen,
  timezone,
  cycleScale,
  decreasePlayBackRate,
  increasePlayBackRate,
  enablePlayBackRateChange,
  resetAnchorDate,
  scrollAxis,
  setAnchorDate,
  centerTimeline,
  skipBackward,
  skipForward,
  skipTime,
  toggleFullScreen,
  toggleMute,
  togglePlayBack,
  toggleLive,
  loadingHistory,
}: TimelineControlsProps) => {
  const shortcutsInfo = React.useMemo(
    () => (
      <ShortcutsInfo
        ref={(c) => (shortcutsInfoRef = c)}
        showCTA={false}
        type="timeline"
        container={document.getElementById('root')}
        focusedElement={controlsRef}
      />
    ),
    [controlsRef],
  );

  const fullScreenCtrl = React.useMemo(
    () => (
      <Tooltip placement="bottom" mouseEnterDelay={0.5} title="Fullscreen">
        <div style={{ display: 'flex' }}>
          <FullScreenCtrl
            toggleFullScreen={toggleFullScreen}
            isFullscreen={isFullscreen}
          />
        </div>
      </Tooltip>
    ),
    [toggleFullScreen, isFullscreen],
  );

  const volumeCtrl = React.useMemo(
    () => (
      <Tooltip
        placement="bottom"
        mouseEnterDelay={0.5}
        title={isMuted ? 'Unmute' : 'Mute'}>
        <div style={{ display: 'flex' }}>
          <VolumeCtrl toggleMute={toggleMute} isMuted={isMuted} />
        </div>
      </Tooltip>
    ),
    [isMuted, toggleMute],
  );

  const sharingCtrlCTA = React.useMemo(
    () => (
      <Tooltip placement="bottom" mouseEnterDelay={0.5} title="Share Clip">
        <div style={{ display: 'flex' }}>
          <div
            className={styles['control-container']}
            onClick={() => isPlaying && togglePlayBack()}>
            <ClipIcon />
          </div>
        </div>
      </Tooltip>
    ),
    [isPlaying, togglePlayBack],
  );

  return (
    <HotKeys
      id="timeline-control-ctn"
      tabIndex={1}
      innerRef={(ref) => {
        controlsRef = ref;
        setControlsRef(ref);
      }}
      className={styles['channel-controls']}
      keyMap={{
        PLAY_PAUSE: ['space', 'k'],
        MUTE_UNMUTE: 'm',
        TOGGLE_FULLSCREEN: 'f',
        FORWARD_FRAME: '.',
        BACKWARD_FRAME: ',',
        SKIP_FORWARD: ['meta+right', 'ctrl+right'],
        SKIP_BACKWARD: ['meta+left', 'ctrl+left'],
        FORWARD_5_SECONDS: 'right',
        BACKWARD_5_SECONDS: 'left',
        FORWARD_10_SECONDS: 'j',
        BACKWARD_10_SECONDS: 'l',
        SPEED_UP: 'shift+.',
        SLOW_DOWN: 'shift+,',
        SCROLL_BACKWARD: 'shift+[',
        SCROLL_FORWARD: 'shift+]',
        CYCLE_BACKWARD: ']',
        CYCLE_FORWARD: '[',
        RESET_ANCHOR_DATE: ';',
        CENTER_TIMELINE: 'shift+;',
        SHOW_SHORTCUTS: ['/', 'shift+/'],
      }}
      handlers={{
        PLAY_PAUSE: wrapFn(togglePlayBack),
        MUTE_UNMUTE: wrapFn(toggleMute),
        TOGGLE_FULLSCREEN: wrapFn(toggleFullScreen),
        SKIP_FORWARD: wrapFn(skipForward),
        SKIP_BACKWARD: wrapFn(skipBackward),
        FORWARD_FRAME: wrapFn(skipTime, 1 / 15),
        BACKWARD_FRAME: wrapFn(skipTime, -1 / 15),
        FORWARD_SECOND: wrapFn(skipTime, 1),
        BACKWARD_SECOND: wrapFn(skipTime, -1),
        FORWARD_5_SECONDS: wrapFn(skipTime, 5),
        BACKWARD_5_SECONDS: wrapFn(skipTime, -5),
        FORWARD_10_SECONDS: wrapFn(skipTime, 10),
        BACKWARD_10_SECONDS: wrapFn(skipTime, -10),
        SPEED_UP: wrapFn(increasePlayBackRate),
        SLOW_DOWN: wrapFn(decreasePlayBackRate),
        SCROLL_BACKWARD: wrapFn(scrollAxis, 'backward', true),
        SCROLL_FORWARD: wrapFn(scrollAxis, 'forward', true),
        CYCLE_BACKWARD: wrapFn(cycleScale, 'backward'),
        CYCLE_FORWARD: wrapFn(cycleScale, 'forward'),
        RESET_ANCHOR_DATE: wrapFn(resetAnchorDate),
        CENTER_TIMELINE: wrapFn(centerTimeline),
        SHOW_SHORTCUTS: (e) => {
          e.preventDefault();
          shortcutsInfoRef?.toggleDrawer();
        },
      }}>
      {shortcutsInfo}
      <div className={styles['controls-left']}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {fullScreenCtrl}
          {volumeCtrl}
          {showShare && shareContext !== null && (
            <SharingCtrl
              targetShareRange={targetShareRange}
              showShareRangeControl={showShareRangeControl}
              context={shareContext}
              timezone={timezone}>
              {sharingCtrlCTA}
            </SharingCtrl>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {currentPlayTime && (
            <div
              onClick={() => console.log(currentPlayTime)}
              className={styles['play-time']}>
              {getFlexibleDateFormat(
                moment.tz(currentPlayTime * 1000, timezone),
                true,
              )}
              &nbsp;
              <div style={{ color: '#8E8E95', fontSize: '8px' }}>
                {displayTZ(timezone)}
              </div>
            </div>
          )}
          {showLive && (
            <div style={{ display: 'flex' }}>
              <LiveCtrl toggleLive={toggleLive} isLive={isLive} />
            </div>
          )}
        </div>
      </div>
      <div className={styles['play-controls-ctn']}>
        <PlayCtrl
          isLive={isLive}
          isPlaying={isPlaying}
          togglePlayBack={togglePlayBack}
          playBackRate={playBackRate}
          skipForward={skipForward}
          skipBackward={skipBackward}
          increasePlayBackRate={increasePlayBackRate}
          decreasePlayBackRate={decreasePlayBackRate}
          enablePlayBackRateChange={enablePlayBackRateChange}
        />
      </div>
      <div className={styles['controls-right']}>
        <SpeedDisplayCtrl playBackRate={playBackRate} />
        <TimelineNavCtrl
          timezone={timezone}
          loadingHistory={loadingHistory}
          axisScales={axisScales}
          currentScale={currentScale}
          cycleScale={cycleScale}
          scrollAxis={scrollAxis}
          resetAnchorDate={resetAnchorDate}
          setAnchorDate={setAnchorDate}
          isFullscreen={isFullscreen}
        />
      </div>
    </HotKeys>
  );
};

const defaultProps = {
  showLive: false,
  showShare: true,
  shareContext: {},
  axisAnchorDate: 0,
  axisScales: [],
  axisRange: [],
  currentScale: '',
  cycleScale: (_direction) => {},
  scrollAxis: (_direction) => {},
  decreasePlayBackRate: () => {},
  increasePlayBackRate: () => {},
  isMuted: false,
  isPlaying: false,
  isFullscreen: false,
  isLive: false,
  playBackRate: ['1x', 1],
  resetAnchorDate: () => {},
  setAnchorDate: () => {},
  showTracks: true,
  skipBackward: () => {},
  skipForward: () => {},
  toggleFullScreen: () => {},
  toggleMute: () => {},
  toggleLive: () => {},
  togglePlayBack: () => {},
  toggleTracks: () => {},
};

TimelineControls.defaultProps = defaultProps;

// export default TimelineControls;
export default React.memo((props) => <TimelineControls {...props} />);
