import Hero from '@/assets/launchpad_hero.png';
import { Image, Typography } from 'antd';
import ConfigureAccordion from '../ConfigureAccordion';
import Showcases from '../Showcases';
import { Container, HeroText, Line1, Line2, YellowText } from './styles';

const { Title, Text } = Typography;

const Landing = () => {
  return (
    <>
      <HeroText vertical gap="middle" justify="center" align="center">
        <Line1>For entrepreneurs and AI developers</Line1>
        <Title level={1} style={{ margin: '0px', textAlign: 'center' }}>
          Launch Your Real-Time <br /> <YellowText>Computer Vision </YellowText>
          Company
        </Title>
        <Image
          src={Hero}
          preview={false}
          style={{
            pointerEvents: 'none',
            maxWidth: '600px',
            marginTop: '32px',
            marginBottom: '32px',
          }}
        />
        <Line2>
          Dragonfruit Launchpad helps developers integrate real-time video
          ingestion and advanced scene analysis into their applications,
          enabling a new generation of powerful computer vision solutions.
        </Line2>
        <Text>
          Whether you want to detect defects, monitor situations, or receive
          alarms based on scene understanding, our platform makes it easy to
          configure your pipeline and get started in just a few clicks.
        </Text>
      </HeroText>
      <Container>
        <div style={{ margin: '24px 0px' }}>
          <ConfigureAccordion />
        </div>
        <Showcases />
      </Container>
    </>
  );
};

export default Landing;
