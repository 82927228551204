import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from '@umijs/max';
import { Typography, type CollapseProps } from 'antd';
import { useMemo, useState } from 'react';
import { AccountCreation, Billing, Ingestion } from '../Forms';
import { ENDPOINT_TYPES_MAP, ENDPOINT_TYPES_PRICES } from './constants';
import { Accordion, Banner, StyledCard, TwoColumnLayout } from './styles';
import {
  getPlatformSignupPayload,
  trackBillingComplete,
  trackIngestionComplete,
  trackSignupComplete,
} from './utils';

export const PriceBanner = ({
  price = 5,
  helpText,
}: {
  price?: number;
  helpText?: string;
}) => {
  return (
    <Banner>
      <Typography.Title level={4}>Estimated Price</Typography.Title>
      <Typography.Title level={4}>${price}</Typography.Title>
      <Typography.Text style={{ fontSize: '16px' }}>per day</Typography.Text>
      {helpText && (
        <Typography.Text style={{ marginTop: '16px' }}>
          {helpText}
        </Typography.Text>
      )}
    </Banner>
  );
};

const ConfigureAccordion = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    ingestion: {
      sourceType: 'rtsp',
      endpointType: ENDPOINT_TYPES_MAP.description,
    },
  });
  const dispatch = useDispatch();
  const isSignupInProgress = useSelector(
    (state) => state.loading.effects['user/launchpadSignup'],
  );
  const [isBillingInProgress, setIsBillingInProgress] = useState(false);
  const minimumBillingAmount =
    formData.ingestion.endpointType === ENDPOINT_TYPES_MAP.description ? 50 : 5;

  const handleIngestionChange = (values) => {
    const newData = {
      ...formData,
      ingestion: { ...formData.ingestion, ...values },
    };
    setFormData(newData);
    return newData;
  };

  const handleIngestionSubmit = (values) => {
    const newData = handleIngestionChange({ ...values, validated: true });
    trackIngestionComplete(newData);
    setCurrentStep(2);
  };

  const handleAccountCreation = (values) => {
    const data = { ...formData, account: values };
    setFormData(data);
    trackSignupComplete(data);
    dispatch({
      type: 'user/launchpadSignup',
      payload: getPlatformSignupPayload(data),
      cb: () => {
        setCurrentStep(3);
      },
    });
  };

  const handleBillingSubmit = async (values) => {
    const stripePromise = loadStripe(`${STRIPE_PUBLISHABLE_KEY}`);
    const newData = { ...formData, billing: values };
    setFormData(newData);
    trackBillingComplete(newData);
    setIsBillingInProgress(true);
    const response = await dispatch({
      type: 'accounts/getCheckoutSession',
      payload: {
        billing: values, // this has the final billing data
        ingestion: formData.ingestion, // this has the ingestion form data
      },
    });
    const { sessionId } = response.data;
    const stripe = await stripePromise;
    const { error } = await stripe?.redirectToCheckout({
      sessionId,
    });
    if (error) {
      message.error(error.message);
    }
    setIsBillingInProgress(false);
  };

  const isStep1Enabled = true;
  const isStep2Enabled = !!formData.ingestion?.validated;
  const isStep3Enabled = !!formData.account && !!formData.ingestion;

  const ingestionPrice = useMemo(() => {
    let streamsCount = 0;
    if (formData?.ingestion?.sourceType === 'csv') {
      streamsCount = formData?.ingestion?.rtspUrls?.length || 0;
    } else if (formData?.ingestion?.sourceType === 'rtsp') {
      streamsCount = formData?.ingestion?.rtspUrl ? 1 : 0;
    }
    const basePrice = 0.01;
    const pricePerStream =
      ENDPOINT_TYPES_PRICES[formData?.ingestion?.endpointType] || 0;
    const numQueriesPerDay =
      (formData?.ingestion?.callsPerMinute || 60) * 60 * 24;
    return (
      basePrice +
      streamsCount * pricePerStream * numQueriesPerDay
    ).toFixed(4);
  }, [
    formData?.ingestion?.sourceType,
    formData?.ingestion?.endpointType,
    formData?.ingestion?.rtspUrl,
    formData?.ingestion?.rtspUrls?.length,
    formData?.ingestion?.callsPerMinute,
  ]);
  const minAmountToPay = Math.max(minimumBillingAmount, Number(ingestionPrice));

  const items: CollapseProps['items'] = [
    {
      key: 1,
      label: 'Step 1: Configure Your Video Ingestion',
      collapsible: isStep1Enabled ? 'header' : 'disabled',
      children: (
        <StyledCard>
          <TwoColumnLayout>
            <Ingestion
              onSubmit={handleIngestionSubmit}
              onChange={handleIngestionChange}
            />
            <PriceBanner
              price={ingestionPrice}
              helpText="Price is calculated based on the number of streams, endpoint type, and max calls per minute."
            />
          </TwoColumnLayout>
        </StyledCard>
      ),
    },
    {
      key: 2,
      label: 'Step 2: Create your Developer Account',
      collapsible: isStep2Enabled ? 'header' : 'disabled',
      children: (
        <StyledCard>
          <AccountCreation
            onSubmit={handleAccountCreation}
            isInProgress={isSignupInProgress}
          />
        </StyledCard>
      ),
    },
    {
      key: 3,
      label: 'Step 3: Pre-pay for 1 Day',
      collapsible: isStep3Enabled ? 'header' : 'disabled',
      children: (
        <StyledCard>
          <TwoColumnLayout>
            <Billing
              amountPerDay={ingestionPrice}
              minAmountToPay={minAmountToPay}
              onSubmit={handleBillingSubmit}
              isBillingInProgress={isBillingInProgress}
              ingestionFormData={formData.ingestion}
            />
            <PriceBanner
              price={ingestionPrice}
              helpText="If auto-reload is off, the API stops automatically when your credits are over."
            />
          </TwoColumnLayout>
        </StyledCard>
      ),
    },
  ];

  return (
    <Accordion
      accordion
      ghost
      items={items}
      activeKey={currentStep}
      onChange={setCurrentStep}
    />
  );
};

export default ConfigureAccordion;
