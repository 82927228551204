import { AXIS_SCALES } from '@/constants';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import ReactGridLayout from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';
import {
  checkAndRecordRaceCondition,
  CUE_RENDER_ORDER,
  getSavedVideoCues,
} from '../../utils/timeline';
import mediaSync from '../../utils/timingsrc/media_sync';
import TIMINGSRC from '../../utils/timingsrc/timingsrc-v3';
import {
  CUE_TYPE,
  findAxisScaleThatFits,
  getAxisStartAndEnd,
  getPreciseInterval,
  isEqualWithoutFunctions,
  isFullScreen,
  PLAY_BACK_RATES,
  SHOULD_BE_LIVE_BUFFER,
  toggleFullScreen,
} from '../../utils/utils';
const AXIS_SCALE_KEYS = Object.keys(AXIS_SCALES);

import TimelineAxis from './axis';
import TimelineControls from './controls';
import HoverBar from './hover-bar';
import HoverPreview from './hover-preview';
import PlayBar from './play-bar';
import styles from './style.less';
// import { logUpdatedDiff } from '@/utils/utils';
import { getCurrentCustomerID } from '@/utils/utils';
import { CuesLayout } from './layout';

const currentCustomerID = getCurrentCustomerID();

type State = any;
type Props = any;

class Timeline extends React.Component<Props, State> {
  timingObject: any;
  timingSubscription: any;
  sequencerObject: any;
  timelineRef: any;
  timelineContainerRef: any;
  timelineData: any;
  defaultCueRenderRef: any;
  sequencerChangeSub: any;
  sequencerRemoveSub: any;
  activeVideoElems: any;

  historyLastLoaded: number;

  lcue: Record<string, any>; // last cue running by track

  static defaultProps = {
    cuesRange: undefined,
    cues: [],
    // eventsOnly forces the timeline to only load data relevant
    // to the events being passed in.
    eventsOnly: false,
    autoPlay: false,
    fitEventsOnAxis: false,
    layoutSpec: null,
    shareContext: {},
    showHideControls: false,
    showLive: false,
    showShare: false,
    // startTime: 0,
    timezone: '',
    loadHistory: () => {},
    channelIdOrder: [],
  };

  constructor(props) {
    super(props);
    const nowTime = Date.now() / 1000;
    const currTime = props.showLive
      ? nowTime
      : _.get(props, 'cuesRange[0]', props.startTime || nowTime);
    this.state = {
      activeCues: [],
      visibleCues: [],
      currentPlayTime: currTime,
      playBackRate: ['1x', 1],
      isMuted: true,
      isPlaying: false,
      isFullscreen: isFullScreen(),
      eventsOnly: false,
      pausedForLoading: false,
      axisConfig: {
        isHovering: false,
        axisLength: null,
        axisHeight: null,
        axisScale: 'HOURS1',
        axisRange: [],
        axisTicksCount: 25,
        axisAnchorDate: null,
        hoverTime: null,
        hoverMouseXposition: 0,
        hoverMouseYposition: 0,
      },
    };
    this.cueRefs = {};
    this.activeVideoElems = {};
    this.timelineRef = React.createRef();
    this.controlsRef = React.createRef();
    this.timelineContainerRef = React.createRef();
    this.defaultCueRenderRef = React.createRef();
    this.timingObject = new TIMINGSRC.TimingObject({
      range: [0, Infinity],
      position: currTime,
    });
    this.timelineData = new TIMINGSRC.Dataset();
    this.sequencerObject = new TIMINGSRC.Sequencer(
      this.timelineData,
      this.timingObject,
    );

    this.timingSubscription = this.timingObject.on('timeupdate', () =>
      this.onTimeUpdate(),
    );

    this.updateDatasetCues();

    _.bindAll(this, [
      'centerTimeline',
      'cycleScale',
      'decreasePlayBackRate',
      'increasePlayBackRate',
      'initTimeline',
      'loadHistoryForAxisRange',
      'onTimelineHover',
      'onTimelineClick',
      'pauseTObjForLoading',
      'playTObjAfterLoading',
      'resizeListener',
      'scrollAxis',
      'setAnchorDate',
      'setControlsRef',
      'showShareRangeControl',
      'skipBackward',
      'skipForward',
      'skipTime',
      'syncElementToTimingObj',
      'toggleFullScreen',
      'toggleLive',
      'toggleMute',
      'togglePlayBack',
      'updateAxisDimensions',
    ]);

    this.historyLastLoaded = 0;
    this.lcue = {};
  }

  resizeListener() {
    this.updateAxisDimensions();
  }

  componentDidMount() {
    this.updateAxisDimensions(this.initTimeline);
    this.setState({ timezone: this.props.timezone || 'UTC' });

    // try to get focus onto this element so we can use keyboard shortcuts
    // this will not be guaranteed but useful if we can get it.
    // when we're trying to explicitly hide controls, e.g. with Views, this
    // focusing property is problematic, so don't do that.
    if (!this.props.showHideControls) {
      this.focus();
    }
  }

  componentDidUpdate(prevProps) {
    // logUpdatedDiff(prevProps, this.props);
    const { cues, cuesRange, startTime, timezone } = this.props;

    // cues are heavy objects, so comparison needs to be done carefully
    const cuesAreSame = isEqualWithoutFunctions(
      prevProps.cues.map((cue) => cue.data),
      cues.map((cue) => cue.data),
    );

    if (!cuesAreSame || !_.isEqual(prevProps.cuesRange, cuesRange)) {
      this.sequencerObject.dataset.clear();
      this.activeVideoElems = {};

      this.updateDatasetCues();
      if (
        getSavedVideoCues(prevProps.cues).length === 0 &&
        getSavedVideoCues(this.props.cues).length > 0
      ) {
        // init the timeline if we got new saved cloud video cues
        this.updateAxisDimensions(this.initTimeline);
      } else if (prevProps.cues.length === 0 && this.props.cues.length !== 0) {
        // if we're getting cues for the first time, then too
        this.updateAxisDimensions(this.initTimeline);
      } else {
        // else don't init, just update
        this.updateAxisDimensions();
      }
      // if cues change such that new videos are added, this is required
      // to get the videos to be picked up for the activeCues state
      if (
        this.state.currentPlayTime !== this.timingObject.pos ||
        !this.state.isPlaying
      ) {
        this.onTimeUpdate();
      }
    }
    if (!_.isEqual(prevProps.startTime, startTime)) {
      this.updateAxisDimensions(this.initTimeline);
    } else if (
      this.timelineRef.current?.clientWidth &&
      !this.state.axisConfig?.axisLength
    ) {
      // if previously we didn't have a clientwidth and have one now
      this.updateAxisDimensions();
    }

    if (prevProps.timezone !== timezone && timezone !== this.state.timezone) {
      this.setState({ timezone: timezone || 'UTC' });
    }

    if (
      !cuesAreSame ||
      !_.isEqual(prevProps.layoutSpec, this.props.layoutSpec)
    ) {
      this.setState({ rerenderLayout: true });
      //Temp code for tracking, remove by end of August 24
      setTimeout(() => {
        try {
          checkAndRecordRaceCondition(
            prevProps.cues,
            this.props.cues,
            this.state.currentPlayTime,
          );
        } catch (e) {
          //do nothing
        }
      }, 0);
    }
  }

  componentWillUnmount() {
    if (this.timingSubscription) {
      this.timingSubscription = this.timingObject.off(this.timingSubscription);
      delete this.timingSubscription;
    }
    if (this.timingObject) {
      delete this.timingObject;
    }
    if (this.timelineData) {
      delete this.timelineData;
    }
    if (this.activeVideoElems) {
      Object.values(this.activeVideoElems).forEach((element) => {
        element.pause();
      });
    }
  }

  // this method is executed very frequently. keep it optimized!
  onTimeUpdate() {
    const { position, velocity } = this.timingObject.query();
    const { axisConfig } = this.state;
    const { cuesRange, eventsOnly } = this.props;
    if (_.get(cuesRange, 'length') !== 2) return;

    // when playing if currentPlayTime/position is outside axisRange update axis
    if (
      !_.isEmpty(axisConfig.axisRange) &&
      !_.inRange(position, axisConfig.axisRange[0], axisConfig.axisRange[1])
    ) {
      this.setAnchorDate(position);
    }

    // pause timing object if outside cuesRange. since the cuesrange don't include
    // streaming (which extends [0, Infinity]), we have to exclude the live situation
    if (
      !this.state.isLive &&
      position > cuesRange[1] &&
      eventsOnly &&
      velocity !== 0
    ) {
      if (currentCustomerID !== 1675) {
        this.pauseTracks();
      }
    }

    // find what cues are relevant for this time
    const currentInterval = new TIMINGSRC.Interval(position);
    const activeCues = this.timelineData.lookup(currentInterval);

    // if the active cues contain a cue that supports live
    // channels, we can stream video from the base station. note
    // that other cues can be active at this time; we'll only do
    // streaming if there are no other options
    const liveCues = activeCues.find((cue) => cue.data.hasLiveChannels) || [];
    // if there are any streaming cues, we can be live. note that there might be multiple
    // of these, for different channels: so if any one of the channels can be live, the
    // entire timeline is allowed to be live.
    const canBeLive = liveCues.length !== 0;

    // if there are no active cues but streaming ones, we can either stream an
    // archive from the base station, or we can do live streaming.
    // we guess what makes sense based on how close to 'now' we are.
    // we might _explicitly_ be made live, so we shouldn't override existing state
    // unless necessary
    let isLive = this.state.isLive;
    if (canBeLive) {
      // can we make an assumption that we want to be live?
      if (Date.now() / 1000 - position < SHOULD_BE_LIVE_BUFFER) {
        isLive = true;
      } else {
        isLive = false;
      }
    }

    let toUpdate = {
      currentPlayTime: position,
      isLive,
      canBeLive,
    };

    // this is an optimization. if the activeCues are pretty much
    // the same, don't update the state object. this will cause
    // fewer downstream renders as there are object comparisons
    // used by React.useMemo() for the grid layout
    if (!_.isEqual(activeCues, this.state.activeCues)) {
      toUpdate.activeCues = activeCues;
    }

    this.setState(toUpdate);
  }

  updateDatasetCues() {
    this.sequencerObject.dataset.update(this.props.cues);

    if (this.state.isPlaying && this.pausedForLoading) {
      this.playTObjAfterLoading();
    }
  }

  updateAxisDimensions(cb = () => {}) {
    if (this.timelineRef.current !== null) {
      const axisLength = this.timelineRef.current.clientWidth;
      const axisHeight = this.timelineRef.current.clientHeight;
      this.updateAxisConfig({ axisLength, axisHeight }, cb);
    }
  }

  // initializing on mount/update
  initTimeline() {
    const {
      cuesRange,
      eventsOnly,
      fitEventsOnAxis,
      autoPlay,
      startTime,
      showLive,
    } = this.props;

    if (_.get(cuesRange, 'length') !== 2) return;

    // this zooms the timeline in a way that shows as many of the media
    // segments as nicely as possible
    // when we're in investigations or search results, we want to show all
    // segments. when in channel mode, want to show most of it
    const { axisConfig } = this.state;
    const { axisScale, axisRange, axisAnchorDate } = findAxisScaleThatFits(
      axisConfig.axisTicksCount,
      cuesRange[0],
      cuesRange[1],
      // the following will ensure that we set up the visible axis to fully cover the
      // events if those are the only things we're supposed to show, or if that's what
      // the use case needs
      eventsOnly || fitEventsOnAxis,
    );

    // reset playBackRate to default (1x)
    this.resetPlayBackRate();

    this.updateAxisConfig(
      {
        axisScale,
        axisRange,
        axisAnchorDate,
      },
      () => this.updateSearchForm(),
    );

    // set current play time depending on history should be loaded or not
    const nowTime = Date.now() / 1000;
    if (startTime !== undefined) {
      // explicit start time set
      this.updateSeekTime(startTime + 0.001);
    } else if (showLive) {
      // seek to 'now'
      this.updateSeekTime(nowTime);
    } else {
      // eventsOnly implies we don't want to show anything beyond the events
      // passed in - e.g. no loading past history
      if (eventsOnly) {
        this.updateSeekTime(cuesRange[0] + 0.001);
      } else {
        // find the last stored video cue, in time order, to anchor the playhead
        const lastCue = _.chain(getSavedVideoCues(this.props.cues))
          .sortBy((cue) => cue.data.endEdge)
          .last()
          .value();
        let targetSeekTime = _.get(lastCue, 'interval.low');

        if (!targetSeekTime) {
          // perhaps there is no cue, but we still got a range to play with,
          // position towards the end of that scale.
          const scaleConfig = AXIS_SCALES[axisScale];
          targetSeekTime = cuesRange[1] - scaleConfig.ticks.step;
        }
        if (targetSeekTime) {
          this.updateSeekTime(targetSeekTime);
        }
      }
    }
    if (autoPlay) {
      if (this.props.cues.length) {
        this.playTracks();
      }
    }
  }

  resetPlayBackRate() {
    this.setState({
      playBackRate: ['1x', 1],
    });
  }

  loadHistoryForAxisRange() {
    this.props.loadHistory(this.state.axisConfig?.axisRange, true);
  }

  updateAxisConfig(config, cb = () => {}) {
    const { axisConfig } = this.state;
    const newAxisConfig = { ...axisConfig, ...config };

    const axisRange = newAxisConfig.axisRange;
    let visibleCues = this.state.visibleCues;
    if (axisRange?.length) {
      const currentInterval = getPreciseInterval(...axisRange);
      visibleCues = this.timelineData.lookup(currentInterval);
    }

    // if axis range has changed
    if (!_.isEqual(newAxisConfig.axisRange, axisConfig.axisRange)) {
      if (!this.props.eventsOnly) {
        this.historyLastLoaded = Date.now();
        this.props.loadHistory(newAxisConfig.axisRange);
      }
    }
    this.setState({ axisConfig: newAxisConfig, visibleCues }, cb);
  }

  syncElementToTimingObj(element: any, skewInMsecs: any) {
    const { timezone } = this.state;

    // keep track of video elements so they can be operated on in bulk.
    // this method _could_ be called every video frame, so if we should only
    // keep element references based on this stable key, otherwise the
    // references will balloon and potentially leak memory
    const key = element?.currentSrc && `${element.currentSrc}-${skewInMsecs}`;
    if (element !== null) {
      element.muted = this.state.isMuted;
      const syncedElement = mediaSync.mediaSync(element, this.timingObject, {
        // automute: true,
        skew: -(moment.tz(skewInMsecs, timezone).valueOf() / 1000 || 0),
        //debug: true,
        mode: 'auto',
      });
      this.activeVideoElems[key] = syncedElement;
      setTimeout(
        () =>
          mediaSync.mediaNeedKick(element, (err) => {
            // if a video was aborted, that doesn't mean we need a kick
            if (err.name === 'AbortError') {
              return;
            }

            // we only need to kick a given element once
            if (element.wasKicked) {
              return;
            }
            element.wasKicked = true;
            this.pauseTracks(false);
            setTimeout(() => {
              this.playTracks(false);
              if (!this.state.shouldBePlaying) {
                this.pauseTracks(false);
              }
            }, 50);
          }),
        0,
      );
      return syncedElement;
    }
  }

  updateSeekTime(timeInSecs) {
    this.timingObject.update({ position: timeInSecs });
  }

  updateSearchForm() {
    const { searchForm } = this.props;
    const { timezone } = this.state;

    const { axisRange } = this.state.axisConfig;
    if (axisRange.length === 2) {
      // select the middle 33%
      const nudge = (axisRange[1] - axisRange[0]) / 3;
      const from = axisRange[0] + nudge;
      const to = axisRange[1] - nudge;
      const dateRange = [from, to];

      this.setState({ targetShareRange: dateRange });

      if (
        searchForm &&
        searchForm.current &&
        'setDateRange' in searchForm.current
      ) {
        searchForm.current.setDateRange([
          moment.tz(axisRange[0] * 1000, timezone),
          moment.tz(axisRange[1] * 1000, timezone),
        ]);
      }
    }
  }

  pauseTObjForLoading() {
    const cuesByTrack = _.groupBy(
      this.state.activeCues,
      (cue) => cue.data.track,
    );
    if (Object.keys(cuesByTrack).length > 1) {
      //We have multiple tracks here and the timeline shouldn't really be paused
      //in favor of a single one
      return;
    }
    if (this.timingObject) {
      this.timingObject.update({ velocity: 0 });
    }
    this.pausedForLoading = true;
  }

  playTObjAfterLoading() {
    this.pausedForLoading = false;
    if (this.timingObject && this.state.isPlaying) {
      const { playBackRate } = this.state;
      this.timingObject.update({ velocity: playBackRate[1] });
    }
  }

  // XXX/akumar change this
  getActiveCueWidthHeight(no_of_cues: number): string[] {
    let width = '33%';
    let height = '45%';
    switch (no_of_cues) {
      case 1:
        width = '100%';
        height = '100%';
        break;
      case 2:
        width = '50%';
        height = '100%';
        break;
      case 3:
      case 4:
        width = '50%';
        height = '50%';
        break;
      case 5:
      case 6:
        width = '33%';
        height = '50%';
        break;
      default:
        width = '33%';
        height = '45%';
    }
    return [width, height];
  }

  // get cue from list of cues by track to render
  getRenderCueByTrack(track: any, cues: any[]) {
    // -- pick the primary cue other than archive
    // pick the cue that we'll use to render the video. for a given track,
    // there should only be one canonical video that can correspond to it -
    // and if multiple cues hit, they should all render the same.
    //
    // however, some cues can have data but no video info - these won't have a
    // renderer. for example, search results that come into play when a
    // clip is shared. don't choose those ones as primary

    // try the ones that are more likely to yield a visible video first
    let cue = _.chain(cues)
      .filter((c) => c.shouldRender())
      .sortBy((c) => CUE_RENDER_ORDER.indexOf(c.data.type))
      .last()
      .value();

    if (!cue) {
      return null;
    }

    return cue;
  }

  // main function to render the primary videos view
  renderCues(activeCues) {
    const { showLink = true, channelIdOrder = [] } = this.props;

    // don't dupe the same channel multiple times
    const cuesByTrack = _.groupBy(activeCues, (cue) => cue.data.track);

    // for this we need to do another pass to ignore tracks that we don't care about
    const cuesByTrackForVisibility = _.groupBy(
      activeCues.filter((cue) => cue.shouldRender()),
      (cue) => cue.data.track,
    );
    const [activeCueWidth, activeCueHeight] = this.getActiveCueWidthHeight(
      Object.keys(cuesByTrackForVisibility).length,
    );
    const { tileLayout } = this.props;

    // each track gets a separate video
    const renderedCues = Object.entries(cuesByTrack).map(([track, cues]) => {
      const cue = this.getRenderCueByTrack(track, cues);

      if (!cue) return null;
      // we need to merge the data across all the cues that are relevant
      // for this timestamp
      let media = _.cloneDeep(cue.data.media);
      for (let i = 0; i < cues.length; i += 1) {
        const thisCue = cues[i];
        // don't double count
        if (cue === thisCue) {
          continue;
        }
        if (cue.data.mediaMerger) {
          media = cue.data.mediaMerger(media, thisCue.data.media);
        }
      }

      const { axisAnchorDate } = this.state.axisConfig;
      const { position } = this.timingObject.query();

      // this key will be used by ReactGridLayout to match the <div>
      // to the corresponding layout location, so the 'i' of the layout
      // needs to match the 'key' we get from the track
      const key = cue.data.track;
      const render = cue.data.render(
        this,
        cue,
        media,
        axisAnchorDate,
        position,
        this.props.internalUser,
      );

      let label = cue.getLabel(showLink);

      return [
        key,
        <div
          key={key}
          className={styles['cue-ctn']}
          style={
            !tileLayout
              ? {
                  width: activeCueWidth,
                  height: activeCueHeight,
                }
              : {}
          }>
          {label && (
            <div className={styles['cue-label-ctn']}>
              <div className={styles['cue-label']}>{label}</div>
            </div>
          )}
          <div
            style={{ width: '100%', height: '100%' }}
            ref={(e) => {
              if (!e) {
                return;
              }
              this.cueRefs[key] = e;
            }}>
            {render}
          </div>
        </div>,
        cue,
      ];
    });

    // if nothing was rendered, it would be nice to show a message,
    // but that might cause glitching as things are loaded, so not
    // doing that right now.

    // remove empty ones, and sort by key, so ordering is consistent

    let cueTypesRendered = [];
    const cuesJSX = _.chain(renderedCues)
      .filter((x) => x)
      .sortBy([
        (x) => {
          const index = _.findIndex(
            channelIdOrder,
            (channelId: number) => channelId.toString() === x[0],
          );
          return index === -1 ? Infinity : index;
        },
        (x) => x[0],
      ])
      .map((x) => {
        cueTypesRendered.push(x[2].data.type);
        return x[1];
      })
      .value();

    //Side effect
    if (
      cueTypesRendered.indexOf(CUE_TYPE.STREAMING) > -1 &&
      this.state.playBackRate[1] != 1
    ) {
      this.resetPlayBackRate();
      this.timingObject.update({ velocity: 1 });
    }

    return [cuesJSX, cueTypesRendered];
  }

  // ======= Controls Start =======
  onTimelineHover(e) {
    if (!this.state.shareRangeControlVisibility) {
      e.stopPropagation();
    }
    const boundingClient = this.timelineRef.current.getBoundingClientRect();
    const x = e.clientX - boundingClient.left;
    const y = e.clientY - boundingClient.top;
    const { axisRange, axisLength } = this.state.axisConfig;
    const hoverTime =
      x * ((axisRange[1] - axisRange[0]) / axisLength) + axisRange[0];
    if (hoverTime !== this.state.axisConfig.hoverTime) {
      this.setState({ rerenderLayout: true });
    }
    this.updateAxisConfig({
      isHovering: true,
      hoverMouseXposition: x,
      hoverMouseYposition: y,
      hoverTime,
    });
  }

  onTimelineClick(e) {
    if (this.state.shareRangeControlVisibility) {
      return;
    }
    e.stopPropagation();
    const { visibleCues } = this.state;
    const { hoverTime, axisLength, axisRange } = this.state.axisConfig;

    // clicking anywhere in the future doesn't change anything,
    // keep where you are.
    const now = Date.now() / 1000;
    let target = hoverTime;
    if (target > now) {
      target = now;
    }
    // the view can be played live
    if (this.state.canBeLive) {
      if (this.state.isLive) {
        // clicking anywhere in the past takes us out
        if (hoverTime < now) {
          this.toggleLive();
        } else {
          // clicking in the future starts playing
          this.playTracks();
        }
      } else {
        // clicking in the future takes us live
        if (hoverTime > now) {
          this.toggleLive();
        }
      }
    }

    this.updateAxisConfig({ axisAnchorDate: target });
    // move playhead to this time
    this.updateSeekTime(target);

    const newHoverTime = this.getNewHoverTime(
      visibleCues,
      axisLength,
      axisRange,
      target,
    );

    if (newHoverTime != target) {
      this.updateSeekTime(newHoverTime);
    }
    this.focus();
  }

  toggleFullScreen() {
    toggleFullScreen(this.timelineContainerRef.current);
    // do this on a timeout because for some reason safari/mac doesn't
    // reflect the right width on the first go-around. perhaps it's
    // sending an intermediate value during an animation sequence? either
    // way, do it off-cycle.
    setTimeout(() => this.updateAxisDimensions(), 200);
    const { isFullscreen } = this.state;
    this.setState({ isFullscreen: !isFullscreen });
  }

  focus() {
    if (this.controlsRef?.focus) {
      this.controlsRef?.focus();
    }
  }

  toggleMute() {
    const { isMuted, activeCues } = this.state;
    this.setState({ isMuted: !isMuted }, () => {
      activeCues.forEach((cue) => {
        if (cue.data.type === CUE_TYPE.STREAMING) {
          cue.toggleMute(this.state.isMuted);
        }
      });
      Object.values(this.activeVideoElems).forEach((syncedElement) => {
        syncedElement.elem.muted = this.state.isMuted;
      });
    });
  }

  toggleLive(callback) {
    this.setState({ isLive: !this.state.isLive }, () => {
      // if we've turned live of, ensure that actually happens
      if (this.state.isLive) {
        this.playTracks();
        // a bit of breathing room
        this.updateSeekTime(Date.now() / 1000 - 0.25);
      }
      if (callback) {
        callback();
      }
    });
  }

  skipBackward() {
    const doSkip = () => {
      const { currentPlayTime } = this.state;
      const interval = new TIMINGSRC.Interval(currentPlayTime);

      let targetTime = 0;

      // if we have cues, go to the start of the nearest playing cue,
      // or a previous cue
      // see https://timingsrc.readthedocs.io/en/latest/timeddata/interval.html#interval-match
      const cues = this.timelineData.lookup(interval, 62 | 64);

      _.forEach(cues, (cue) => {
        // we might be _at_ the beginning of an interval already, in which
        // case we should go to a previous one
        if (cue.interval.low === currentPlayTime) {
          return;
        }
        targetTime = Math.max(targetTime, cue.interval.low);
      });

      if (targetTime) {
        this.setAnchorDate(targetTime, true);
      } else {
        this.scrollAxis('backward', true);
      }
    };

    // for live streams, we support a special skip back so you can get
    // to the last recorded video clip. if that's evoked, we should
    // turn the live stream off
    if (this.state.isLive) {
      this.toggleLive(doSkip);
    } else {
      doSkip();
    }
  }

  skipTime(delta) {
    const { currentPlayTime } = this.state;
    this.setAnchorDate(currentPlayTime + delta, true);
  }

  skipForward() {
    const { currentPlayTime } = this.state;
    const interval = new TIMINGSRC.Interval(currentPlayTime);

    let targetTime = Infinity;

    // if we have cues, go to the start of the next possible cue
    // see https://timingsrc.readthedocs.io/en/latest/timeddata/interval.html#interval-match
    const cues = this.timelineData.lookup(interval, 1);

    _.forEach(cues, (cue) => {
      targetTime = Math.min(targetTime, cue.interval.low);
    });

    if (targetTime !== Infinity) {
      this.setAnchorDate(targetTime, true);
    } else {
      this.scrollAxis('forward', true);
    }
  }

  increasePlayBackRate() {
    const { playBackRate, isPlaying } = this.state;
    const index = _.findIndex(
      PLAY_BACK_RATES,
      (info) => info[0] === playBackRate[0],
    );
    if (index > -1) {
      const nextIndex = Math.min(PLAY_BACK_RATES.length - 1, index + 1);
      if (this.timingObject && isPlaying) {
        let velocity = PLAY_BACK_RATES[nextIndex][1];
        this.timingObject.update({ velocity });
      }
      this.setState({
        playBackRate: PLAY_BACK_RATES[nextIndex],
      });
    }
  }

  decreasePlayBackRate() {
    const { playBackRate, isPlaying } = this.state;
    const index = _.findIndex(
      PLAY_BACK_RATES,
      (info) => info[0] === playBackRate[0],
    );
    if (index > -1) {
      const nextIndex = Math.max(0, index - 1);
      if (this.timingObject && isPlaying) {
        let velocity = PLAY_BACK_RATES[nextIndex][1];
        this.timingObject.update({ velocity });
      }
      this.setState({
        playBackRate: PLAY_BACK_RATES[nextIndex],
      });
    }
  }

  playTracks(updateShouldBePlaying = true) {
    const stateChanges = { isPlaying: true };
    if (updateShouldBePlaying) {
      stateChanges.shouldBePlaying = true;
    }
    this.setState(stateChanges, () => {
      const { playBackRate } = this.state;
      if (!this.pausedForLoading) {
        this.timingObject.update({ velocity: playBackRate[1] });
      }
    });
  }

  pauseTracks(updateShouldBePlaying = true, callback = () => {}) {
    const stateChanges = { isPlaying: false };
    if (updateShouldBePlaying) {
      stateChanges.shouldBePlaying = false;
    }
    this.setState(stateChanges, () => {
      this.timingObject.update({ velocity: 0 });
      callback();
    });
  }

  togglePlayBack() {
    const { isPlaying } = this.state;
    if (isPlaying) {
      this.pauseTracks();
    } else {
      this.playTracks();
    }
  }

  cycleScale(direction: any) {
    const { axisConfig, currentPlayTime } = this.state;
    const scaleKeys = Object.keys(AXIS_SCALES);
    const index = scaleKeys.findIndex((s) => s === axisConfig.axisScale);
    let nextIndex;
    if (direction === 'forward') {
      nextIndex = Math.min(index + 1, scaleKeys.length - 1);
    }
    if (direction === 'backward') {
      nextIndex = Math.max(index - 1, 0);
    }
    if (axisConfig.axisScale !== scaleKeys[nextIndex]) {
      this.updateAxisConfig({ axisScale: scaleKeys[nextIndex] }, () => {
        this.setAnchorDate(currentPlayTime);
      });
    }
  }

  scrollAxis(direction: any, seekTo = false) {
    const executeScroll = () => {
      const { axisScale, axisTicksCount } = this.state.axisConfig;
      let { axisAnchorDate } = this.state.axisConfig;

      const scaleConfig = AXIS_SCALES[axisScale];
      if (direction === 'forward') {
        axisAnchorDate += scaleConfig.ticks.step * axisTicksCount;
      }
      if (direction === 'backward') {
        axisAnchorDate -= scaleConfig.ticks.step * axisTicksCount;
      }
      this.setAnchorDate(axisAnchorDate, seekTo);
    };

    // if we're asked to scroll while we're playing, we need to first
    // pause, otherwise the playhead will keep moving, and the scroll
    // won't actually execute
    this.pauseTracks(true, executeScroll);
  }

  centerTimeline() {
    let { currentPlayTime } = this.state;
    if (!currentPlayTime) {
      return;
    }
    this.setAnchorDate(currentPlayTime, true);
  }

  setAnchorDate(date: any, seekTo = false) {
    const { axisAnchorDate, axisScale, axisTicksCount } = this.state.axisConfig;
    const currentDate = Math.round(date || axisAnchorDate);

    const scaleConfig = AXIS_SCALES[axisScale];
    const { currentStart, currentEnd } = getAxisStartAndEnd(
      axisTicksCount,
      scaleConfig,
      currentDate,
    );
    const newAxisRange = [currentStart, currentEnd];
    this.updateAxisConfig(
      {
        axisAnchorDate: currentDate,
        axisRange: newAxisRange,
      },
      () => {
        this.updateSearchForm();
      },
    );
    if (seekTo) {
      this.updateSeekTime(currentDate);
    }
  }

  showShareRangeControl(visibility, dateRange) {
    const { timezone } = this.state;
    if (timezone) {
      dateRange[0].tz(timezone, true);
      dateRange[1].tz(timezone, true);
    }
    this.setState({
      shareRangeControlVisibility: visibility,
      targetShareRange: [
        dateRange[0].valueOf() / 1000,
        dateRange[1].valueOf() / 1000,
      ],
    });
  }

  getNewHoverTime(visibleCues, axisLength, axisRange, hoverTime) {
    const axisScale = axisLength / (axisRange[1] - axisRange[0]);
    const MINIMUM_CUE_WIDTH = 10;
    const cuesByTrack = _.groupBy(visibleCues, (cue) => cue.data.track);
    let newHoverTime = hoverTime;
    for (const trackCues of Object.values(cuesByTrack)) {
      for (const cue of trackCues) {
        if (!cue.data.preview) continue;

        const cueLeft =
          _.subtract(cue.interval.low, axisRange[0]) * axisScale || 0;
        const hoverLeft = _.subtract(hoverTime, axisRange[0]) * axisScale || 0;
        const cueWidth =
          _.subtract(cue.interval.high, cue.interval.low) * axisScale;

        if (cueWidth < MINIMUM_CUE_WIDTH) {
          const fakeRight = cueLeft + MINIMUM_CUE_WIDTH;
          // checking if cursor is hovering over cue
          if (cueLeft < hoverLeft && hoverLeft < fakeRight) {
            newHoverTime = cue.interval.low;
            break;
          }
        }
      }
    }
    return newHoverTime;
  }

  setControlsRef(ref) {
    this.controlsRef = ref;
  }

  // ======= Controls End =======

  render() {
    const {
      axisConfig,
      timezone,
      currentPlayTime,
      playBackRate,
      canBeLive,
      isLive,
      isPlaying,
      isMuted,
      isFullscreen,
      activeCues,
      visibleCues,
      targetShareRange,
      shareRangeControlVisibility,
      axisSize,
    } = this.state;
    const {
      loadingHistory,
      showShare,
      shareContext,
      showLive,
      showHideControls,
      layoutSpec,
      channelIdOrder,
      tileLayout,
    } = this.props;

    const displayedCues = [...visibleCues];

    let contentHeight = 'calc(100% - 110px)';
    let containerStyle = {};
    // this will layer the seek bar and controls at the bottom and show them
    // only on hover
    if (showHideControls) {
      containerStyle = { border: 'none' };
      contentHeight = '100%';
    }

    let newLayoutSpec = layoutSpec;
    if (
      layoutSpec &&
      newLayoutSpec.layout?.length &&
      this.state.rerenderLayout
    ) {
      newLayoutSpec = _.cloneDeep(layoutSpec);
      // ReactGridLayout only re-renders if the layout changes (it does a
      // _.isEqual comparison), so if anything significant changes, e.g. size,
      // or child elements, we have to kick it ourselves.

      // adding randomness
      newLayoutSpec.layout[0].blah = Math.random();

      // of course, we should not be constantly re-rendering after done so once
      setTimeout(() => this.setState({ rerenderLayout: false }), 100);
    }
    let gridLayoutWidth = newLayoutSpec?.width;
    if (isFullScreen() && newLayoutSpec) {
      gridLayoutWidth = _.get(
        this.timelineContainerRef.current,
        'clientWidth',
        1280,
      );
      newLayoutSpec.width = gridLayoutWidth;
      newLayoutSpec.rowHeight =
        _.get(this.timelineContainerRef.current, 'clientHeight', 720) /
        newLayoutSpec.maxRows;
    }

    const [renderedCues, renderedCueTypes] = this.renderCues(activeCues);
    const areControlsRequired =
      _.intersection(renderedCueTypes, [
        CUE_TYPE.CLOUD_VIDEO,
        CUE_TYPE.HEATMAP,
        CUE_TYPE.STREAMING,
      ]).length > 0;
    const controlsClassname = showHideControls
      ? areControlsRequired
        ? styles['timeline-show-hover']
        : styles['timeline-hide']
      : '';

    return (
      <div
        id="timeline-ctn"
        ref={this.timelineContainerRef}
        style={containerStyle}
        className={styles['timeline-container']}>
        <div
          style={{ height: contentHeight }}
          className={styles['timeline-content']}>
          {newLayoutSpec ? (
            <div style={{ width: gridLayoutWidth, height: '100%' }}>
              <ReactGridLayout
                allowOverlap={true}
                preventCollision={true}
                isDraggable={false}
                isResizable={false}
                {...newLayoutSpec}>
                {renderedCues}
              </ReactGridLayout>
            </div>
          ) : (
            <CuesLayout layout={tileLayout} cues={renderedCues} />
          )}
          <HoverPreview
            getActiveCueWidthHeight={this.getActiveCueWidthHeight}
            cueRefs={this.cueRefs}
            visibleCues={displayedCues}
            axisLength={axisConfig.axisLength}
            axisRange={axisConfig.axisRange}
            hoverTime={axisConfig.hoverTime}
            isHovering={axisConfig.isHovering}
            left={axisConfig.hoverMouseXposition}
            gridLayoutWidth={gridLayoutWidth}
            newLayoutSpec={newLayoutSpec}
          />
          <div
            className={styles['loading-history']}
            style={loadingHistory ? { maxHeight: '20px' } : undefined}>
            Loading history...
          </div>
        </div>
        <div className={controlsClassname}>
          <div
            className={styles['timeline-seek-container']}
            ref={this.timelineRef}
            onMouseLeave={() => {
              if (shareRangeControlVisibility) {
                return;
              }
              this.updateAxisConfig({ isHovering: false });
            }}
            onMouseMove={this.onTimelineHover}
            onClick={this.onTimelineClick}>
            <SizeMe refreshMode="throttle" refreshRate={500}>
              {({ size }) => {
                if (!_.isEqual(size, axisSize)) {
                  setTimeout(
                    () =>
                      this.setState({ axisSize: size }, () =>
                        this.resizeListener(),
                      ),
                    200,
                  );
                }
                return (
                  <TimelineAxis
                    visibleCues={displayedCues}
                    targetShareRange={targetShareRange}
                    shareRangeControlVisibility={shareRangeControlVisibility}
                    showShareRangeControl={this.showShareRangeControl}
                    axisLength={axisConfig.axisLength}
                    axisRange={axisConfig.axisRange}
                    timezone={timezone}
                    channelIdOrder={channelIdOrder}
                  />
                );
              }}
            </SizeMe>
            <PlayBar
              isLive={this.state.isLive}
              timezone={timezone}
              axisLength={axisConfig.axisLength}
              axisRange={axisConfig.axisRange}
              currentPlayTime={currentPlayTime}
              height={axisConfig.axisHeight}
            />
            <HoverBar
              timezone={timezone}
              axisLength={axisConfig.axisLength}
              height={axisConfig.axisHeight}
              hoverTime={axisConfig.hoverTime}
              left={axisConfig.hoverMouseXposition}
            />
          </div>
          <TimelineControls
            setControlsRef={this.setControlsRef}
            timezone={timezone}
            currentPlayTime={currentPlayTime}
            playBackRate={playBackRate}
            loadingHistory={loadingHistory}
            showLive={showLive && canBeLive}
            showShare={showShare}
            targetShareRange={targetShareRange}
            showShareRangeControl={this.showShareRangeControl}
            shareContext={shareContext}
            axisRange={axisConfig.axisRange}
            axisScales={AXIS_SCALE_KEYS}
            currentScale={axisConfig.axisScale}
            cycleScale={this.cycleScale}
            scrollAxis={this.scrollAxis}
            setAnchorDate={this.setAnchorDate}
            centerTimeline={this.centerTimeline}
            decreasePlayBackRate={this.decreasePlayBackRate}
            increasePlayBackRate={this.increasePlayBackRate}
            enablePlayBackRateChange={
              !(renderedCueTypes.indexOf(CUE_TYPE.STREAMING) > -1)
            }
            isMuted={isMuted}
            isPlaying={isPlaying}
            isFullscreen={isFullscreen}
            canBeLive={canBeLive}
            isLive={isLive}
            resetAnchorDate={this.initTimeline}
            skipTime={this.skipTime}
            skipBackward={this.skipBackward}
            skipForward={this.skipForward}
            toggleFullScreen={this.toggleFullScreen}
            toggleMute={this.toggleMute}
            toggleLive={this.toggleLive}
            togglePlayBack={this.togglePlayBack}
          />
        </div>
      </div>
    );
  }
}

export default Timeline;
