import InfoSidebar, {
  SidebarItem,
  SidebarRow,
} from '@/components/InfoSidebar/InfoSidebarV2';
import Actions from '@/components/InfoSidebar/SidebarComponents/Actions';
import Comments from '@/components/InfoSidebar/SidebarComponents/Comments';
import AddComments from '@/components/InfoSidebar/SidebarComponents/Comments/AddComment';
import {
  Camera,
  Time,
} from '@/components/InfoSidebar/SidebarComponents/ContextDetails';
import {
  Assignee,
  Priority,
} from '@/components/InfoSidebar/SidebarComponents/FormItems';
import PlayerModal from '@/components/PlayerModal';
import TimelinePlayer from '@/components/TimelinePlayer';
import CreateEvent from '@/pages/investigations/components/create-event';
import { entityHasLicenseOfType } from '@/utils/licenses';
import { dispatchWithFeedback, getChannelInfo } from '@/utils/utils';
import { Form, Input, Popconfirm, Select } from 'antd';
import _, { debounce } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { useDispatch, useSelector } from 'umi';
import { ARCHIVED_INCIDENT_PRIORITY } from '../../AbstractIncidentsBase/constants';
import { useAlertSidebar } from '../../AbstractIncidentsBase/useAlertSidebar';
import POSTransactionReceipt from '../../components/pos-transaction-receipt';
import { Incident, Transaction } from '../types';
import { InnerPlayerDiv, OuterPlayerDiv } from './sidebar.styles';
import {
  generateLossTypeDropdownOptions,
  generateTransactionUpdatePayload,
  getTransactionReceiptProps,
} from './utils';

type SidebarProps = {
  transaction: Transaction;
  onClose: () => void;
};

const useTransactionSidebarElements = (transaction: Transaction) => {
  const { loc, ch_grp, ch } = useSelector((state: any) => state.locations);
  //const currentUser = useSelector((state: any) => state.user.currentUser);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const channelInfo = getChannelInfo(transaction.channel_id, {
    loc,
    ch_grp,
    ch,
  });

  const cameraProps = {
    location: channelInfo.location_obj && {
      id: channelInfo.location_obj.ID,
      name: channelInfo.location_obj.Name,
    },
    channelGroup: channelInfo.channel_group_obj && {
      id: channelInfo.channel_group_obj.ID,
      name: channelInfo.channel_group_obj.Name,
    },
    channel: channelInfo.channel_obj && {
      id: channelInfo.channel_obj.ID,
      name: channelInfo.channel_obj.Name,
    },
  };

  const timeProps = {
    from: moment.unix(transaction.start_time).tz(transaction.site_timezone),
    to: moment.unix(transaction.end_time).tz(transaction.site_timezone),
    timezone: channelInfo.timezone,
  };

  //const comments = Form.useWatch('comments', form);

  const updateTransaction = () => {
    dispatchWithFeedback(
      dispatch,
      'Updating transaction',
      {
        type: 'checkout_insights/updateTransaction',
        payload: generateTransactionUpdatePayload(
          transaction,
          form.getFieldsValue(),
        ),
      },
      true,
    );
  };

  const handleFieldChange = debounce(() => {
    updateTransaction();
  }, 500);

  // const onAddComment = (text: string) => {
  //   const newComment = {
  //     id: (comments?.index || 0) + 1,
  //     text: text,
  //     timestamp: moment.now(),
  //     user: {
  //       name: `${currentUser.FirstName} ${currentUser.LastName}`,
  //       id: currentUser.UserID,
  //     },
  //   };
  //   const currentComments = form.getFieldValue('comments') || [];
  //   form.setFieldsValue({ comments: [...currentComments, newComment] });
  //   updateTransaction();
  // };

  // const onDeleteComment = (index: number) => {
  //   const currentComments = form.getFieldValue('comments') || [];
  //   const updatedComments = currentComments.filter(
  //     (__: any, i: number) => i !== index,
  //   );
  //   form.setFieldsValue({ comments: updatedComments });
  //   updateTransaction();
  // };

  const formHtml = (
    <Form
      form={form}
      onValuesChange={handleFieldChange}
      initialValues={transaction}>
      <SidebarItem title="Loss Type">
        <Form.Item name="loss_type" noStyle>
          <Select placeholder="Select Loss Type">
            {generateLossTypeDropdownOptions().map(({ value, text }) => (
              <Select.Option key={value} value={value}>
                {text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </SidebarItem>
      <SidebarItem title="Loss Amount">
        <Form.Item name="loss_amount" noStyle>
          <Input
            prefix="$"
            type="number"
            placeholder="Enter loss amount"
            min={0}
            step="0.01"
          />
        </Form.Item>
      </SidebarItem>
      <SidebarItem title="Summary">
        <Form.Item name="summary" noStyle>
          <Input.TextArea rows={4} placeholder="Enter summary here" />
        </Form.Item>
      </SidebarItem>
    </Form>
  );

  return {
    form: formHtml,
    cameraProps,
    timeProps,
  };
};

const TransactionSidebar: React.FC<SidebarProps> = ({
  transaction,
  onClose,
}) => {
  const dispatch = useDispatch();
  const accounts = useSelector((state: any) => state.accounts);
  const { form, timeProps, cameraProps } =
    useTransactionSidebarElements(transaction);

  //Only applicable to incidents
  let onPriorityChange,
    onAssignmentChange,
    onAddNote,
    onDeleteNote,
    onArchive: () => void | undefined,
    onUnarchive: () => void | undefined,
    interpretedClip;
  const onAfterClipUpdate = (newClip: Incident) => {
    if (newClip.isDeleted) {
      dispatch({
        type: 'checkout_insights/archiveIncident',
        payload: {
          id: newClip.id,
        },
      });
      onClose();
    } else {
      dispatch({
        type: `checkout_insights/refreshIncidents`,
        payload: {
          ids: [newClip.id],
        },
      });
    }
  };
  const clip = transaction.incident;
  if (clip) {
    ({
      interpretedClip,
      onPriorityChange,
      onAssignmentChange,
      onArchive,
      onUnarchive,
      onAddNote,
      onDeleteNote,
    } =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useAlertSidebar({
        clip,
        onAfterClipUpdate,
      }));
  }

  const is_archived =
    clip && (clip.isDeleted || clip.priority === ARCHIVED_INCIDENT_PRIORITY);

  let actions = [
    {
      name: 'View in Context',
      type: 'component',
      component: PlayerModal,
      componentProps: {
        autoPlay: true,
        restrictHistoryToStartEnd: true,
        startTime: transaction.start_time,
        endTime: transaction.end_time,
        channelIDs: [transaction.channel_id],
        events: interpretedClip?.events,
      },
    },
    {
      name: 'Add To Investigation',
      type: 'component',
      component: CreateEvent,
      componentProps: {
        channelID: transaction.channel_id,
        startTime: moment
          .unix(transaction.start_time)
          .tz(transaction.site_timezone),
        endTime: moment
          .unix(transaction.end_time)
          .tz(transaction.site_timezone),
        disabled: !entityHasLicenseOfType(
          accounts,
          'DIS',
          +_.get(transaction, 'ChannelID', 0),
          +_.get(transaction, 'locationID', 0),
        ),
        style: { display: 'none' },
      },
    },
  ] as any;

  if (clip) {
    actions.push({
      name: is_archived ? 'Unarchive' : 'Archive',
      type: 'component',
      component: ({ children }: { children: React.ReactNode }) => {
        return (
          <Popconfirm
            title="Are you sure you want to delete this incident?"
            onConfirm={is_archived ? onUnarchive : onArchive}
            okText="Yes"
            cancelText="No">
            {children}
          </Popconfirm>
        );
      },
    });
  }

  return (
    <InfoSidebar
      onClose={onClose}
      content={
        <>
          <SidebarItem>
            <Actions actions={actions} />
          </SidebarItem>
          <SidebarItem title="Camera">
            <Camera {...cameraProps} />
          </SidebarItem>
          <SidebarItem title="Time">
            <Time {...timeProps} />
          </SidebarItem>
          {clip && !is_archived && (
            <SidebarRow>
              <SidebarItem title="Priority" width="20%">
                <Priority
                  priority={clip.priority}
                  onChange={onPriorityChange}
                />
              </SidebarItem>
              <SidebarItem title="Assignee" width="75%">
                <Assignee
                  assignee={clip.statusOwnerID}
                  onChange={onAssignmentChange}
                />
              </SidebarItem>
            </SidebarRow>
          )}
          {form}
          {clip && (
            <SidebarItem>
              <Comments
                comments={clip}
                onDelete={onDeleteNote}
                filterOnAction={['note']}
              />
            </SidebarItem>
          )}
          <SidebarItem>
            <OuterPlayerDiv>
              <InnerPlayerDiv>
                <TimelinePlayer
                  showHideControls={true}
                  startTime={transaction.start_time}
                  endTime={transaction.end_time}
                  channelIDs={[transaction.channel_id]}
                  fitEventsOnAxis={true}
                  restrictHistoryToStartEnd={true}
                />
              </InnerPlayerDiv>
            </OuterPlayerDiv>
          </SidebarItem>
          <SidebarItem>
            <POSTransactionReceipt
              transaction={getTransactionReceiptProps(transaction)}
            />
          </SidebarItem>
        </>
      }
      title="Details"
      footer={clip && <AddComments onChange={onAddNote} />}
    />
  );
};

export default TransactionSidebar;
