import { selectFilterFunction } from '@/utils/utils';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Divider, Input, Select, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useRef, useState } from 'react';
import { ComboboxProps, ValueType } from './types';

const Combobox = ({
  options,
  value,
  onChange,
  ...restProps
}: ComboboxProps) => {
  const [items, setItems] = useState<DefaultOptionType[]>(options || []);
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<InputRef>(null);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleChange = (val: ValueType) => {
    if (typeof val === 'string' && val.startsWith('new-') && onChange) {
      onChange(val.slice(4), true);
    } else if (onChange) {
      onChange(val, false);
    }
  };

  const addItem = () => {
    if (
      inputValue &&
      !items.some((item) => String(item.value) === inputValue)
    ) {
      setItems((prev) => [
        ...prev,
        { label: inputValue, value: 'new-' + inputValue },
      ]);
      setInputValue('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  useEffect(() => {
    setItems(options || []);
  }, [options]);

  return (
    <Select
      style={{ width: '100%' }}
      value={value}
      onChange={handleChange}
      showSearch
      filterOption={selectFilterFunction}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Add new type"
              ref={inputRef}
              value={inputValue}
              onChange={onInputChange}
            />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={addItem}
              disabled={
                !inputValue ||
                items.some((item) => String(item.value) === inputValue)
              }>
              Add
            </Button>
          </Space>
        </>
      )}
      options={items}
      {...restProps}
    />
  );
};

export default Combobox;
