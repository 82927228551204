import LoadingSpinner from '@/components/LoadingSpinner';
import { STREAM_QUALITY } from '@/components/StreamQualitySelector/constants';
import { StreamQuality } from '@/components/StreamQualitySelector/types';
import { TileLayout } from '@/components/TileLayoutSelector/constants';
import { TileLayoutType } from '@/components/TileLayoutSelector/types';
import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { convertStringToEnum } from '@/utils/utils';
import { useSelector } from '@umijs/max';
import { Button, Form, Input, Modal, theme, Tooltip, Typography } from 'antd';
import _ from 'lodash';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useFragment } from 'react-relay';
import { useMonitorContext } from '../../MonitorContext';
import {
  useSceneAddActions,
  useSceneUpdateActions,
} from '../../MonitorMutations';
import type { MonitorMutations_SceneUpdate_Mutation$data } from '../../__generated__/MonitorMutations_SceneUpdate_Mutation.graphql';
import type { MonitorMutations_UserSceneConnectionAdd_Mutation$data } from '../../__generated__/MonitorMutations_UserSceneConnectionAdd_Mutation.graphql';
import { SceneFragment } from '../Scene';
import { getSceneName } from '../Scene/utils';
import type {
  SceneFragment$data,
  SceneFragment$key,
} from '../Scene/__generated__/SceneFragment.graphql';
import { ChannelsSelection } from './components/ChannelsSelection';
import type { ChannelTileAndName_Channel$key } from './components/ChannelsSelection/ChannelsList/__generated__/ChannelTileAndName_Channel.graphql';
import { SceneTimeline } from './components/SceneTimeline';
import SearchForm from './components/SearchForm';
import SearchResults from './components/SearchResults';
import { RowCountMap } from './constants';
import { useResultCountCalculation } from './hooks';
import {
  SceneViewerContainer,
  SceneViewerContent,
  StyledSider,
} from './styles';
import type { RowCountType } from './types';

export interface SceneViewerProps {
  selectedSceneRef?: SceneFragment$key;
  isCameraListHidden?: boolean;
  isSearchMode?: boolean;
  passedChannelIds?: number[];
  openSaveSceneModal?: boolean;
  onSceneSave?: (
    scene:
      | MonitorMutations_UserSceneConnectionAdd_Mutation$data
      | MonitorMutations_SceneUpdate_Mutation$data,
  ) => void;
  onModalClose?: () => void;
}

const SceneViewerComponent = ({
  selectedSceneRef,
  isCameraListHidden,
  passedChannelIds,
  openSaveSceneModal,
  onSceneSave,
  onModalClose,
  isSearchMode,
}: SceneViewerProps) => {
  const timelinePlayerRef = useRef();
  const { appId, customerId } = useMonitorContext();
  const { token } = theme.useToken();

  const { isMobile } = useDfMediaQuery();
  const selectedSceneData = useFragment(SceneFragment, selectedSceneRef);

  const areSearchResultsPresent = useSelector(
    (state) =>
      //@ts-expect-error
      state.monitor_search.searchResults.clips !== null,
  );

  const sceneViewerContentRef = useRef<HTMLDivElement>(null);
  const sceneTimelineRef = useRef<HTMLDivElement>(null);

  const calculateResultCount = useResultCountCalculation(
    sceneViewerContentRef,
    sceneTimelineRef,
  );

  const [rowSelectorValue, setRowSelectorValue] = useState<RowCountType>(
    RowCountMap.FIT,
  );

  const [selectedChannelIDs, setSelectedChannelIDs] = useState<number[]>([]);
  const [selectedChannelTileRefs, setSelectedChannelTileRefs] = useState<
    ChannelTileAndName_Channel$key[]
  >([]);
  const [streamQuality, setStreamQuality] = useState<StreamQuality>(
    STREAM_QUALITY.DEFAULT,
  );
  const [timelineTileLayout, setTimelineTileLayout] = useState<TileLayoutType>(
    TileLayout.FIT,
  );

  const defaultTimelinePlayerProps = {
    autoPlay: true,
    showShare: true,
    showLive: true,
    showLink: false,
    channelIDs: selectedChannelIDs,
    channelIdOrder: selectedChannelIDs,
  };

  const [timelinePlayerProps, setTimelinePlayerProps] = useState<any>(
    defaultTimelinePlayerProps,
  );

  useEffect(() => {
    if (selectedSceneData) {
      const channelIDs = selectedSceneData.channels.map((ch) => ch.ChannelID);
      setSelectedChannelIDs(channelIDs);
      setSelectedChannelTileRefs(
        selectedSceneData.channels.map(
          (channel) => channel as ChannelTileAndName_Channel$key,
        ),
      );
      setTimelinePlayerProps({
        ...defaultTimelinePlayerProps,
        channelIDs,
        channelIdOrder: channelIDs,
      });
      setStreamQuality(
        convertStringToEnum(STREAM_QUALITY, selectedSceneData?.resolution) ||
          STREAM_QUALITY.DEFAULT,
      );
      setTimelineTileLayout(
        isMobile
          ? TileLayout.FIT
          : convertStringToEnum(TileLayout, selectedSceneData?.layout) ||
              TileLayout.FIT,
      );
    } else {
      setSelectedChannelIDs(passedChannelIds ?? []);
      setStreamQuality(STREAM_QUALITY.DEFAULT);
      setTimelineTileLayout(TileLayout.FIT);
      setSelectedChannelTileRefs([]);
      setTimelinePlayerProps({
        ...defaultTimelinePlayerProps,
        channelIDs: passedChannelIds,
        channelIdOrder: passedChannelIds,
      });
    }
  }, [selectedSceneData, passedChannelIds]);

  const viewingExistingScene = !!selectedSceneData;

  const [form] = Form.useForm();

  const { updateSceneNameAndChannels, sceneUpdateMutationPending } =
    useSceneUpdateActions({
      scene: selectedSceneData as SceneFragment$data,
      appId,
    });

  const { addUserScene, addUserSceneMutationPending } = useSceneAddActions({
    appId,
    customerId,
  });

  const resetTimelinePlayerProps = () => {
    setTimelinePlayerProps(defaultTimelinePlayerProps);
  };

  const newChannelsSelected =
    (selectedSceneData &&
      !_.isEqual(
        selectedSceneData.channels.map((ch) => ch.ChannelID),
        selectedChannelIDs,
      )) ||
    (passedChannelIds && !_.isEqual(passedChannelIds, selectedChannelIDs));

  const isSceneViewingMode =
    !!selectedChannelIDs.length && !newChannelsSelected;

  const getAnchorTimestamp = () => {
    // @ts-expect-error
    let timestamp = timelinePlayerRef.current?.state.currentPlayTime;
    if (timestamp) {
      // db doesn't like floats...
      timestamp = Math.floor(timestamp) + '';
    }

    return timestamp;
  };

  const handleSceneCreateUpdateViaModal = (updatedSceneName: string) => {
    if (viewingExistingScene) {
      updateSceneNameAndChannels({
        name: updatedSceneName,
        channelIDs: selectedChannelIDs.map(String),
        anchorTimeStamp: getAnchorTimestamp(),
        onSceneUpdated: (response) => {
          onSceneSave?.(response);
        },
        layout: timelineTileLayout.toString(),
        resolution: streamQuality.toString(),
      });
    } else {
      addUserScene({
        channelIds: selectedChannelIDs.map(String),
        name: updatedSceneName,
        anchorTimeStamp: getAnchorTimestamp(),
        onSceneAdded: (response) => {
          onSceneSave?.(response);
        },
        layout: timelineTileLayout.toString(),
        resolution: streamQuality.toString(),
      });
    }
  };

  const saveSceneModalContent = (
    <Modal
      open={openSaveSceneModal}
      onCancel={() => onModalClose?.()}
      title={viewingExistingScene ? 'Update Scene' : 'Create Scene'}
      footer={null}
      centered>
      <Form
        form={form}
        onFinish={(values) => {
          handleSceneCreateUpdateViaModal(values.sceneName);
        }}
        initialValues={{
          sceneName: viewingExistingScene
            ? getSceneName(selectedSceneData)
            : 'New Scene',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Typography.Text>
            {viewingExistingScene
              ? 'Update this scene under the ‘My Scenes’ tab.'
              : 'Save this scene for quick viewing later under the ‘My Scenes’ tab.'}
          </Typography.Text>
          <Form.Item name="sceneName" style={{ marginBottom: 0 }}>
            <Input />
          </Form.Item>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Button onClick={() => onModalClose?.()} style={{ width: '100%' }}>
              Discard Changes
            </Button>
            <Tooltip
              title={
                selectedChannelIDs.length === 0 ? 'No cameras selected' : ''
              }
              color={token.colorError}>
              <Button
                disabled={selectedChannelIDs.length === 0}
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
                loading={
                  addUserSceneMutationPending || sceneUpdateMutationPending
                }>
                {viewingExistingScene ? 'Save Scene' : 'Create Scene'}
              </Button>
            </Tooltip>
          </div>
        </div>
      </Form>
    </Modal>
  );

  return (
    <SceneViewerContainer>
      <SceneViewerContent ref={sceneViewerContentRef}>
        <SceneTimeline
          ref={sceneTimelineRef}
          timelinePlayerRef={timelinePlayerRef}
          timelinePlayerProps={timelinePlayerProps}
          selectedChannelIDs={selectedChannelIDs}
          selectedSceneData={selectedSceneData}
          fitTimeline={
            !areSearchResultsPresent || rowSelectorValue === RowCountMap.FIT
          }
          streamQuality={streamQuality}
          setStreamQuality={setStreamQuality}
          timelineTileLayout={timelineTileLayout}
          setTimelineTileLayout={setTimelineTileLayout}
        />
        {isSearchMode && areSearchResultsPresent && (
          <SearchResults
            setTimelinePlayerProps={setTimelinePlayerProps}
            selectedChannelIDs={selectedChannelIDs}
            setRowSelectorValue={setRowSelectorValue}
            calculateResultCount={calculateResultCount}
            rowSelectorValue={rowSelectorValue}
          />
        )}
      </SceneViewerContent>
      <StyledSider
        trigger={null}
        breakpoint="md"
        collapsedWidth="0"
        collapsible={true}
        collapsed={isCameraListHidden}
        width={isMobile ? 'calc(100vw - 15px)' : 282}>
        {isSearchMode ? (
          <SearchForm
            selectedChannelIDs={selectedChannelIDs}
            calculateResultCount={calculateResultCount}
            rowSelectorValue={rowSelectorValue}
            resetTimelinePlayerProps={resetTimelinePlayerProps}
          />
        ) : (
          <ChannelsSelection
            selectedChannelIDs={selectedChannelIDs}
            passedChannelIds={passedChannelIds}
            isSceneViewingMode={isSceneViewingMode}
            setSelectedChannelIDs={setSelectedChannelIDs}
            setTimelinePlayerProps={setTimelinePlayerProps}
            selectedChannelTileRefs={selectedChannelTileRefs}
            setSelectedChannelTileRefs={setSelectedChannelTileRefs}
          />
        )}
      </StyledSider>
      {saveSceneModalContent}
    </SceneViewerContainer>
  );
};

const SceneViewer = (props: SceneViewerProps) => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <SceneViewerComponent {...props} />
    </Suspense>
  );
};

export default SceneViewer;
