import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Empty } from 'antd';
import React, { Suspense, useMemo, useState } from 'react';

import DataList from '@/components/DataList';
import LoadingSpinner from '@/components/LoadingSpinner';
import { booleanTableFilterFunction } from '@/utils/utils';
import type { ColumnType } from 'antd/es/table';
import { graphql, useFragment } from 'react-relay';
import CreateSiteModal from '../CreateUpdateSite/CreateSiteModal';
import UpdateSiteModal from '../CreateUpdateSite/UpdateSiteModal';
import { useFleetManagerContext } from '../FleetManagerContext';
import styles from './style.less';
import type { SiteRecord, SitesProps } from './types';

const ITEMS_PER_PAGE = 10;

const SitesFragment = graphql`
  fragment Sites_sites on SiteConnection {
    __id
    edges {
      node {
        ...sites_siteSummary @relay(mask: false)
      }
    }
  }
`;

const LabelsFragment = graphql`
  fragment Sites_labels on LabelConnection {
    ...CreateUpdateSite_labels
  }
`;

const Sites: React.FC<SitesProps> = ({
  siteConnectionRef,
  labelConnectionRef,
  refreshData,
}) => {
  const { selectedSiteFilter, setSelectedSiteFilter } =
    useFleetManagerContext();

  // required for local filtering - we set activeFilters when the local dropdown is used and invalidate the selectedSiteFilter
  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});

  const handleTableChange = (
    _pagination: any,
    filters: Record<string, any>,
    _sorter: any,
  ) => {
    setActiveFilters(filters);
    setSelectedSiteFilter(undefined);
  };

  const sitesData = useFragment(SitesFragment, siteConnectionRef);
  const labelsData = useFragment(LabelsFragment, labelConnectionRef);

  const sites = useMemo(
    () => sitesData?.edges.map((edge) => edge?.node) ?? [],
    [sitesData],
  );

  const selectedSiteName = useMemo(() => {
    if (!selectedSiteFilter) return null;
    const site = sites.find(
      (siteObject) => siteObject?.SiteID === selectedSiteFilter,
    );
    return site?.Name || null;
  }, [selectedSiteFilter, sites]);

  const labelOptions = useMemo(() => {
    const names = new Set<string>();
    sites.forEach((site) => {
      site?.Labels?.forEach((label) => names.add(label?.Name as string));
    });
    return Array.from(names)
      .sort()
      .map((name) => ({ text: name, value: name }));
  }, [sites]);
  const booleanFilterOptions = [
    { text: 'Set', value: true },
    { text: 'Unset', value: false },
  ];

  const columns = [
    {
      title: 'Site',
      dataIndex: 'Name',
      key: 'Name',
      enableLocalFiltering: true,
      enableLocalSorting: true,
      filteredValue: selectedSiteName
        ? [selectedSiteName]
        : activeFilters.Name ?? [],
      filterMultiple: true,
      onFilter: (value: string | string[], record: any) => {
        const filterValues = Array.isArray(value) ? value : [value];
        return filterValues.includes(record.Name);
      },
    },
    {
      title: 'Site ID',
      dataIndex: 'ExternalID',
      key: 'ExternalID',
      render: (id: string) => id || '-',
    },
    {
      title: 'Groups',
      key: 'Groups',
      render: (_: unknown, record: SiteRecord) => {
        if (!record?.Labels?.length) return '-';
        return (
          <span>
            {record.Labels.slice(0, 3).map((label, index) => (
              <React.Fragment key={label?.LabelID}>
                <span style={{ color: label?.Color || '#000000' }}>
                  {label?.Name}
                </span>
                {index < 2 &&
                  index !== (record?.Labels?.length || 0) - 1 &&
                  ', '}
              </React.Fragment>
            ))}
            {record.Labels.length > 3 && (
              <span>
                {' and '}
                {record.Labels.length - 3}
                {' more'}
              </span>
            )}
          </span>
        );
      },
      onFilter: (value: string, record: SiteRecord) => {
        const labelNames = record?.Labels?.map((label) => label?.Name);
        if (!labelNames) return false;
        return labelNames.some((name) =>
          name?.toLowerCase().includes(value.toLowerCase()),
        );
      },
      filterMultiple: true,
      filters: labelOptions,
    },
    {
      title: 'City',
      dataIndex: ['AddressInfo', 'City'],
      key: 'City',
      render: (city: string) => city || '-',
      enableLocalFiltering: true,
    },
    {
      title: 'State',
      dataIndex: ['AddressInfo', 'State'],
      key: 'State',
      render: (state: string) => state || '-',
      enableLocalFiltering: true,
    },
    {
      title: 'Country',
      dataIndex: ['AddressInfo', 'Country'],
      key: 'Country',
      render: (country: string) => country || '-',
      enableLocalFiltering: true,
    },
    {
      title: 'Ship Address',
      key: 'ShipAddress',
      dataIndex: ['HasShippingAddress'],
      render: (hasShippingAddress: boolean) =>
        hasShippingAddress ? (
          <CheckCircleOutlined className={styles['icon-check']} />
        ) : (
          <ExclamationCircleOutlined className={styles['icon-warning']} />
        ),
      enableLocalFiltering: true,
      filters: booleanFilterOptions,
      onFilter: booleanTableFilterFunction('HasShippingAddress'),
    },
    {
      title: 'Contacts',
      key: 'Contacts',
      dataIndex: ['HasContacts'],
      render: (hasContacts: boolean) =>
        hasContacts ? (
          <CheckCircleOutlined className={styles['icon-check']} />
        ) : (
          <ExclamationCircleOutlined className={styles['icon-warning']} />
        ),
      enableLocalFiltering: true,
      filters: booleanFilterOptions,
      onFilter: booleanTableFilterFunction('HasContacts'),
    },
    {
      title: 'Escalation',
      key: 'Escalation',
      dataIndex: ['HasEscalationPath'],
      render: (hasEscalationPath: boolean) =>
        hasEscalationPath ? (
          <CheckCircleOutlined className={styles['icon-check']} />
        ) : (
          <ExclamationCircleOutlined className={styles['icon-warning']} />
        ),
      enableLocalFiltering: true,
      filters: booleanFilterOptions,
      onFilter: booleanTableFilterFunction('HasEscalationPath'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, record: SiteRecord) => {
        if (!record) return '-';
        return (
          <div>
            <UpdateSiteModal
              siteId={record.id}
              siteConnectionId={sitesData?.__id}
              labelConnectionRef={labelsData}>
              <span className="df-link">Details</span>
            </UpdateSiteModal>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className={styles['header']}>
        <h3 style={{ margin: '0px' }}>Sites</h3>
        <div className={styles['actions']}>
          <CreateSiteModal
            siteConnectionId={sitesData?.__id}
            labelConnectionRef={labelsData}
            refreshData={refreshData}>
            <Button type="primary">Add Sites</Button>
          </CreateSiteModal>
        </div>
      </div>
      <DataList
        dataList={sites}
        size="small"
        columns={columns as ColumnType<SiteRecord>[]}
        bordered
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data found"
            />
          ),
        }}
        pagination={{
          p_size: ITEMS_PER_PAGE,
          position: ['bottomLeft'],
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

const SitesWrapper: React.FC<SitesProps> = (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <Sites {...props} />
  </Suspense>
);

export default SitesWrapper;
