import { DfFlex } from '@/components/DfFlex';
import { useLoginLayoutContext } from '@/layouts/LoginLayout/LoginLayoutContext';
import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { MenuOutlined } from '@ant-design/icons';
import { Outlet } from '@umijs/max';
import { Button, Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { LaunchPadContainer, LaunchPadNavLink } from './styles';

const LaunchPad = () => {
  const { setNavBarControls } = useLoginLayoutContext();
  const { isMobile } = useDfMediaQuery();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const theme = useTheme();

  const handleMobileMenuButtonClick = () => {
    setIsMobileMenuOpen(true);
  };

  const handleDrawerClose = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const controls = isMobile ? (
      <>
        <Button
          type="text"
          icon={<MenuOutlined style={{ color: 'white', fontSize: '20px' }} />}
          onClick={handleMobileMenuButtonClick}
        />
        <Drawer
          open={isMobileMenuOpen}
          onClose={handleDrawerClose}
          style={{ backgroundColor: theme.token?.colorPrimary }}>
          <DfFlex gap={12} direction="column">
            <LaunchPadNavLink href="https://www.dragonfruit.ai/company">
              About Us
            </LaunchPadNavLink>
            <LaunchPadNavLink color="white" href="/launchpad/faq">
              FAQ
            </LaunchPadNavLink>
            <LaunchPadNavLink href="mailto:launchpad@dragonfruit.ai">
              Contact
            </LaunchPadNavLink>
            <LaunchPadNavLink href="/apps">Login</LaunchPadNavLink>
          </DfFlex>
        </Drawer>
      </>
    ) : (
      <DfFlex gap={12} direction="row">
        <LaunchPadNavLink href="https://www.dragonfruit.ai/company">
          About Us
        </LaunchPadNavLink>
        <LaunchPadNavLink href="/launchpad/faq">FAQ</LaunchPadNavLink>
        <LaunchPadNavLink href="mailto:launchpad@dragonfruit.ai">
          Contact
        </LaunchPadNavLink>
        <LaunchPadNavLink href="/apps">Login</LaunchPadNavLink>
      </DfFlex>
    );
    setNavBarControls(controls);
  }, [
    isMobile,
    isMobileMenuOpen,
    setNavBarControls,
    theme.token?.colorPrimary,
  ]);

  return (
    <LaunchPadContainer>
      <Outlet />
    </LaunchPadContainer>
  );
};

export default LaunchPad;
