import { RTSPURL } from '@/utils/onvif';
import Papa from 'papaparse';

export const getRTSPUrlsFromForm = (values) => {
  return new Promise((resolve, reject) => {
    if (values.sourceType === 'rtsp') {
      resolve([[values.rtspUrl]]);
    }
    const file = values.csvFile[0].originFileObj;
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (results) => {
        resolve(results.data);
      },
      error: (error) => {
        reject('Error parsing CSV:', error);
      },
    });
  });
};

export const validateRTSPUrl = (_: any, value: string) => {
  if (!value) {
    return Promise.resolve();
  }

  const isValid = RTSPURL.validateRTSP_URL(value.trim());

  return isValid
    ? Promise.resolve()
    : Promise.reject('Invalid RTSP URL format');
};

export const validateCSV = async (_: any, fileList: any[]) => {
  if (!fileList || fileList.length === 0) {
    return Promise.resolve(); // Let the required rule handle this case
  }
  try {
    // Ensure the structure matches what getRTSPUrlsFromForm expects
    const values = { csvFile: [{ originFileObj: fileList[0].originFileObj }] };

    const extractedUrls = await getRTSPUrlsFromForm(values);

    // Flatten the extracted data by taking only the first column of each row
    const urlsToValidate = extractedUrls.map((row) => row[0]);
    if (urlsToValidate.length === 0) {
      return Promise.reject('Atleast one RTSP URL required');
    }
    const validatedUrls = await Promise.allSettled(
      urlsToValidate.map((url) => validateRTSPUrl(null, url)),
    );

    // Find invalid URLs
    const invalidUrls = validatedUrls
      .map((result, index) =>
        result.status === 'rejected' ? urlsToValidate[index] : null,
      )
      .filter(Boolean); // Remove null values

    if (invalidUrls.length > 0) {
      return Promise.reject(
        `File contains invalid RTSP URLs: ${invalidUrls.join(', ')}`,
      );
    }

    return Promise.resolve(); // Validation successful
  } catch (error) {
    console.error('Error processing the CSV:', error);
    return Promise.reject('Error processing the CSV file');
  }
};
