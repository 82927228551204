/**
 * @generated SignedSource<<39821aded7d75747f7fab5034a4be33d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SceneFragment$data = {
  readonly anchorTimeStamp: string | null | undefined;
  readonly channels: ReadonlyArray<{
    readonly ChannelID: number;
    readonly Name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ChannelTileAndName_Channel" | "TileThumbnailChannelsMedia">;
  }>;
  readonly createdBy: number | null | undefined;
  readonly id: string;
  readonly isFavorite: boolean | null | undefined;
  readonly isSharedWithTeam: boolean | null | undefined;
  readonly layout: string | null | undefined;
  readonly name: string | null | undefined;
  readonly resolution: string | null | undefined;
  readonly timestamp: string;
};
export type SceneFragment$key = {
  readonly " $data"?: SceneFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SceneFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "SceneFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFavorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "anchorTimeStamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSharedWithTeam",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "layout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resolution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Channel",
      "kind": "LinkedField",
      "name": "channels",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ChannelID",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "Name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChannelTileAndName_Channel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TileThumbnailChannelsMedia"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MonitorScene",
  "abstractKey": null
};

(node as any).hash = "ce721ebae5acc2cfbbc1d83676c45fbf";

export default node;
