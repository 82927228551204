import request from '@/utils/request';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function signupForLaunchPadPlatform(payload) {
  return request(`${DF_SERVICES_API_ENDPOINT}/platform_signup`, {
    method: 'POST',
    data: payload,
  });
}
