import { Result, Typography } from 'antd';
import { PaymentSuccessContainer } from './styles';

const { Title, Paragraph } = Typography;

const PaymentSuccessPage = () => {
  return (
    <PaymentSuccessContainer>
      <Result
        status="success"
        title={
          <Title level={3} style={{ marginTop: '24px' }}>
            All Set!
          </Title>
        }
        subTitle={
          <Paragraph style={{ marginTop: '8px' }}>
            We will verify your feeds (thanks, Legal!) and start calling your
            API endpoints shortly. Your card will only be charged once the API
            calls start flowing.
          </Paragraph>
        }
      />
    </PaymentSuccessContainer>
  );
};

export default PaymentSuccessPage;
