import { StreamQualitySelector } from '@/components/StreamQualitySelector';
import { StreamQuality } from '@/components/StreamQualitySelector/types';
import { TileLayoutSelector } from '@/components/TileLayoutSelector';
import { TileLayoutType } from '@/components/TileLayoutSelector/types';
import TimelinePlayer from '@/components/TimelinePlayer';
import { DFConfigKeys } from '@/dfConfigKeys';
import { useCustomerProfileValue } from '@/utils/hooks';
import { Flex } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMonitorContext } from '../../../../MonitorContext';
import { useSceneUpdateActions } from '../../../../MonitorMutations';
import { getSceneName } from '../../../Scene/utils';
import type { SceneFragment$data } from '../../../Scene/__generated__/SceneFragment.graphql';
import { EditableName } from './EditableName';
import { NewSceneTimelinePlaceholder } from './NewSceneTimelinePlaceholder';
import {
  TimelineLayoutSelectorContainer,
  TimelinePlayerContainer,
  TimelinePlayerTileSettingsContainer,
} from './styles';

interface SceneTimelineProps {
  timelinePlayerRef: React.MutableRefObject<undefined>;
  timelinePlayerProps?: any;
  selectedSceneData: SceneFragment$data | null | undefined;
  selectedChannelIDs: number[];
  fitTimeline: boolean; // True if timeline needs to be fit to prevent scrolling
  streamQuality: StreamQuality;
  setStreamQuality: React.Dispatch<React.SetStateAction<StreamQuality>>;
  timelineTileLayout: TileLayoutType;
  setTimelineTileLayout: React.Dispatch<React.SetStateAction<TileLayoutType>>;
}

const SceneTimeline = React.forwardRef<HTMLDivElement, SceneTimelineProps>(
  (
    {
      timelinePlayerRef,
      selectedSceneData,
      selectedChannelIDs,
      timelinePlayerProps,
      fitTimeline,
      streamQuality,
      setStreamQuality,
      timelineTileLayout,
      setTimelineTileLayout,
    },
    ref,
  ) => {
    const { appId, currentUserId } = useMonitorContext();

    const { updateSceneAttribute } = useSceneUpdateActions({
      scene: selectedSceneData as SceneFragment$data,
      appId,
    });

    const sceneName = useMemo(
      () => (selectedSceneData ? getSceneName(selectedSceneData) : ''),
      [selectedSceneData],
    );

    const chIDs = selectedChannelIDs;

    const handleSceneNameChange = (name: string) => {
      updateSceneAttribute({ name });
    };

    //We'll assign a function to a ref and run that on unmounting
    //This is to ensure that we get the latest values for all variables
    //while not adding them as dependants to the effect (since we want the handler to only run once before unmouting)
    const unmountHandlerRef = useRef(() => {});
    unmountHandlerRef.current = useCallback(() => {
      //Persist selections against the scene before unmounting
      if (
        selectedSceneData &&
        (selectedSceneData.resolution !== streamQuality ||
          selectedSceneData.layout !== timelineTileLayout)
      ) {
        updateSceneAttribute({
          resolution: streamQuality.toString(),
          layout: timelineTileLayout.toString(),
        });
      }
    }, [
      selectedSceneData,
      streamQuality,
      timelineTileLayout,
      updateSceneAttribute,
    ]);

    useEffect(() => {
      return () => {
        unmountHandlerRef.current();
      };
    }, []);

    const showSceneName = !!selectedSceneData;

    //Check if video quality selection is rolled out to the customer
    const videoQualitySelectionConfig = useCustomerProfileValue(
      DFConfigKeys.fe_video_quality_selection,
    );
    const videoQualitySelectionEnabled = !!_.get(
      videoQualitySelectionConfig,
      'enabled',
      false,
    );

    return (
      <Flex vertical flex={'1 0'} ref={ref}>
        <Flex
          gap={8}
          align="center"
          justify="space-between"
          style={{ marginBottom: '18px' }}>
          {showSceneName && (
            <EditableName
              name={sceneName}
              onNameChange={handleSceneNameChange}
              isEditable={selectedSceneData.createdBy === currentUserId}
            />
          )}
          <TimelinePlayerTileSettingsContainer>
            {videoQualitySelectionEnabled && (
              <StreamQualitySelector
                value={streamQuality}
                onChange={setStreamQuality}
              />
            )}
            <TimelineLayoutSelectorContainer>
              <TileLayoutSelector
                value={timelineTileLayout}
                onChange={setTimelineTileLayout}
              />
            </TimelineLayoutSelectorContainer>
          </TimelinePlayerTileSettingsContainer>
        </Flex>
        <TimelinePlayerContainer $fitTimeline={fitTimeline}>
          {chIDs.length > 0 ? (
            <TimelinePlayer
              innerRef={timelinePlayerRef}
              tileLayout={timelineTileLayout}
              preferredStreamQuality={
                videoQualitySelectionEnabled ? streamQuality : undefined
              }
              {...timelinePlayerProps}
            />
          ) : (
            <NewSceneTimelinePlaceholder />
          )}
        </TimelinePlayerContainer>
      </Flex>
    );
  },
);

export { SceneTimeline };
