import { useLocation } from '@umijs/max';
import type { ThemeConfig } from 'antd';
import { ConfigProvider } from 'antd';
import _ from 'lodash';
import { useMemo } from 'react';
import { dfTheme } from './dfTheme';

interface DfThemeConfigProviderProps {
  children: React.ReactNode;
}

const getThemeOverridesForRoute = (pathname: string): ThemeConfig => {
  if (pathname.startsWith('/launchpad')) {
    return {
      components: {
        Typography: {
          colorText: 'white',
          colorTextHeading: 'white',
          fontSize: 16,
        },
        Collapse: {
          colorTextHeading: 'white',
          headerPadding: '16px 0',
          fontSize: 18,
        },
      },
    };
  }

  return {};
};

const DfThemeConfigProvider = ({ children }: DfThemeConfigProviderProps) => {
  const { pathname } = useLocation();
  const themeOverridesForRoute = useMemo(
    () => getThemeOverridesForRoute(pathname),
    [pathname],
  );
  const theme = useMemo(
    () => _.merge({}, dfTheme, themeOverridesForRoute),
    [themeOverridesForRoute],
  );

  return <ConfigProvider theme={theme}>{children}</ConfigProvider>;
};

export { DfThemeConfigProvider };
