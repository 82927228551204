import {
  addConfigProfile,
  addConfigProfiles,
  changeUserRole,
  fetchCustomerBillingMetrics,
  fetchCustomerConfigs,
  fetchLicenseInfo,
  getCheckoutSession,
  getInvite,
  getUsers,
  removeConfigProfile,
  removeUser,
  requestLicense,
  userInvite,
  withdrawInvite,
} from '@/services/account';
import _ from 'lodash';

import { Licenses, SKUS } from '@/types/licenses';
import type { Model } from 'dva';

export type AccountsModelState = {
  users: any[];
  invites: any[];
  licenses: Record<string, Licenses>;
  skus: SKUS;
  sort: {
    sortBy: any;
    isDescending: boolean;
  };
  errors: Record<any, any>;
};

const AccountsModel: Model & { state: AccountsModelState } = {
  namespace: 'accounts',
  state: {
    users: [],
    invites: [],
    licenses: {},
    skus: {
      modules: [],
      suites: [],
    },
    sort: {
      sortBy: '',
      isDescending: true,
    },
    errors: {},
  },
  effects: {
    // add modify remove save
    *fetchUsers(action, { call, put }) {
      const response = yield call(() => getUsers());
      if (response.success) {
        yield put({
          type: 'saveAccount',
          payload: response.data,
        });
      } else {
        yield put({
          type: 'error',
          errorKey: 'fetchUsers',
          payload: response.data,
        });
      }
    },

    *fetchInvite(action, { call, put }) {
      const response = yield call(() => getInvite());
      if (response.success) {
        yield put({
          type: 'saveInvite',
          payload: response.data,
        });
      }
    },

    *changeRole(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() =>
        changeUserRole(payload.userID, payload.roles),
      );
      if (response.success) {
        yield put({ type: 'fetchUsers' });
        yield put({ type: 'fetchInvite' });
      }
      return response;
    },

    *inviteUserToCustomer(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() =>
        userInvite(payload.email, payload.role),
      );
      if (response.success) {
        yield put({
          type: 'inviteUser',
          payload: response.data,
        });
        yield put({ type: 'fetchUsers' });
        yield put({ type: 'fetchInvite' });
      }
      return response;
    },
    *removeUser(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => removeUser(payload.userID));
      if (response.success) {
        yield put({
          type: 'remove',
          payload,
        });
      }
      return response;
    },
    *withdrawInviteFromCustomerAccount(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => withdrawInvite(payload.inviteID));
      if (response.success) {
        yield put({
          type: 'withdrawInvite',
          payload,
        });
      }
      return response;
    },
    *fetchCustomerBillingMetrics(action, { call }) {
      const response = yield call(() => fetchCustomerBillingMetrics());
      return response;
    },
    *fetchLicenseInfo(action, { call, put }) {
      const response = yield call(() => fetchLicenseInfo());
      if (response.success) {
        yield put({
          type: 'saveLicenseData',
          payload: response.data,
        });
      }
      return response;
    },
    *requestLicense(action, { call }) {
      const { payload } = action;
      const response = yield call(() => requestLicense(payload));
      return response;
    },
    *getCheckoutSession(action, { call }) {
      const { payload } = action;
      const response = yield call(() => getCheckoutSession(payload));
      return response;
    },
    *fetchCustomerConfigs(action, { call }) {
      const response = yield call(() => fetchCustomerConfigs());
      return response;
    },
    *removeConfigProfile(action, { call }) {
      const { payload } = action;
      const response = yield call(() => removeConfigProfile(payload));
      return response;
    },
    *addConfigProfile(action, { call }) {
      const { payload } = action;
      const response = yield call(() => addConfigProfile(payload));
      return response;
    },
    *addConfigProfiles(action, { call }) {
      const { payload } = action;
      const response = yield call(() => addConfigProfiles(payload));
      return response;
    },
    *sortUsers(action, { put }) {
      const { payload } = action;
      yield put({
        type: 'setUserSort',
        payload,
      });
      yield put({ type: 'sortUserList' });
    },
  },
  reducers: {
    error(state, action) {
      return {
        ...state,
        errors: { ...state.errors, [action.errorKey]: action.payload },
      };
    },
    saveAccount(state, action) {
      return { ...state, users: action.payload };
    },

    saveInvite(state, action) {
      return { ...state, invites: action.payload };
    },
    changeAccount(state) {
      return state;
    },
    inviteUser(state) {
      return state;
    },
    saveLicenseData(state, action) {
      return {
        ...state,
        licenses: action.payload.licenses || {},
        skus: action.payload.skus,
      };
    },
    remove(state, action) {
      _.remove(
        state.users,
        (user) => user.User.UserID === action.payload.userID,
      );
      return { ...state, users: state.users };
    },
    withdrawInvite(state, action) {
      _.remove(
        state.invites,
        (invite) => invite.InviteUUID === action.payload.inviteID,
      );
      return { ...state, invites: state.invites };
    },
    setUserSort(state, action) {
      const { sort } = state;
      sort.sortBy = action.payload.sortBy;
      return {
        ...state,
        sort,
      };
    },
    sortUserList(state) {
      let { users, sort } = state;
      users = users.sort((a: any, b: any) => {
        if (a.User[sort.sortBy] < b.User[sort.sortBy]) {
          return -1;
        }
        if (a.User[sort.sortBy] > b.User[sort.sortBy]) {
          return 1;
        }
        return 0;
      });
      return { ...state, users };
    },
  },
};

export default AccountsModel;
