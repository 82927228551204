import {
  Flex,
  notification,
  Popconfirm,
  Popover,
  Spin,
  Typography,
} from 'antd';
import {
  SceneCardContainer,
  SceneChannelThumbnailsContainer,
  SceneDetailsContainer,
  SceneDetailsTimestampText,
  SceneTitleNameEditIcon,
  ScreenDetailsTitleText,
  ShareUnshareIconContainer,
  StyledDeleteOutlined,
} from './styles';

import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { SceneTypesMap } from '../../constants';
import { useMonitorContext } from '../../MonitorContext';
import {
  useSceneRemoveActions,
  useSceneUpdateActions,
} from '../../MonitorMutations';
import type { SceneType } from '../../types';
import { ShareWithTeam } from './ShareWithTeam';
import { TileThumbnail } from './TileThumbnail';
import { UnShareWithTeam } from './UnShareWithTeam';
import { getSceneName } from './utils';
import type {
  SceneFragment$data,
  SceneFragment$key,
} from './__generated__/SceneFragment.graphql';

interface SceneProps {
  sceneKey: SceneFragment$key;
  onSelect: (
    sceneRef: SceneFragment$key,
    sceneData: SceneFragment$data,
  ) => void;
  sceneType: SceneType;
  parentConnectionId: string | null;
}

export const SceneFragment = graphql`
  fragment SceneFragment on MonitorScene @relay(mask: false) {
    id
    name
    isFavorite
    timestamp
    anchorTimeStamp
    createdBy
    isSharedWithTeam
    layout
    resolution
    channels {
      ChannelID
      Name
      ...ChannelTileAndName_Channel
      ...TileThumbnailChannelsMedia
    }
  }
`;

const Scene = ({
  sceneKey,
  onSelect,
  sceneType,
  parentConnectionId,
}: SceneProps) => {
  const { appId, currentUserId, customerId, isInternalDfEmployeeUser } =
    useMonitorContext();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const scene = useFragment(SceneFragment, sceneKey);
  const sceneName = scene.name ?? 'Scene';

  const isCurrentUserOwnerOfScene = scene.createdBy === currentUserId;
  const canMutateScene = isInternalDfEmployeeUser || isCurrentUserOwnerOfScene; // mutate scene includes - editing, removing from team scenes and deleting

  const { removeUserScene, isRemovingScene } = useSceneRemoveActions({
    scene,
    appId,
    customerId,
  });

  const { updateSceneAttribute } = useSceneUpdateActions({
    scene,
    appId,
  });

  const tileName = useMemo(() => getSceneName(scene), [scene]);

  const deleteSceneDescription = useMemo(() => {
    if (sceneType === SceneTypesMap.TEAM) {
      if (isCurrentUserOwnerOfScene)
        return 'Deleting will remove access for all your team members and will remove the scene from your scenes as well';

      return "Deleting will remove access for all your team members and will remove the scene from owner's scenes as well";
    } else {
      if (scene.isSharedWithTeam)
        return 'This scene is shared with team. Deleting will remove access for all your team members';

      return 'Are you sure you want to delete this scene';
    }
  }, [isCurrentUserOwnerOfScene, scene.isSharedWithTeam, sceneType]);

  const sceneRemoveSuccessMessage = useMemo(() => {
    if (sceneType === SceneTypesMap.TEAM) {
      if (isCurrentUserOwnerOfScene)
        return `${sceneName} deleted successfully from both Team Scenes and your scenes`;

      return `${sceneName} deleted successfully from Team Scenes and owner's scenes`;
    } else {
      if (scene.isSharedWithTeam)
        return `${sceneName} deleted successfully from your scenes and Team Scenes`;

      return `${sceneName} deleted successfully`;
    }
  }, [isCurrentUserOwnerOfScene, scene.isSharedWithTeam, sceneName, sceneType]);

  const showSceneRemoveSuccessNotification = () => {
    notification.success({
      message: sceneRemoveSuccessMessage,
      placement: 'bottomRight',
    });
  };

  const showSceneRemoveErrorNotification = () => {
    notification.error({
      message: `${sceneName} could not be deleted`,
      placement: 'bottomRight',
    });
  };

  const handleTileClick = () => {
    onSelect(sceneKey, scene);
  };

  const handleTileTitleChange = (text: string) => {
    if (text !== tileName) {
      updateSceneAttribute({ name: text });
    }
  };

  const handleMouseOverCardDetails = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeaveCardDetails = () => {
    setIsMouseOver(false);
  };

  const handleRemoveSceneConfirmed = () => {
    removeUserScene({
      onUserSceneRemoveSuccess: showSceneRemoveSuccessNotification,
      onUserSceneRemoveError: showSceneRemoveErrorNotification,
      connectionId: parentConnectionId,
    });
  };

  return (
    <SceneCardContainer
      onMouseOver={handleMouseOverCardDetails}
      onMouseLeave={handleMouseLeaveCardDetails}
      onClick={handleTileClick}>
      <SceneChannelThumbnailsContainer>
        <TileThumbnail channelsRef={scene.channels} />
      </SceneChannelThumbnailsContainer>
      <SceneDetailsContainer>
        <Flex gap={16} align="baseline" justify="space-between">
          {/* HACK: onClick on ScreenDetailsTitleText isnt applied in edit mode causing issues */}
          <div onClick={(e) => e?.stopPropagation()} style={{ width: '85%' }}>
            <ScreenDetailsTitleText
              editable={
                isMouseOver && canMutateScene
                  ? {
                      onChange: handleTileTitleChange,
                      icon: <SceneTitleNameEditIcon />,
                      autoSize: { maxRows: 2 },
                    }
                  : false
              }
              ellipsis={{ rows: 4, expandable: false }}
              onClick={(e) => e?.stopPropagation()}>
              {tileName}
            </ScreenDetailsTitleText>
          </div>
          {isMouseOver &&
            !isRemovingScene &&
            (canMutateScene ? (
              <div onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  title="Delete Scene?"
                  onConfirm={handleRemoveSceneConfirmed}
                  description={deleteSceneDescription}
                  okText="Yes"
                  cancelText="No"
                  overlayStyle={{ maxWidth: '350px' }}>
                  <StyledDeleteOutlined />
                </Popconfirm>
              </div>
            ) : (
              <Popover
                content={
                  <Typography.Text style={{ color: 'red' }}>
                    This scene can only be deleted by the scene owner
                  </Typography.Text>
                }
                trigger="click">
                <StyledDeleteOutlined />
              </Popover>
            ))}
          {isRemovingScene && (
            <Spin size="small" indicator={<LoadingOutlined spin />} />
          )}
          {isMouseOver && (
            <ShareUnshareIconContainer onClick={(e) => e.stopPropagation()}>
              {sceneType === SceneTypesMap.TEAM && (
                <UnShareWithTeam
                  scene={scene}
                  connectionId={parentConnectionId}
                />
              )}
              {sceneType === SceneTypesMap.USER && (
                <ShareWithTeam scene={scene} />
              )}
            </ShareUnshareIconContainer>
          )}
        </Flex>
        {scene?.timestamp && (
          <SceneDetailsTimestampText>
            Last updated {moment(Number(scene?.timestamp) * 1000).fromNow()}
          </SceneDetailsTimestampText>
        )}
      </SceneDetailsContainer>
    </SceneCardContainer>
  );
};
export { Scene };
