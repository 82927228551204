import { default as DefaultCameraImage } from '@/assets/cam-image-default.png';
import ChannelImageStream from '@/components/channel-image-stream';
import { MonitorAppId } from '@/pages/apps/app/VMSPlus/constants';
import { getChannelThumbnailImageUrl } from '@/pages/apps/app/VMSPlus/utils';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Link } from '@umijs/max';
import { Image, Popover, theme as antdTheme, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { graphql, useFragment } from 'react-relay';
import {
  ChanneImageStreamWrapper,
  ChannelNameText,
  ChannelTileAndNameContainer,
  ChannelTileAndNameThumbnailContainer,
  LivePreviewDetailsContainer,
  LivePreviewMediaContainer,
  LivePreviewPopoverContainer,
  OnlineContainer,
  OnlineDot,
  OnlineText,
  OverlayContainer,
  PreviewMediaHeight,
  PreviewMediaWidth,
  ThumbnailImage,
} from './styles';
import type { ChannelTileAndName_Channel$key } from './__generated__/ChannelTileAndName_Channel.graphql';

const PopoverContent = ({
  channelID,
  channelName,
  siteName,
  thumbnailURL,
}: {
  channelID: number;
  channelName: string;
  siteName: string;
  thumbnailURL: string;
}) => {
  const [showOnline, setShowOnline] = useState(true);
  return (
    <LivePreviewPopoverContainer>
      <Link to={`/apps/${MonitorAppId}?channelIds=${channelID}`}>
        <LivePreviewMediaContainer>
          <ThumbnailImage
            src={thumbnailURL}
            preview={false}
            width={PreviewMediaWidth}
            height={PreviewMediaHeight}
            onError={() => setShowOnline(false)}
            fallback={DefaultCameraImage}
            style={{ objectFit: showOnline ? 'fill' : 'cover' }} // HACK: to prevent fallback from streching but allow main image to stretch
          />
          <ChanneImageStreamWrapper>
            <ChannelImageStream channelID={channelID} showLoader={false} />
          </ChanneImageStreamWrapper>
        </LivePreviewMediaContainer>
      </Link>
      <LivePreviewDetailsContainer>
        <ChannelNameText strong ellipsis={{ tooltip: channelName }}>
          {channelName}
        </ChannelNameText>
        <Typography.Text>{siteName}</Typography.Text>
        {showOnline && (
          <OnlineContainer>
            <OnlineDot />
            <OnlineText>Online</OnlineText>
          </OnlineContainer>
        )}
      </LivePreviewDetailsContainer>
    </LivePreviewPopoverContainer>
  );
};

const ChannelTileFragment = graphql`
  fragment ChannelTileAndName_Channel on Channel {
    Name
    ChannelID
    MonitorStatus
    LatestMedia
    Project {
      Site {
        SiteID
      }
    }
  }
`;

type ChannelTileAndNameProps = {
  tileFragmentRef: ChannelTileAndName_Channel$key;
  siteName: string;
  onClick: (
    channelId: number,
    channelRef: ChannelTileAndName_Channel$key,
  ) => void;
  isSelected?: boolean;
  dragging?: boolean;
};
const ChannelTileAndName = ({
  tileFragmentRef,
  isSelected,
  siteName,
  onClick,
  dragging = false,
}: ChannelTileAndNameProps) => {
  const { useToken } = antdTheme;
  const { token } = useToken();
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const channelData = useFragment(ChannelTileFragment, tileFragmentRef);
  const channelThumbnailUrl = useMemo(
    () => (inView ? getChannelThumbnailImageUrl(channelData) : ''),
    [channelData, inView],
  );
  const tileContent = (
    <ChannelTileAndNameContainer
      key={channelData.ChannelID}
      onClick={() => {
        onClick(Number(channelData.ChannelID), tileFragmentRef);
      }}>
      <ChannelTileAndNameThumbnailContainer ref={ref}>
        <Image
          src={channelThumbnailUrl}
          fallback={DefaultCameraImage}
          preview={false}
        />
        {isSelected && (
          <OverlayContainer>
            <CheckCircleTwoTone twoToneColor={token.colorPrimary} />
          </OverlayContainer>
        )}
      </ChannelTileAndNameThumbnailContainer>
      <Typography.Text style={{ fontWeight: 500 }}>
        {channelData.Name}
      </Typography.Text>
    </ChannelTileAndNameContainer>
  );

  return dragging ? (
    tileContent
  ) : (
    <Popover
      placement="leftBottom"
      arrow={false}
      destroyTooltipOnHide
      overlayInnerStyle={{
        padding: 0,
        overflow: 'hidden',
      }}
      content={
        <PopoverContent
          channelID={Number(channelData.ChannelID)}
          channelName={channelData.Name as string}
          siteName={siteName}
          thumbnailURL={channelThumbnailUrl}
        />
      }>
      {tileContent}
    </Popover>
  );
};

export default ChannelTileAndName;
