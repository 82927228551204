import {
  CheckCircleFilled,
  DeleteOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Divider, Flex, Spin, Typography, Upload } from 'antd';
import React, { useState } from 'react';
import { importData } from '../../services';
import styles from './style.less';

type UploadInfoType = {
  filename: string;
  status: 'success' | 'error' | 'processing';
};

type UploadBoxProps = {
  uploadTo: (file: File | null) => Promise<any>;
  type: 'Sites' | 'Contacts';
  refreshData: () => void;
};

const UploadBox: React.FC<UploadBoxProps> = ({
  uploadTo,
  type,
  refreshData,
}) => {
  const [uploadInfo, setUploadInfo] = useState<UploadInfoType | null>(null);

  const handleCustomRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;

    const processingStatus: UploadInfoType = {
      filename: file.name,
      status: 'processing',
    };
    setUploadInfo(processingStatus);

    try {
      const response = await uploadTo(file);
      const data = response.data;
      const hasErrorMessages =
        data?.errors &&
        (data.errors.sites?.length || data.errors.site_contacts?.length);

      if (!response?.success || hasErrorMessages) {
        const uploadError: UploadInfoType = {
          filename: file.name,
          status: 'error',
        };
        setUploadInfo(uploadError);
        onError();
      } else {
        const uploadSuccess: UploadInfoType = {
          filename: file.name,
          status: 'success',
        };
        setUploadInfo(uploadSuccess);
        refreshData();
        onSuccess();
      }
    } catch (error) {
      const uploadError: UploadInfoType = {
        filename: file.name,
        status: 'error',
      };
      setUploadInfo(uploadError);
      onError();
    }
  };

  const color =
    uploadInfo?.status === 'processing'
      ? 'blue'
      : uploadInfo?.status === 'error'
      ? 'red'
      : 'green';

  return (
    <Flex vertical style={{ width: '100%' }}>
      {/* We can simplify this on upgrading to Antd 5.21 which allows us to pass showRemoveIcon in showUploadList which will allow us to conditionally show the delete icon
      We can do away with uploadInfo then which is only implemented to only show delete icon on failed uploads*/}
      <Upload.Dragger
        className={styles['drop-box']}
        accept=".csv"
        customRequest={handleCustomRequest}
        showUploadList={false}>
        <Flex vertical align="center" gap={4}>
          <InboxOutlined className={styles['upload-icon']} />
          <Typography.Text>{`Drag & drop a ${type} CSV file here`}</Typography.Text>
          <Typography.Text>or</Typography.Text>
          <Button color="primary" className={styles['browse-button']}>
            Browse
          </Button>
        </Flex>
      </Upload.Dragger>
      {uploadInfo && (
        <Flex
          justify="space-between"
          style={{ width: '100%', marginTop: '8px' }}>
          <Flex align="center" gap={8} flex="1 1 0" style={{ minWidth: 0 }}>
            {uploadInfo.status === 'processing' && (
              <Spin
                indicator={
                  <LoadingOutlined style={{ fontSize: '14px', color }} spin />
                }
              />
            )}
            {uploadInfo.status === 'success' && (
              <CheckCircleFilled style={{ color }} />
            )}
            {uploadInfo && (
              <Typography.Text
                style={{
                  color,
                  flexGrow: 1,
                  whiteSpace: 'nowrap',
                }}
                ellipsis>
                {uploadInfo.status === 'processing'
                  ? `Importing ${uploadInfo.filename}`
                  : uploadInfo.status === 'error'
                  ? `Unable to import ${uploadInfo.filename}`
                  : `Imported ${uploadInfo.filename} successfully`}
              </Typography.Text>
            )}
          </Flex>
          {uploadInfo.status === 'error' && (
            <Button
              type="text"
              size="small"
              icon={<DeleteOutlined />}
              danger
              onClick={() => setUploadInfo(null)}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};

const ImportSites: React.FC<{ refreshData: () => void }> = ({
  refreshData,
}) => {
  const uploadToSites = async (file: File | null) => {
    return importData(file, null);
  };

  const uploadToContacts = async (file: File | null) => {
    return importData(null, file);
  };

  return (
    <Flex vertical align="left" gap={12}>
      <Typography.Text>
        Select a CSV file to upload bulk site and contact data
      </Typography.Text>
      <UploadBox
        uploadTo={uploadToSites}
        type="Sites"
        refreshData={refreshData}
      />
      <UploadBox
        uploadTo={uploadToContacts}
        type="Contacts"
        refreshData={refreshData}
      />
      <Divider />
      <Typography.Title level={5}>
        What CSV format is accepted?
      </Typography.Title>
      <Typography.Text>
        Please arrange your CSV file to match the headers shown below and then
        save the file as a CSV filetype.&nbsp;
        <Typography.Link
          href="https://en.wikipedia.org/wiki/Comma-separated_values"
          target="_blank">
          Learn more about CSV files
        </Typography.Link>
      </Typography.Text>
      <Button
        href="https://s3.us-east-1.amazonaws.com/downloads.dragonfruit.ai/assets/files/FleetManager/Sites_Upload_Sample.csv"
        target="_blank">
        Download sample Sites CSV file
      </Button>
      <Button
        href="https://s3.us-east-1.amazonaws.com/downloads.dragonfruit.ai/assets/files/FleetManager/Contacts_Upload_Sample.csv"
        target="_blank">
        Download sample Contacts CSV file
      </Button>
    </Flex>
  );
};

export default ImportSites;
