import InfoSidebar from '@/components/InfoSidebar';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import ReviewQueueCard from '@/components/ReviewQueueCard';
import { STREAM_QUALITY } from '@/components/StreamQualitySelector/constants';
import TimelinePlayer from '@/components/TimelinePlayer';
import { DFConfigKeys } from '@/dfConfigKeys';
import NotificationsEditRule from '@/pages/apps/app/components/notifications-edit-rule-2';
import { ACCESS_CONTROL_APPS, APPS } from '@/pages/apps/app/constants/appList';
import { getSidebarActions } from '@/utils/datatable';
import {
  doNotificationOp,
  notificationAppID,
  notificationOps,
  playAlertSound,
  refreshNotifications,
  transformConfiguredRule,
  transformTriggered2,
} from '@/utils/notifications';
import {
  ga,
  interpretClipData,
  isCustomerProfileEnabled,
  isInternalUser,
  urlTo,
} from '@/utils/utils';
import { Badge, Button, Empty, notification, Table, Tabs } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect, history, Link } from 'umi';
import { PRIORITY_IGNORE, PRIORITY_REVIEW_QUEUE } from './AlarmApp/constants';
import BaseApp from './BaseApp';
import NotificationsRuleMenu from './components/notifications-rule-menu';
import NotificationsTriggeredTable from './components/notifications-triggered-table';
import styles from './style.less';
const { TabPane } = Tabs;

// @ts-expect-error
@connect(({ apps, user, accounts, locations, loading }) => ({
  allApps: apps.all,
  alertApp: apps.byID[44],
  accounts,
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  currentUser: user.currentUser,
  loadingDoAppOp: loading.effects['apps/doAppOp'],
  loadingFetch: loading.effects['apps/fetchApp'],
}))
class NotificationsApp extends BaseApp {
  static appID = 44;

  constructor(props) {
    super(props);
    this.alertDetailsRef = React.createRef();
    this.state = {
      dataSource: [],
      visibleQcards: {},
      updatingQcard: {},
    };

    this.props.dispatch({
      type: 'accounts/fetchUsers',
    });

    // when a URL with an ?alertID=xyz shows up, we remove that from the URL,
    // then send that alertID as state to get remounted.
    // note that there is a 'get permalink' button at the bottom of the
    // InfoSideBar layer
    if (history.location.state) {
      this.state.selectedAlertID = history.location.state?.selectedAlertID;
    }
  }

  componentDidMount() {
    this.setData(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.data, this.props.data) ||
      !_.isEqual(prevProps.loc, this.props.loc) ||
      !_.isEqual(prevProps.ch_grp, this.props.ch_grp) ||
      !_.isEqual(prevProps.ch, this.props.ch)
    ) {
      this.setData(this.props.data);
    }
  }

  static sendAppNotification = (
    { loc, ch_grp, ch, currentUser, dispatch },
    notificationWithContext: NotificationInstance,
    appInfo,
    wsMessage,
  ) => {
    const note = _.get(wsMessage, 'data');

    const alertID = _.get(note, 'trigger_id');
    const ruleName = _.get(note, 'rule_name');
    const ruleSourceId = _.get(note, 'rule_source_ids[0]', null);
    const channelIDs =
      _.get(note, 'rule_source_type') === 'channel'
        ? _.get(note, 'alert_channel_ids', _.get(note, 'rule_source_ids'))
        : [];
    const priority = _.get(note, 'priority');
    const timezone = _.get(note, 'rule_timezone', 'UTC');
    const startTime = moment
      .tz(_.get(note, 'triggered_at'), 'YYYY-MM-DD HH:mm:ss', timezone)
      .unix();
    const endTime = moment
      .tz(_.get(note, 'event_end_time'), 'YYYY-MM-DD HH:mm:ss.SSSSSS', timezone)
      .unix();
    const internalUser: boolean = isInternalUser(currentUser);
    // If user is external,  dont show review queue notifs
    if (priority === PRIORITY_REVIEW_QUEUE && !internalUser) {
      return null;
    }

    refreshNotifications(dispatch);
    const key = `alert-${alertID}`;
    const appID = _.get(
      note,
      ['rule_threshold', 'source', 'app'],
      notificationAppID,
    );

    const closeAction = (doNavigate) => {
      if (doNavigate) {
        setTimeout(() => {
          history.push(`/apps/${appID}?alertID=${alertID}`);
        }, 100);
      }
      // IMPORTANT: we need to do this so we unmount the
      // ChannelImageStream component, which sends the unrequest
      // response to frontier to potentially stop streaming
      notificationWithContext.open({
        key,
        message: undefined,
        description: undefined,
        duration: 1,
      });
      notification.config({ closeIcon: undefined });
      notificationWithContext.destroy(key);
    };

    let additionalDescription = null;
    const appObj = APPS[+ruleSourceId];
    if (
      appObj &&
      ACCESS_CONTROL_APPS.includes(appObj) &&
      'getNotificationAlertDescription' in appObj
    ) {
      additionalDescription = appObj.getNotificationAlertDescription(
        { loc, ch_grp, ch },
        wsMessage,
      );
    }

    if (isCustomerProfileEnabled(currentUser, DFConfigKeys.fe_alert_sounds)) {
      playAlertSound();
    }

    notification.config({
      closeIcon: (
        <div
          onClick={(e) => {
            e.preventDefault();
            closeAction(false);
          }}>
          x
        </div>
      ),
    });
    notificationWithContext.info({
      key,
      message: `Alert: ${ruleName}`,
      description: (
        <>
          <div style={{ cursor: 'pointer' }}>
            <Button
              style={{ paddingLeft: 0 }}
              type="link"
              onClick={(e) => {
                e.preventDefault();
                closeAction(true);
              }}>
              View Details
            </Button>
          </div>
          {channelIDs.map((channelID) => {
            const channelObj = ch.byId[channelID];
            if (!channelObj) return null;

            const link = urlTo('CHANNEL', {
              locID: channelObj.ProjectID,
              chID: channelObj.ID,
            });
            return (
              <div style={{ margin: '8px 0' }} key={channelID}>
                <div
                  style={{
                    borderRadius: '8px',
                    overflow: 'hidden',
                    height: '200px',
                  }}>
                  <TimelinePlayer
                    channelIDs={[channelID]}
                    startTime={startTime}
                    endTime={endTime}
                    showHideControls={true}
                    autoPlay={false}
                    restrictHistoryToStartEnd={true}
                    //Force use video stream
                    preferredStreamQuality={STREAM_QUALITY.MEDIUM}
                  />
                </div>
                <div style={{ cursor: 'pointer' }}>
                  Camera: <Link to={link}>{channelObj?.Name}</Link>
                </div>
              </div>
            );
          })}
          {additionalDescription}
        </>
      ),
      duration: 0,
      onClick: () => {},
      btn: (
        <Button
          size="small"
          onClick={(e) => {
            e.preventDefault();
            closeAction(false);
          }}>
          Close
        </Button>
      ),
    });
  };

  getPayload() {
    return {};
  }

  setTriggered(triggered, cb) {
    this.setState({ triggered }, cb);
  }

  wrangleData(data) {
    let triggered = this.transformTriggered(data);
    this.setTriggered(triggered);

    // find the selected alert again
    let selectedAlert = null;
    if (this.state.selectedAlert) {
      selectedAlert = triggered.find(
        (alert) => alert.id === this.state.selectedAlert.id,
      );
    }

    this.setState(
      {
        configured: this.transformConfigured(data),
        // TODO: what if the page doesn't have this anymore
        selectedAlert,
        loading: false,
      },
      () => this.setSelectedFromQuery(),
    );
  }

  setData(data) {
    if (data) {
      this.wrangleData(data);
    }
  }

  getTypeEl(record) {
    const deliveryType = _.get(record, 'delivery.type', 'realtime_alerts');
    if (deliveryType !== 'app_alerts') {
      return 'Search';
    }

    const appID = _.get(record, 'sources.apps[0].id');
    const app = this.props.allApps.find((x) => x.AppID === appID);
    return app ? app.Name : 'Unknown';
  }

  getTimeframeEl(t) {
    return (
      <span>
        <span className={styles['tf-timing']}>
          <span>{t.time_range.from}</span>-<span>{t.time_range.to}</span>
        </span>
        {[
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',
        ].map((d, i) => (
          <span
            className={styles[t.days.indexOf(d) === -1 ? 'tf-off' : 'tf-on']}
            key={d}
            style={{
              borderRadius:
                i === 0 ? '4px 0 0 4px' : i === 6 ? '0 4px 4px 0' : '',
            }}>
            {d[0].toUpperCase()}
          </span>
        ))}
      </span>
    );
  }

  getActionsEl(record) {
    const types = [];
    [
      ['Email', 'emailEnabled'],
      ['Text', 'phoneEnabled'],
      ['Voice', 'voiceEnabled'],
      ['Webhook', 'webhookEnabled'],
    ].forEach(([name, field]) => {
      if (record[field]) {
        types.push(name);
      }
    });
    return types.join(', ');
  }

  transformConfigured(data) {
    return _.get(data, 'configuration.rules.list', []).map((el) =>
      transformConfiguredRule(el),
    );
  }

  transformTriggered(data) {
    return transformTriggered2(
      {
        loc: this.props.loc,
        ch: this.props.ch,
        apps: this.props.allApps,
        alertApp: this.props.alertApp,
      },
      ga(data, 'triggered.list'),
    );
  }

  updateURLParams() {
    const alert = this.state.selectedAlert;
    const params = {};
    if (alert) {
      params['alertID'] = alert.id;
    }
    history.push({
      search: `?${new URLSearchParams(params).toString()}`,
    });
  }

  setSelectedAlert(alert) {
    this.setState({ selectedAlert: alert });
    // reset the history
    if (!alert) {
      history.replace({ ...history.location, state: undefined });
    }
  }

  setSelectedFromQuery() {
    const alertID =
      parseInt(_.get(this.props, 'queryParams.alertID', '0')) ||
      this.state.selectedAlertID;
    let alert;
    if (this.state.triggered && alertID) {
      alert = this.state.triggered.find((r) => r.id === alertID);
      if (alert) {
        this.setSelectedAlert(alert);
      }
    }
    // remove the alertID param
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('alertID')) {
      queryParams.delete('alertID');
      history.replace(
        {
          search: queryParams.toString(),
        },
        { selectedAlertID: alertID },
      );
    }
  }

  highlightSelectedRow(record) {
    if (this.state.selectedAlert && this.state.selectedAlert.id === record.id) {
      return styles['selected-row'];
    }
    return '';
  }

  updateTriggerState(record, cb) {
    // used to update a record locally, and updating state with that, instead
    // of going to the server, with the optimistic assumption that it would have
    // worked. increases speed at the cost of busted state if the server fails
    let current = this.state.triggered;
    let rec = _.find(current, (r) => r.id === record.id);
    _.assign(rec, record);
    this.setTriggered(current, cb);
  }

  updatePriority(record, priority) {
    const { dispatch } = this.props;
    const { updatingQcard } = this.state;

    this.setState({ updatingQcard: { ...updatingQcard, [record.id]: true } });
    doNotificationOp(
      dispatch,
      notificationOps.setPriorityAlert,
      {
        alert_id: record.id,
        value: priority,
      },
      { showFeedbackOnFailureOnly: true },
    );
    record.priority = priority;
    this.updateTriggerState(record, () =>
      this.setState({
        updatingQcard: { ...updatingQcard, [record.id]: false },
      }),
    );
  }

  // called if the selectedAlert object is updated in the sidebar.
  // the caller can use this info to refresh the view if they desire
  sidebarTargetUpdated(selectedAlert) {
    const { triggered } = this.state;

    let newTriggered = [];
    let newSelected = _.cloneDeep(selectedAlert);
    _.forEach(triggered, (alert) => {
      newTriggered.push(alert.id === newSelected.id ? newSelected : alert);
    });

    this.setState({ triggered: newTriggered, selectedAlert: newSelected });
  }

  render() {
    const { appID, loadingFetch, dispatch } = this.props;
    const { triggered, selectedAlert, updatingQcard, configured } = this.state;
    let queueCards = (triggered || []).filter(
      (x) => x.priority === PRIORITY_REVIEW_QUEUE,
    );
    // take only first 20 - they get replenished as they get used up
    queueCards = _.take(queueCards, 20);

    const tableDataSource = (triggered || []).filter(
      (el) => _.range(1, 6).indexOf(_.get(el, 'priority', -1)) !== -1,
    );

    return (
      <div>
        <PageHeader
          title="Real-time Alerts"
          right={
            <>
              <NotificationsEditRule>
                <Button size="small" type="default">
                  + Rule
                </Button>
              </NotificationsEditRule>
              &nbsp;
              <Button
                onClick={() => refreshNotifications(dispatch)}
                loading={loadingFetch}
                size="small"
                type="default">
                Refresh
              </Button>
            </>
          }
        />
        {selectedAlert && (
          <InfoSidebar
            clip={selectedAlert}
            searchResults={selectedAlert?.searchResults?.clips}
            onClose={() => this.setSelectedAlert(null)}
            getExtraActions={() => getSidebarActions(this, selectedAlert)}
          />
        )}
        <Tabs tabBarStyle={{ marginRight: '16px' }} destroyInactiveTabPane>
          <TabPane tab="Alerts" key="alerts">
            <div style={{ marginRight: '16px' }}>
              <NotificationsTriggeredTable
                dataSource={tableDataSource}
                dispatch={this.props.dispatch}
                selected={selectedAlert}
                doSelected={(r) => this.setSelectedAlert(r)}
                enableReadReport={true}
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <Badge size="small" count={queueCards.length}>
                Review Queue
              </Badge>
            }
            key="queue">
            {!queueCards.length && (
              <div className="df-center">
                <Empty
                  description={
                    <>
                      <p>No alerts to review.</p>
                      <p>
                        Enable 'Review Queue' in Rule settings to review alerts
                        before sending notifications.
                      </p>
                    </>
                  }
                />
              </div>
            )}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {queueCards.map((alert, index) => {
                const { visibleQcards } = this.state;
                const { loc, ch, ch_grp } = this.props;
                const info = interpretClipData(
                  alert,
                  { loc, ch, ch_grp },
                  alert?.timezone,
                  alert?.searchResults?.clips,
                );
                const channelID = info.channel_obj?.ID;
                if (!channelID) {
                  return null;
                }

                const rule = _.find(configured, (r) => r.id === alert.ruleID);
                return (
                  <ReviewQueueCard
                    key={info.clip.key}
                    alert={info}
                    alertTitle={
                      <>
                        <div>Alert for&nbsp;</div>
                        <div className="df-link">
                          <NotificationsEditRule rule={rule}>
                            {alert.ruleName}
                          </NotificationsEditRule>
                        </div>
                      </>
                    }
                    controls={
                      <>
                        {updatingQcard[alert.id] && (
                          <div className={styles['loading-ctn']}>
                            <LoadingSpinner fontSize={35} />
                          </div>
                        )}
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <div
                            style={{ marginRight: '8px' }}
                            onClick={() =>
                              this.updatePriority(alert, rule.priority)
                            }>
                            <Button>Raise Alert</Button>
                          </div>
                          <div>
                            <Button
                              onClick={() =>
                                this.updatePriority(alert, PRIORITY_IGNORE)
                              }>
                              Ignore
                            </Button>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (
                              !selectedAlert ||
                              selectedAlert.id !== alert.id
                            ) {
                              this.setSelectedAlert(alert);
                            } else {
                              this.setSelectedAlert(null);
                            }
                          }}
                          className="df-link">
                          Details
                        </div>
                      </>
                    }
                    timelineIsVisible={
                      !!(index === 0 || this.state.visibleQcards[alert.id])
                    }
                    onTimeLineClick={() =>
                      this.setState({
                        visibleQcards: { ...visibleQcards, [alert.id]: true },
                      })
                    }
                  />
                );
              })}
            </div>
          </TabPane>
          <TabPane tab="Rules" key="rules">
            <Table
              style={{ marginRight: '16px' }}
              className={styles['table-container']}
              dataSource={this.state.configured}
              size="small"
              rowClassName={(record) =>
                record.enabled || styles['disabled-row']
              }
              pagination={true}>
              <Table.Column
                dataIndex="name"
                key="name"
                title={<span className={styles.header}>Name</span>}
              />
              <Table.Column
                dataIndex="filterType"
                key="filterType"
                align="center"
                title={<span className={styles.header}>Type</span>}
                render={(text, record) => this.getTypeEl(record)}
              />
              <Table.Column
                dataIndex="timeframe"
                key="timeframe"
                align="center"
                title={<span className={styles.header}>Timeframe</span>}
                render={(text, record) => this.getTimeframeEl(record.timeframe)}
              />
              <Table.Column
                dataIndex="actions"
                key="actions"
                align="center"
                title={<span className={styles.header}>Actions</span>}
                render={(text, record) => this.getActionsEl(record)}
              />
              <Table.Column
                dataIndex="priority"
                key="priority"
                align="center"
                title={<span className={styles.header}>Priority</span>}
              />
              <Table.Column
                dataIndex="controls"
                key="controls"
                title=""
                render={(text, record) => (
                  <NotificationsRuleMenu appID={appID} rule={record} />
                )}
              />
            </Table>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default NotificationsApp;
