import * as atatus from 'atatus-spa';

declare const ATATUS_KEY: string;

interface User {
  id: string; //<user_id-customer_id>
  full_name: string;
}

export function initialize() {
  if (ATATUS_KEY) {
    atatus
      .config(ATATUS_KEY, {
        //Capture only these logs from console
        consoleTimelineLevels: ['error'],
      })
      .install();
  }
}

export function logError(
  error: any,
  customData?: Record<string, any>,
  tags?: string[],
): void {
  atatus.notify(error, customData, tags);
}

export function beginTransaction(type: string): void {
  atatus.beginTransaction(type);
}

export function endTransactionWithSuccess(type: string): void {
  atatus.endTransaction(type);
}

export function endTransactionWithFailure(type: string): void {
  atatus.failTransaction(type);
}

//Simply record one - Pass duration in milliseconds
export function recordTransaction(type: string, duration: number): void {
  atatus.recordTransaction(type, duration);
}

export function setUser(user: User | null): void {
  if (user) {
    atatus.setUser(user.id, '', user.full_name);
  }
}

export function sendSlackNotification(
  message: string,
  webhookUrl: string = SLACK_WEBHOOK,
) {
  if (!webhookUrl || !message) {
    return;
  }

  return fetch(webhookUrl, {
    method: 'POST',
    body: JSON.stringify({ text: message }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Slack API responded with status: ${response.status}`);
      }
      return response.text();
    })
    .catch((error) =>
      console.error('Error sending Slack notification:', error),
    );
}
