import type { DefaultOptionType } from 'antd/lib/cascader';
import _ from 'lodash';
import { ALL_SITES_GROUP_LABEL } from './constants';
import type {
  CascaderValuePath,
  Site,
  SiteCascaderValue,
  SiteGroup,
} from './types';

export const getSiteOptions = (
  sites: Site[],
  siteGroups: SiteGroup[],
): DefaultOptionType[] => {
  const siteOptions: DefaultOptionType[] = [];

  // add options for all sites
  siteOptions.push({
    label: 'All Sites',
    value: ALL_SITES_GROUP_LABEL,
    children: sites
      .sort((a, b) => (a.Name > b.Name ? 1 : -1))
      .map((site) => ({
        label: site.Name,
        value: site.SiteID,
      })),
  });

  // add option for each site group type
  const siteGroupsByType = new Map<SiteGroup['LabelType'], SiteGroup[]>();
  siteGroups.forEach((siteGroup) => {
    const siteGroupsOfType = siteGroupsByType.get(siteGroup.LabelType) || [];
    siteGroupsOfType.push(siteGroup);
    siteGroupsByType.set(siteGroup.LabelType, siteGroupsOfType);
  });

  siteGroupsByType.forEach((siteGroupsOfType, labelType) => {
    siteOptions.push({
      label: `by ${labelType}`,
      value: labelType,
      children: siteGroupsOfType
        .sort((a, b) => (a.Name > b.Name ? 1 : -1))
        .map((siteGroup) => ({
          label: siteGroup.Name,
          value: siteGroup.LabelID,
          children: siteGroup.Sites.sort((a, b) =>
            a.Name > b.Name ? 1 : -1,
          ).map((site) => ({
            label: site.Name,
            value: site.SiteID,
          })),
        })),
    });
  });

  return siteOptions;
};

export const deduplicateCascaderValues = (
  values: SiteCascaderValue[],
): SiteCascaderValue[] => {
  const uniqueValues: SiteCascaderValue[] = [];
  const seenValues = new Set<string | number>();

  values.forEach((value) => {
    const site = value[value.length - 1];
    if (!seenValues.has(site)) {
      uniqueValues.push(value);
      seenValues.add(site);
    }
  });

  return uniqueValues;
};

export const isOptionLeaf = (option: DefaultOptionType): boolean =>
  !option.children;

const isSiteSelectedInAnyPath = (
  path: CascaderValuePath,
  value: SiteCascaderValue[],
): boolean => {
  return value.some(
    (valuePath) => valuePath[valuePath.length - 1] === path[path.length - 1],
  );
};

const isSitePathExactlySelected = (
  path: CascaderValuePath,
  value: SiteCascaderValue[],
): boolean => {
  return value.some((valuePath) => _.isEqual(path, valuePath));
};

export const updateOptions = (
  options: DefaultOptionType[],
  value: SiteCascaderValue[],
  path: CascaderValuePath = [],
): DefaultOptionType[] => {
  for (const option of options) {
    path.push(option.value);
    if (isOptionLeaf(option)) {
      if (
        isSiteSelectedInAnyPath(path, value) &&
        !isSitePathExactlySelected(path, value)
      ) {
        option.disabled = true;
      } else {
        option.disabled = false;
      }
    } else {
      updateOptions(option.children || [], value, path);
    }
    path.pop();
  }
  return [...options];
};
