import TimelinePlayer from '@/components/TimelinePlayer';
import { interpretClipData } from '@/utils/utils';
import { Flex } from 'antd';
import _ from 'lodash';
import { useSelector } from 'umi';
import type { SelfCheckoutLossDetectionModelState } from '../constants';

const IncidentVideo = () => {
  const locations = useSelector((state: any) => {
    const l = state.locations;
    return {
      all: l.all,
      ch: l.ch,
      loc: l.loc,
      ch_grp: l.ch_grp,
    };
  });

  const incident_data = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) => state.self_checkout_loss_detection.selectedIncidentData?.clipData,
  );

  const result = incident_data.clips.reduce(
    (
      acc: { events: any[]; minFrom: number | null; maxTo: number | null },
      clip: any,
    ) => {
      const timezone = locations.ch.byId[clip?.ChannelID].Timezone;
      const clipInfo = interpretClipData(clip, locations, timezone, [clip]);

      if (clipInfo) {
        // Extract `from` and `to`
        const from = _.get(clipInfo, 'from')?.valueOf();
        const to = _.get(clipInfo, 'to')?.valueOf();

        // Update minFrom and maxTo
        acc.minFrom =
          from !== undefined && from !== null
            ? acc.minFrom === null
              ? from
              : Math.min(acc.minFrom, from)
            : acc.minFrom;

        acc.maxTo =
          to !== undefined && to !== null
            ? acc.maxTo === null
              ? to
              : Math.max(acc.maxTo, to)
            : acc.maxTo;

        // Append events if they exist
        if (clipInfo.events) {
          acc.events.push(...clipInfo.events);
        }
      }

      return acc;
    },
    { events: [], minFrom: null, maxTo: null },
  );

  const startTime = result.minFrom !== null ? result.minFrom / 1000 : null;
  const endTime = result.maxTo !== null ? result.maxTo / 1000 : null;

  const clipEvents: any[] = result.events;
  // matches burglar alarm, TODO BE needs to be updates
  let source_ch_ids = incident_data?.source?.camera?.ids.map(Number);
  let source_es_start = incident_data?.timeframe?.start;
  let source_es_end = incident_data?.timeframe?.end;

  // handle legacy case
  if (source_ch_ids && source_es_start && source_es_end) {
    if (source_ch_ids.length > 1) {
      console.warn(
        'multiple channels found, only using first channel for video',
      );
      source_ch_ids = [source_ch_ids[0]];
    }
  } else {
    console.warn(
      'legacy incident data format, trying to interpret from clips...',
    );
    // - go through clips and get min start and max start time of each clip
    // - make sure to filter out and mark as a warning if clips are from different channels
    let esStartTime = Infinity;
    let esEndTime = -Infinity;
    let channelID = null;
    for (const clip of incident_data.clips) {
      if (!channelID) {
        channelID = clip.ChannelID;
      } else {
        if (channelID !== clip.ChannelID) {
          console.error('clips are from different channels, skipping ...');
          continue;
        }
      }
      esStartTime = Math.min(esStartTime, clip.ESVideoStartTime);
      esEndTime = Math.max(esEndTime, clip.ESVideoEndTime);
    }
    // update
    source_es_start = esStartTime / 1000;
    source_es_end = esEndTime / 1000;
    source_ch_ids = [Number(channelID)];
  }

  if (!source_ch_ids) {
    return <div>no clips for incident</div>;
  }

  // get timezone
  const timezone = locations.ch.byId[source_ch_ids[0]]?.Timezone;
  if (!timezone) {
    return <div>no timezone found for channel</div>;
  }

  return (
    <Flex vertical style={{ height: '550px' }}>
      <h1> video section</h1>
      <TimelinePlayer
        autoPlay={false} // sometimes takes long to load then you skip the event...
        startTime={startTime}
        endTime={endTime}
        events={clipEvents}
        eventsOnly={true}
        channelIDs={source_ch_ids}
      />
    </Flex>
  );
};

export default IncidentVideo;
