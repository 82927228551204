import { Checkbox, Col, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'umi';
import type {
  IncidentKey,
  SelfCheckoutLossDetectionModelState,
} from '../constants';
import { IncidentCategory } from '../constants';
import { useAuditStatusSelector } from '../utils/selector';
import AuditSubmitButton from './audit-submit-button';
import IncidentVisuals from './incident-visuals';
import PredictedReference from './predicted-reference';
import SearchSimilarProduct from './search-similar-products';
import SelectionToggle from './selection-toggle';
import SimilarProductSuggestion from './similar-product-suggestions';
import TransactionLineItems from './transaction-line-items';

const SelfCheckoutIncidentAudit: React.FC<{ incidentId: IncidentKey }> = ({
  incidentId,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector(
    (state: any) =>
      state.loading.effects['self_checkout_loss_detection/fetIncidentDetails'],
  );

  const incident = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) => state.self_checkout_loss_detection.selectedIncidentData,
  );

  const auditStatus = useAuditStatusSelector();

  useEffect(() => {
    dispatch({
      type: 'self_checkout_loss_detection/fetIncidentDetails',
      payload: incidentId,
    });
  }, [dispatch, incidentId]);

  const case3ToggleHandler = () => {
    if (auditStatus.incidentCategory === IncidentCategory.CASE3) {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.DEFAULT },
      });
    } else {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.CASE3 },
      });
    }
  };

  if (loading) return <h3> loading transaction data...</h3>;
  if (!incident) return <h3> no details found </h3>;

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <h1>{incident.name || 'Unnamed Incident'}</h1>

        <IncidentVisuals />

        <Row gutter={10}>
          <Col span={12}>
            <PredictedReference />
          </Col>
          <Col span={12}>
            <TransactionLineItems />
          </Col>
        </Row>

        <Row justify="center" gutter={20} style={{ margin: '10px 0' }}>
          <Col span={10}>
            <Checkbox
              checked={auditStatus.incidentCategory == IncidentCategory.CASE3}
              onChange={case3ToggleHandler}>
              Wrong prediction and Item missing
            </Checkbox>
          </Col>
          <Col span={8}>
            <SelectionToggle toggle_value={IncidentCategory.NEGATIVE} />
          </Col>
          <Col span={6}>
            <SelectionToggle toggle_value={IncidentCategory.UNSURE} />
          </Col>
        </Row>

        {auditStatus.incidentCategory === IncidentCategory.CASE3 ? (
          <Row>
            <Col span={12}>
              <SearchSimilarProduct />
            </Col>
            <Col span={12}>
              <SimilarProductSuggestion />
            </Col>
          </Row>
        ) : null}

        <Row justify="center">
          <Col span={8} style={{ textAlign: 'center' }}>
            <AuditSubmitButton />
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default SelfCheckoutIncidentAudit;
