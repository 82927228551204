import { Flex } from 'antd';

interface DfFlexProps extends React.ComponentProps<typeof Flex> {
  direction?: 'row' | 'column';
}

/**
 * A customized component wrapper over antd's `<Flex />` component to tailor DF specific needs
 */
const DfFlex = ({ children, direction = 'row', ...restProps }: DfFlexProps) => {
  return (
    <Flex
      {...restProps}
      style={{
        flexDirection: direction,
      }}>
      {children}
    </Flex>
  );
};

export { DfFlex };
