import BrandProtectionImageOne from '@/assets/brand_protection_image_1.png';
import BrandProtectionImageTwo from '@/assets/brand_protection_image_2.png';
import CityComplianceImageOne from '@/assets/city_compliance_image_1.png';
import CityComplianceImageTwo from '@/assets/city_compliance_image_2.png';
import EarlyWarningSystemImageOne from '@/assets/early_warning_system_image_1.png';
import EarlyWarningSystemImageTwo from '@/assets/early_warning_system_image_2.png';
import IndustrialSafetyImageOne from '@/assets/industrial_safety_image_1.png';
import IndustrialSafetyImageTwo from '@/assets/industrial_safety_image_2.png';
import TrailTrackingImageOne from '@/assets/trail_tracking_image_1.png';
import TrailTrackingImageTwo from '@/assets/trail_tracking_image_2.png';
import WildfireSafetyImageOne from '@/assets/wildfire_safety_image_1.png';
import WildfireSafetyImageTwo from '@/assets/wildfire_safety_image_2.png';
import type { ExampleUsageCardProps } from './types';

export const ExampleUsageCardsData: ExampleUsageCardProps[] = [
  {
    title: 'Trail Tracking',
    videoUrl: 'https://downloads.dragonfruit.ai/site/Trail+Tracking.mp4',
    cost: '$0.02/day',
    samples: [
      {
        imageUrl: TrailTrackingImageOne,
        description:
          'A barren hillside is seen in the distance. There are two tree trunks on the right side of the image. The ground is covered with dry grass and rocks. There is a small green tree on the left side. In the distance there are more trees and mountains.',
        jsonOutput: {
          uuid: '34494_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1151',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'A barren hillside is seen in the distance. There are two tree trunks on the right side of the image. The ground is covered with dry grass and rocks. There is a small green tree on the left side. In the distance there are more trees and mountains. ',
          channel_id: '34494',
        },
      },
      {
        imageUrl: TrailTrackingImageTwo,
        description:
          'A leopard is standing on the top of a hill. There are two trees next to the leopard. There is a large hill behind them with trees on it.',
        jsonOutput: {
          uuid: '34494_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1152',
          timestamp_start: 1738404300.911,
          timestamp_end: 1738404300.911,
          caption:
            'A leopard is standing on the top of a hill. There are two trees next to the leopard. There is a large hill behind them with trees on it. ',
          channel_id: '34494',
        },
      },
    ],
  },
  {
    title: 'Wildfire safety',
    videoUrl: 'https://downloads.dragonfruit.ai/site/Wildfire+Safety.mp4',
    cost: '$0.02/day',
    samples: [
      {
        imageUrl: WildfireSafetyImageOne,
        description:
          'There is a field with dead grass on it. There are trees in the distance behind the field. There is a black bench in front of the field on the ground.',
        jsonOutput: {
          uuid: '38892_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1131',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'There is a field with dead grass on it. There are trees in the distance behind the field. There is a black bench in front of the field on the ground. ',
          channel_id: '38892',
        },
      },
      {
        imageUrl: WildfireSafetyImageTwo,
        description:
          'A man in a yellow jacket is standing in front of a fire. There is a large cloud of smoke coming from the fire. The fire is bright orange and yellow. There are trees in the distance behind the man.',
        jsonOutput: {
          uuid: '38892_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1220',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'A man in a yellow jacket is standing in front of a fire. There is a large cloud of smoke coming from the fire. The fire is bright orange and yellow. There are trees in the distance behind the man.  ',
          channel_id: '38892',
        },
      },
    ],
  },
  {
    title: 'Brand protection',
    videoUrl: 'https://downloads.dragonfruit.ai/site/Brand+protection.mp4',
    cost: '$0.02/day',
    samples: [
      {
        imageUrl: BrandProtectionImageOne,
        description:
          'A man is looking out of a window. The man has short hair and a beard. His eyes are open and he has a slight smile on his face. He is wearing a short sleeve shirt. The window behind him is blue and there is a red object behind him.',
        jsonOutput: {
          uuid: '21998_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1151',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'A man is looking out of a window. The man has short hair and a beard. His eyes are open and he has a slight smile on his face. He is wearing a short sleeve shirt. The window behind him is blue and there is a red object behind him.',
          channel_id: '21998',
        },
      },
      {
        imageUrl: BrandProtectionImageTwo,
        description:
          'There are several people standing in a room. There is a boy wearing a jean jacket with a red ribbon around his neck. He has his mouth open and his hands are in the air. Next to him is a girl wearing a white jacket and a blue shirt. She is holding a can of pepsi in her hand. ',
        jsonOutput: {
          uuid: '21998_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1776',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'There are several people standing in a room. There is a boy wearing a jean jacket with a red ribbon around his neck. He has his mouth open and his hands are in the air. Next to him is a girl wearing a white jacket and a blue shirt. She is holding a can of pepsi in her hand.',
          channel_id: '21998',
        },
      },
    ],
  },
  {
    title: 'Industrial safety',
    videoUrl: 'https://downloads.dragonfruit.ai/site/Industrial+Safety.mp4',
    cost: '$0.02/day',
    samples: [
      {
        imageUrl: IndustrialSafetyImageOne,
        description:
          'A picture of a factory. There are two men standing in the middle of the room. There is a large blue machine to the left of the men. The man to the right is wearing a black suit and is standing in front of a white table. The floor of the factory is gray. The ceiling of the building is made of metal.',
        jsonOutput: {
          uuid: '30332_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1151',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'A picture of a factory. There are two men standing in the middle of the room. There is a large blue machine to the left of the men. The man to the right is wearing a black suit and is standing in front of a white table. The floor of the factory is gray. The ceiling of the building is made of metal.',
          channel_id: '30332',
        },
      },
      {
        imageUrl: IndustrialSafetyImageTwo,
        description:
          'A person is walking in a large industrial building. There are large machines surrounding the person. There is a lot of smoke coming out of one of the machines.',
        jsonOutput: {
          uuid: '30332_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1233',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'A person is walking in a large industrial building. There are large machines surrounding the person. There is a lot of smoke coming out of one of the machines.',
          channel_id: '30332',
        },
      },
    ],
  },
  {
    title: 'Early warning system',
    videoUrl: 'https://downloads.dragonfruit.ai/site/Mall+Safety.mp4',
    cost: '$0.02/day',
    samples: [
      {
        imageUrl: EarlyWarningSystemImageOne,
        description:
          'This is a picture of some people in a mall. The people are walking around. There is a man in a white shirt and black pants. There are other people standing around in the mall.',
        jsonOutput: {
          uuid: '31887_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1151',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'This is a picture of some people in a mall. The people are walking around. There is a man in a white shirt and black pants. There are other people standing around in the mall..',
          channel_id: '31887',
        },
      },
      {
        imageUrl: EarlyWarningSystemImageTwo,
        description:
          'This is a picture of some people in a mall. The people are running around. There is a man in a white shirt and black pants. There are stores next to the people.',
        jsonOutput: {
          uuid: '31887_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_2111',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'This is a picture of some people in a mall. The people are running around. There is a man in a white shirt and black pants. There are stores next to the people.',
          channel_id: '31887',
        },
      },
    ],
  },
  {
    title: 'City compliance',
    videoUrl: 'https://downloads.dragonfruit.ai/site/City+compliance.mp4',
    cost: '$0.02/day',
    samples: [
      {
        imageUrl: CityComplianceImageOne,
        description:
          'A cobblestone street is lit up at dusk. There are tall black lamp posts lining the street. On the left side of the street there is a white building with a large window. In front of the building there is an awning with tables and chairs under it.',
        jsonOutput: {
          uuid: '32176_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1151',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'A cobblestone street is lit up at dusk. There are tall black lamp posts lining the street. On the left side of the street there is a white building with a large window. In front of the building there is an awning with tables and chairs under it.',
          channel_id: '32176',
        },
      },
      {
        imageUrl: CityComplianceImageTwo,
        description:
          'There are people skateboarding on the sidewalk. There is a black lamp post in front of them. There are trees next to the street. ',
        jsonOutput: {
          uuid: '32176_f8e10b1a-83b5-43ed-8b0f-fa1eeb69b5ae_1879',
          timestamp_start: 1738404279.911,
          timestamp_end: 1738404279.911,
          caption:
            'There are people skateboarding on the sidewalk. There is a black lamp post in front of them. There are trees next to the street. ',
          channel_id: '32176',
        },
      },
    ],
  },
];
