import { Typography } from 'antd';
import { TermsSectionContainer } from './styles';

const { Title, Paragraph } = Typography;

const TermsAndConditions = () => (
  <Typography
    style={{ maxWidth: '800px', margin: '0 auto', padding: '0 24px' }}>
    <Title level={1} style={{ textAlign: 'center' }}>
      Subscription Terms
    </Title>

    <TermsSectionContainer>
      <Title level={3}>Subscription Overview</Title>
      <Paragraph>
        By subscribing to Dragonfruit AI’s (“Company”) Launchpad, you
        (“Subscriber”) agree to pre-pay a fee (the “Fee”), which is determined
        by parameters set in your Account Settings. The specific Fee is provided
        at checkout and may be updated in accordance with TermsSectionContainer
        3 (Price Changes). Once your pre-paid balance is depleted, your access
        to Launchpad will be suspended unless you add additional funds or enable
        auto-refill.
      </Paragraph>
    </TermsSectionContainer>

    <TermsSectionContainer>
      <Title level={3}>Automatic Refill / Non-Refill Option</Title>
      <Title level={4}>Automatic Refill</Title>
      <Paragraph>
        If you opt in to automatic refill, you authorize Company to charge your
        chosen payment method whenever your account balance runs low, ensuring
        uninterrupted access to Launchpad.
      </Paragraph>

      <Title level={4}>Opt-Out of Refill</Title>
      <Paragraph>
        If you choose to opt out, your service will end once your pre-paid
        balance is exhausted. You will not be charged again unless you manually
        add more funds.
      </Paragraph>
    </TermsSectionContainer>

    <TermsSectionContainer>
      <Title level={3}>Account Settings and Pricing</Title>
      <Paragraph>
        Your Account Settings control how quickly your pre-paid balance is
        consumed. You must ensure these settings are accurate and up to date.
      </Paragraph>
      <Paragraph>
        Any changes to your Account Settings may affect the rate at which your
        balance is used.
      </Paragraph>
    </TermsSectionContainer>

    <TermsSectionContainer>
      <Title level={3}>Price Changes</Title>
      <Paragraph>
        Company reserves the right to modify the Fee (including rates against
        your pre-paid balance) at any time. Changes will be communicated to you
        at least 10 days in advance and apply prospectively.
      </Paragraph>
      <Paragraph>
        If you do not agree to a revised Fee, you must stop using Launchpad or
        disable auto-refill before the effective date of the change.
      </Paragraph>
    </TermsSectionContainer>

    <TermsSectionContainer>
      <Title level={3}>Refunds and Cancellations</Title>
      <Title level={4}>Refund Policy</Title>
      <Paragraph>
        Fees are generally non-refundable, except as required by law or as
        otherwise stated in a written agreement with Company.
      </Paragraph>
      <Paragraph>
        Refund requests are evaluated on a case-by-case basis per applicable
        consumer protection laws.
      </Paragraph>

      <Title level={4}>Subscription Cancellation</Title>
      <Paragraph>
        You may stop using Launchpad or disable auto-refill at any time in your
        Account Settings.
      </Paragraph>
      <Paragraph>
        Once canceled or if auto-refill is disabled, access continues until your
        current pre-paid balance is depleted.
      </Paragraph>
    </TermsSectionContainer>

    <TermsSectionContainer>
      <Title level={3}>Payment and Billing</Title>
      <Paragraph>
        You must provide a valid payment method when subscribing. By enabling
        auto-refill, you authorize Company to automatically charge this method
        when your balance is running low.
      </Paragraph>
      <Paragraph>
        If a charge fails, you have 24 hours to update your payment information.
        Otherwise, your account may be suspended or terminated once your balance
        is depleted.
      </Paragraph>
    </TermsSectionContainer>

    <TermsSectionContainer>
      <Title level={3}>Additional Terms</Title>
      <Paragraph>
        Your use of Launchpad is also governed by Company’s Terms of Service
        (“TOS”). The TOS includes provisions regarding, among other things,
        governing law, warranty disclaimers, limitations of liability, and
        licensing.
      </Paragraph>
      <Paragraph>
        In the event of any conflict between these Subscription Terms and the
        TOS, these Subscription Terms control only as to payment, pre-paid
        balances, and subscription-specific features. All other matters are
        governed by the TOS.
      </Paragraph>
    </TermsSectionContainer>

    <TermsSectionContainer>
      <Title level={3}>Contact Information</Title>
      <Paragraph>
        If you have questions about these Subscription Terms, billing, or your
        subscription, please contact{' '}
        <a href="mailto:contact@Dragonfruit.ai">contact@Dragonfruit.ai</a>.
      </Paragraph>
    </TermsSectionContainer>

    <TermsSectionContainer>
      <Title level={3}>Entire Agreement</Title>
      <Paragraph>
        These Subscription Terms, together with the TOS and any other documents
        expressly incorporated by reference, constitute the entire agreement
        between you and Company regarding your Launchpad subscription and
        supersede all prior agreements, understandings, and communications
        related to this subscription.
      </Paragraph>
    </TermsSectionContainer>
  </Typography>
);

export default TermsAndConditions;
