import { message } from 'antd';
import _ from 'lodash';

import {
  DEFAULT_PAGE_SIZE,
  SortTypeMap,
} from '@/pages/apps/app/VMSPlus/components/SceneViewer/components/SearchForm/constants';
import type {
  ObjectType,
  SortType,
} from '@/pages/apps/app/VMSPlus/components/SceneViewer/components/SearchForm/types';
import { searchMedia } from '@/services/search';
import { interpretClipData } from '@/utils/search-result';
import type { Model } from 'dva';

export interface MonitorSearchPayload {
  queryID: string;
  Metadata: {
    CustomerID: number;
    ChannelID: number[];
  };
  Objects?: ObjectType[];
  license_plate?: string;
  sort: SortType;
  ESVideoStartTime: string;
  ESVideoEndTime: string;
  p_number: number;
  p_size: number;
  person_colors?: {
    top_colors?: string[];
    bottom_colors?: string[];
  };
  vehicle_colors?: string[];
  EmbeddingSearch?: {
    ObjectID: string;
    ChannelID: string;
  };
  search_object?: string;
}

export const defaultMonitorSearchPayload: MonitorSearchPayload = {
  queryID: 'DEFAULT_QUERY_ID_REPLACE',
  Metadata: {
    CustomerID: 0,
    ChannelID: [],
  },
  sort: SortTypeMap.CHRONOLOGICAL,
  ESVideoStartTime: '1900-01-01T00:00:00.000000Z',
  ESVideoEndTime: '1900-01-01T00:00:00.000000Z',
  p_number: 1,
  p_size: DEFAULT_PAGE_SIZE,
};

export interface MonitorSearchState {
  currentPayload: MonitorSearchPayload;
  searchResults: {
    search_result_clips: any[] | null;
    clips: any[] | null;
    p_number: number;
    total_pages: number;
  };
}

export const MONITOR_SEARCH_DEFAULT_STATE: MonitorSearchState = {
  currentPayload: { ...defaultMonitorSearchPayload },
  searchResults: {
    search_result_clips: null,
    clips: null,
    p_number: 1,
    total_pages: 0,
  },
};

const MonitorSearchModel: Model = {
  namespace: 'monitor_search',
  state: MONITOR_SEARCH_DEFAULT_STATE,
  effects: {
    *search({ payload }: { payload: MonitorSearchPayload }, { call, put }) {
      yield put({ type: 'savePayload', payload });

      const response = yield call(searchMedia, payload);
      if (response.success) {
        yield put({
          type: 'saveSearchResults',
          payload: response.data,
        });
      } else {
        message.error(`Search Failed! ${response.data?.message || ''}`);
      }
    },
    *paginate(
      { payload }: { payload: { p_number: number; p_size: number } },
      { put, select },
    ) {
      // Retrieve the existing payload from state
      const currentPayload: MonitorSearchPayload = yield select(
        (state: any) => state.monitor_search.currentPayload,
      );

      const updatedPayload: MonitorSearchPayload = {
        ...currentPayload,
        p_number: payload.p_number,
        p_size: payload.p_size,
      };

      yield put({ type: 'search', payload: updatedPayload });
    },
    *sort({ payload }: { payload: { sortType: SortType } }, { put, select }) {
      const currentPayload: MonitorSearchPayload = yield select(
        (state: any) => state.monitor_search.currentPayload,
      );
      const updatedPayload: MonitorSearchPayload = {
        ...currentPayload,
        sort: payload.sortType,
        p_number: 1, // Reset to page 1
      };

      yield put({ type: 'search', payload: updatedPayload });
    },
  },
  reducers: {
    savePayload(
      state: MonitorSearchState,
      { payload }: { payload: MonitorSearchPayload },
    ): MonitorSearchState {
      return { ...state, currentPayload: payload };
    },
    saveSearchResults(state: MonitorSearchState, { payload }) {
      const clips = _.get(payload, 'clips', []);
      return {
        ...state,
        searchResults: {
          clips,
          search_result_clips: interpretClipData(_.cloneDeep(clips)),
          p_number: _.get(payload, 'p_number', 1),
          total_pages: _.get(payload, 'total_pages', 0),
        },
      };
    },
    resetState(): MonitorSearchState {
      return { ...MONITOR_SEARCH_DEFAULT_STATE };
    },
  },
};

export default MonitorSearchModel;
