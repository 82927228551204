import { Typography } from 'antd';
import styled from 'styled-components';

export const RadioLabel = styled.div`
  transform: translateY(10px);
  align-self: flex-start;
`;

export const RadioHelpText = styled.div`
  color: rgb(156, 163, 175);
  margin-bottom: 3px;
  a {
    font-size: inherit !important;
  }
`;

export const BalanceInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0px;
`;

export const FormHeading = styled(Typography.Title)`
  color: #2b104f !important;
`;
