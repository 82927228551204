import PageHeader from '@/components/PageHeader2';
import InvestigationCard from '@/pages/home/components/investigation-card';
import InvestigationGroupTile from '@/pages/home/components/investigation-group-tile';
import withRouter from '@/utils/withRouter';
import { Button, Empty, Tooltip } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, Link } from 'umi';
import CreateInvestigation from '../components/create-investigation';
import CreateInvestigationGroup from './components/create-investigation-group';
import DeleteInvestigationGroup from './components/delete-investigation-group';
import MoveInvestigationGroup from './components/move-investigation-group';
import styles from './style.less';
import type { InvestigationGroupType } from './types';

interface InvestigationGroupProps {}
interface InvestigationGroupState {
  investigationGroup?: InvestigationGroupType;
  investigationGroupPageHeaderTitle: string;
}
@connect(({ investigations }) => ({ investigations }))
class InvestigationGroup extends React.Component<
  InvestigationGroupProps,
  InvestigationGroupState
> {
  constructor(props) {
    super(props);
    this.state = {
      investigationGroup: undefined,
      investigationGroupPageHeaderTitle: '',
    };
  }

  componentDidMount() {
    this.initInvestigationGroup();
  }

  componentDidUpdate(prevProps) {
    const investigationGroupID = _.get(
      this.props,
      'match.params.investigationGroupID',
    );
    if (
      investigationGroupID !==
        _.get(prevProps, 'match.params.investigationGroupID') ||
      prevProps.investigations.investigationGroups.byId !==
        this.props.investigations.investigationGroups.byId
    ) {
      this.initInvestigationGroup();
    }
  }

  initInvestigationGroup() {
    const investigationGroupID = Number(
      _.get(this.props, 'match.params.investigationGroupID'),
    );
    const investigationGroup: InvestigationGroupType | undefined =
      this.props.investigations.investigationGroups.byId[investigationGroupID];
    const titles = [];
    if (investigationGroup !== undefined) {
      let groupId = investigationGroupID;
      while (groupId) {
        const group =
          this.props.investigations.investigationGroups.byId[groupId];
        titles.unshift(group.Name);
        groupId = group.ParentID;
      }
    }
    this.setState({
      investigationGroup,
      investigationGroupPageHeaderTitle: titles.join(' > '),
    });
  }

  render() {
    const { investigationGroup, investigationGroupPageHeaderTitle } =
      this.state;
    if (investigationGroup === undefined)
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Investigations"
        />
      );
    const hasInvestigations =
      _.get(investigationGroup, 'InvestigationIDs', []).length !== 0;
    const hasInvestigationGroups =
      _.get(investigationGroup, 'InvestigationGroupIDs', []).length !== 0;
    if (!investigationGroup)
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Investigations"
        />
      );

    const sortedInvestigationGroups = _.get(
      investigationGroup,
      'InvestigationGroupIDs',
      [],
    )
      .map((igID) => this.props.investigations.investigationGroups.byId[igID])
      .sort((a, b) => a.Name.localeCompare(b.Name)); // Sort alphabetically by name

    const sortedInvestigations = _.get(
      investigationGroup,
      'InvestigationIDs',
      [],
    )
      .map((invID) => this.props.investigations.investigations.byId[invID])
      .sort((a, b) => a.Name.localeCompare(b.Name));
    return (
      <div className={styles.container}>
        <PageHeader
          title={
            <Tooltip
              placement="bottom"
              title={investigationGroupPageHeaderTitle}>
              <span>{investigationGroupPageHeaderTitle}</span>
            </Tooltip>
          }
          right={
            <>
              <CreateInvestigation
                investigationGroupID={investigationGroup.GroupID}>
                <Button size="small" type="default">
                  + Investigation
                </Button>
              </CreateInvestigation>
              &nbsp;
              <CreateInvestigationGroup
                parentInvestigationGroupID={investigationGroup.GroupID}>
                <Button size="small" type="default">
                  + Investigation Group
                </Button>
              </CreateInvestigationGroup>
              &nbsp;
              <CreateInvestigationGroup investigationGroup={investigationGroup}>
                <Button size="small" type="default">
                  Rename
                </Button>
              </CreateInvestigationGroup>
              &nbsp;
              <MoveInvestigationGroup investigationGroup={investigationGroup}>
                <Button size="small" type="default">
                  Move
                </Button>
              </MoveInvestigationGroup>
              &nbsp;
              <DeleteInvestigationGroup
                investigationGroup={investigationGroup}
                disabled={hasInvestigations || hasInvestigationGroups}>
                <Tooltip
                  title={
                    hasInvestigations || hasInvestigationGroups
                      ? 'Can only archive empty Investigation Groups'
                      : ''
                  }
                  placement="bottomLeft">
                  <Button
                    size="small"
                    type="default"
                    disabled={hasInvestigations || hasInvestigationGroups}>
                    Archive
                  </Button>
                </Tooltip>
              </DeleteInvestigationGroup>
            </>
          }
        />
        <div className={styles['investigations-container']}>
          {!hasInvestigations && !hasInvestigationGroups && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Investigations"
            />
          )}
          {sortedInvestigationGroups.map((group) => (
            <InvestigationGroupTile
              key={group.GroupID}
              investigationGroup={group}
            />
          ))}
          {hasInvestigations && hasInvestigationGroups && (
            <div className="horizontal-divider" />
          )}
          {sortedInvestigations.map((investigation) => (
            <Link
              key={investigation.InvestigationID}
              to={`/investigations/${investigation.ID}`}>
              <InvestigationCard investigation={investigation} />
            </Link>
          ))}
        </div>
      </div>
    );
  }
}
export default withRouter(InvestigationGroup);
