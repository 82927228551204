import { PlayCircleOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Flex, Modal, Typography } from 'antd';
import { useState } from 'react';
import { ExampleUsageCardsData } from '../constants';
import { SampleImage, VideoThumbnail } from './styles';

const { Text } = Typography;

const ExampleUsageCards = () => {
  const [videoModal, setVideoModal] = useState({ open: false, videoUrl: '' });
  const [jsonModal, setJsonModal] = useState({ open: false, jsonData: {} });

  return (
    <Flex wrap gap={16} vertical={false} justify="center">
      {ExampleUsageCardsData.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          extra={<span>{card.cost}</span>}
          style={{ maxWidth: '350px' }}>
          <Flex vertical gap={16}>
            <VideoThumbnail
              onClick={() =>
                setVideoModal({ open: true, videoUrl: card.videoUrl })
              }>
              <PlayCircleOutlined className="play-icon" />
            </VideoThumbnail>

            {card.samples.map((sample, idx) => (
              <Flex key={idx} gap={16} align="center" vertical>
                <SampleImage src={sample.imageUrl} alt="Sample" />
                <Text type="secondary">{sample.description}</Text>
                <Button
                  type="default"
                  onClick={() =>
                    setJsonModal({ open: true, jsonData: sample.jsonOutput })
                  }
                  style={{ width: 'fit-content' }}>
                  Show JSON output
                </Button>
                {idx !== card.samples.length - 1 && <Divider />}
              </Flex>
            ))}
          </Flex>
        </Card>
      ))}

      <Modal
        title="Video Player"
        open={videoModal.open}
        footer={null}
        onCancel={() => setVideoModal({ open: false, videoUrl: '' })}>
        <video key={videoModal.videoUrl} width="100%" controls>
          <source src={videoModal.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal>

      <Modal
        title="JSON Output"
        open={jsonModal.open}
        footer={null}
        onCancel={() => setJsonModal({ open: false, jsonData: {} })}>
        <pre style={{ whiteSpace: 'pre-wrap' }} lang="javascript">
          {JSON.stringify(jsonModal.jsonData, null, 2)}
        </pre>
      </Modal>
    </Flex>
  );
};

export { ExampleUsageCards };
