import { DfFlex } from '@/components/DfFlex';
import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Typography,
  Upload,
} from 'antd';
import Link from 'antd/es/typography/Link';
import { useEffect, useState } from 'react';
import { ENDPOINT_TYPES_MAP } from '../ConfigureAccordion/constants';
import { FormHeading, RadioHelpText, RadioLabel } from './styles';
import { getRTSPUrlsFromForm, validateCSV, validateRTSPUrl } from './utils';

const { Paragraph } = Typography;
const { Option } = Select;

const verticalRadioStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
};

export const Ingestion = ({
  onSubmit,
  onChange,
  initialValues = {
    sourceType: 'rtsp',
    endpointType: ENDPOINT_TYPES_MAP.description,
    callsPerMinute: 60,
  },
}) => {
  const [form] = Form.useForm();
  const sourceType = Form.useWatch('sourceType', form);
  const endpointType = Form.useWatch('endpointType', form);

  const handleSubmit = (values) => {
    if (onSubmit) {
      getRTSPUrlsFromForm(values)
        .then((rtspUrls) => {
          onSubmit({
            ...values,
            rtspUrls: rtspUrls.map(([url]) => url),
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleValuesChange = (values) => {
    if (values['csvFile']) {
      getRTSPUrlsFromForm(values)
        .then((rtspUrls) => {
          onChange({
            ...values,
            rtspUrls: rtspUrls.map(([url]) => url),
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      onChange(values);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      style={{ flex: '1' }}>
      <FormHeading level={5} style={{ color: '#2b104f' }}>
        Select how you provide the videos
      </FormHeading>
      <Form.Item name="sourceType">
        <Radio.Group style={verticalRadioStyles}>
          <Radio value="rtsp">Use RTSP URL</Radio>
          <Radio value="csv">Upload CSV of RTSP URLs</Radio>
        </Radio.Group>
      </Form.Item>
      {sourceType &&
        (sourceType === 'rtsp' ? (
          <Form.Item
            name="rtspUrl"
            label="RTSP URL"
            rules={[
              { required: true, message: 'RTSP URL is required' },
              { validator: validateRTSPUrl },
            ]}>
            <Input placeholder="rtsp://user:pass@ip:port/url..." />
          </Form.Item>
        ) : (
          <Form.Item
            name="csvFile"
            label="CSV File"
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
            rules={[
              { required: true, message: 'CSV file is required' },
              { validator: validateCSV },
            ]}>
            <Upload
              accept=".csv"
              beforeUpload={() => false}
              multiple={false}
              maxCount={1}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        ))}

      <FormHeading level={5}>Choose your endpoint type</FormHeading>
      <Form.Item name="endpointType">
        <Radio.Group style={verticalRadioStyles}>
          <Radio value={ENDPOINT_TYPES_MAP.description}>
            <RadioLabel>
              Receive Scene Descriptions
              <RadioHelpText>
                We will periodically send JSON with details about what's
                happening in the scene.{' '}
                <Link href="/launchpad/faq">Learn more</Link>
              </RadioHelpText>
            </RadioLabel>
          </Radio>

          <Radio value={ENDPOINT_TYPES_MAP.match}>
            <RadioLabel>
              Receive Match Results
              <RadioHelpText>
                We will call your endpoint only when a match is found.{' '}
                <Link href="/launchpad/faq">Learn more</Link>
              </RadioHelpText>
            </RadioLabel>
          </Radio>
        </Radio.Group>
      </Form.Item>
      {endpointType === ENDPOINT_TYPES_MAP.match && (
        <>
          <Form.Item
            name="matchType"
            label="Match Type"
            rules={[{ required: true, message: 'Match type is required' }]}>
            <Select placeholder="Select match type" style={{ width: '300px' }}>
              <Option value="person">People</Option>
              <Option value="vehicle">Vehicles</Option>
              <Option value="lp">License Plates</Option>
            </Select>
          </Form.Item>
        </>
      )}
      <Form.Item
        name="webhookEndpoint"
        label={
          endpointType === ENDPOINT_TYPES_MAP.description
            ? 'Description Endpoint'
            : 'Match Result Endpoint'
        }
        rules={[{ required: true, message: 'Endpoint is required' }]}>
        <Input placeholder="https://my-endpoint.com" />
      </Form.Item>

      <Form.Item
        name="callsPerMinute"
        label="Max API Calls per Minute"
        extra="You can also enter decimal values if you want calls less than once a minute — e.g. once an hour if that's your use case."
        rules={[{ required: true, message: 'This is required' }]}>
        <Input type="number" placeholder="60" min={0} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save Settings
        </Button>
      </Form.Item>
    </Form>
  );
};

export const Billing = ({
  onSubmit,
  amountPerDay = 0,
  minAmountToPay = 0,
  isBillingInProgress,
  initialValues = { autoReload: false },
  ingestionFormData,
}) => {
  const [form] = Form.useForm();
  const isAutoReloadChecked = Form.useWatch('autoReload', form);
  const paymentAmount = Form.useWatch('amount', form) || 0;
  const autoReloadAmount = Form.useWatch('autoReloadAmount', form) || 0;

  const totalPayable = paymentAmount + autoReloadAmount;

  const handleSubmitPayment = async (values) => {
    await onSubmit({
      ...values,
      totalPayable,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      amount: minAmountToPay,
      autoReloadAmount: amountPerDay,
    });
  }, [amountPerDay, minAmountToPay]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleSubmitPayment}>
      {minAmountToPay > amountPerDay ? (
        <Paragraph type="secondary" italic style={{ marginBottom: '16px' }}>
          Your daily cost from Step 1 is ${amountPerDay} per day. However, you
          must pay at least ${minAmountToPay} for this one-day prepay.
        </Paragraph>
      ) : null}

      <Form.Item
        label="Amount to Pay (USD)"
        name="amount"
        initialValue={minAmountToPay}
        rules={[{ required: true, message: 'Please enter payment amount' }]}>
        <InputNumber
          min={Math.max(
            amountPerDay,
            ingestionFormData.endpointType === ENDPOINT_TYPES_MAP.description
              ? 50
              : 5,
          )}
          style={{ width: '100%' }}
          placeholder="Enter amount"
        />
      </Form.Item>
      <Paragraph type="secondary" italic style={{ marginBottom: '16px' }}>
        Minimum required: ${minAmountToPay}
      </Paragraph>

      <Form.Item name="autoReload" valuePropName="checked">
        <Checkbox>Enable auto-reload</Checkbox>
      </Form.Item>

      {isAutoReloadChecked && (
        <Form.Item
          label="Auto-Reload Amount (USD)"
          name="autoReloadAmount"
          initialValue={amountPerDay}
          extra="When your credits are over, we'll automatically charge this amount."
          rules={[
            { required: true, message: 'Please enter auto-reload amount' },
          ]}>
          <InputNumber
            style={{ width: '100%' }}
            min={amountPerDay}
            placeholder="Enter auto-reload amount"
          />
        </Form.Item>
      )}
      <Form.Item style={{ marginTop: 24 }}>
        <Button type="primary" htmlType="submit" loading={isBillingInProgress}>
          Pay for 1 day
        </Button>
      </Form.Item>
    </Form>
  );
};

export const AccountCreation = ({ onSubmit, isInProgress = false }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { isMobile } = useDfMediaQuery();
  const termsChecked = (e) => {
    setTermsAccepted(e.target.checked);
  };
  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        password: '',
      }}>
      <DfFlex gap={isMobile ? 0 : 16} direction={isMobile ? 'column' : 'row'}>
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: true, message: 'First name is required' }]}
          style={{ flex: 1 }}>
          <Input placeholder="Enter your first name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true, message: 'Last name is required' }]}
          style={{ flex: 1 }}>
          <Input placeholder="Enter your last name" />
        </Form.Item>
      </DfFlex>
      <DfFlex gap={isMobile ? 0 : 16} direction={isMobile ? 'column' : 'row'}>
        <Form.Item
          name="company"
          label="Company"
          rules={[{ required: true, message: 'Company name is required' }]}
          style={{ flex: 1 }}>
          <Input placeholder="Enter your company name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'Enter a valid email address' },
          ]}
          style={{ flex: 1 }}>
          <Input placeholder="Enter your email" />
        </Form.Item>
      </DfFlex>
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: 'Password is required' }]}
        style={{ width: isMobile ? 'auto' : 'calc(50% - 8px)' }}>
        <Input.Password placeholder="Enter your password" />
      </Form.Item>
      <Form.Item
        name="acceptTerms"
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: 'Please select the checkbox',
          },
        ]}>
        <Checkbox onChange={termsChecked}>
          I agree to the{' '}
          <Link
            href="https://www.dragonfruit.ai/master-services-agreement"
            target="_blank"
            style={{ fontSize: '14px' }}>
            Terms of Service
          </Link>
          ,{' '}
          <Link
            href="https://www.dragonfruit.ai/privacy-policy"
            target="_blank"
            style={{ fontSize: '14px' }}>
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link
            href="/launchpad/terms"
            target="_blank"
            style={{ fontSize: '14px' }}>
            Payment Terms
          </Link>
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button
          disabled={!termsAccepted}
          type="primary"
          htmlType="submit"
          loading={isInProgress}>
          Save Account
        </Button>
      </Form.Item>
    </Form>
  );
};
