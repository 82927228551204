import Link from 'antd/es/typography/Link';
import { Outlet } from 'umi';
import { DfThemeConfigProvider } from '../DfThemeConfigProvider';
import {
  LoginLayoutContextProvider,
  useLoginLayoutContext,
} from './LoginLayoutContext';
import {
  AuthBackground,
  AuthContentContainer,
  DfLogo,
  NavContainer,
} from './styles';

const NavBar = () => {
  const { navBarControls } = useLoginLayoutContext();
  return (
    <NavContainer>
      <Link href="https://www.dragonfruit.ai/">
        <DfLogo />
      </Link>
      {navBarControls}
    </NavContainer>
  );
};

const LoginLayout = () => {
  return (
    <LoginLayoutContextProvider>
      <DfThemeConfigProvider>
        <AuthBackground>
          <NavBar />
          <AuthContentContainer>
            <Outlet />
          </AuthContentContainer>
        </AuthBackground>
      </DfThemeConfigProvider>
    </LoginLayoutContextProvider>
  );
};

export default LoginLayout;
