declare const DF_SERVICES_WS_ENDPOINT: string;

let websocket: WebSocket;
const getWebsocket = () => {
  // if there isn't a websocket of it's in CLOSED state
  if (!websocket || websocket.readyState === 3) {
    console.log('Setting up a new websocket connection');
    websocket = new WebSocket(DF_SERVICES_WS_ENDPOINT);
    // websocket.binaryType = 'arraybuffer';
  }
  return websocket;
};

export function getWebsocketState() {
  return getWebsocket().readyState;
}

export function listenToSocket(actionHandler, openHandler) {
  try {
    const socket = getWebsocket();

    socket.addEventListener('message', (event) => {
      let newEvent = _.cloneDeep(event);
      // sometimes we're seeing data that has multiple json strings one
      // after the other:
      // {"type": "success_message", "success": true, "data": {"message": "request_image_stream successful"}, "for_id": "20561-352x240/1.0"}{"type": "start_image_stream", "success": true, "pattern": "/customer/170/*", "channel": "/customer/170/project/4185/frontier/channel/stream", "data": {"channel_id": 20561, "viewport": "352x240", "fps": 1}}
      // let's handle that.
      let str = '[' + event.data.replace(/\}\s*\{/g, '},{') + ']';
      let dataStrings = [];

      try {
        dataStrings = JSON.parse(str);
      } catch (e) {
        console.error('websocket string error', str);
      }

      dataStrings.forEach((string) => {
        try {
          newEvent.data = JSON.stringify(string);
          actionHandler(newEvent);
        } catch (e) {
          console.error('websocket parse error', string, e);
        }
      });
    });

    socket.onopen = function () {
      if (openHandler) {
        openHandler();
      }
    };

    socket.onclose = function () {
      setTimeout(() => listenToSocket(actionHandler, openHandler), 1000);
    };

    socket.onerror = function (e) {
      console.error('websocket onerror', e);
      socket.close();
    };
  } catch (e) {
    console.error(e);
  }
}

function waitForSocketConnection(socket, callback) {
  setTimeout(function () {
    if (socket.readyState === 1) {
      if (callback !== undefined) {
        callback();
      }
      return;
    } else {
      waitForSocketConnection(socket, callback);
    }
  }, 5);
}

export function sendToSocket(message) {
  // check if the message contains "id", if not then warn
  if (!message.id) {
    console.warn('Message does not contain an id, cannot link to response:', message);
  }
  try {
    const socket = getWebsocket();
    waitForSocketConnection(socket, function () {
      socket.send(JSON.stringify(message));
    });
  } catch (e) {
    console.error(e);
  }
}
